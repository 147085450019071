import * as fromRouter from '@ngrx/router-store';
import { routerReducer, RouterState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { authFeatureKey, authReducer, AuthState } from './auth/+store/auth.reducer';
import { brandVoiceFeatureKey, brandVoiceReducer, BrandVoiceState } from './brand-voice/+store/brand-voice.reducer';
import { chatReducer, chatsFeatureKey, ChatState } from './chat/+store/chat.reducer';
import { documentReducer, documentsFeatureKey, DocumentState } from './document/+store/document.reducer';
import { embeddingFeatureKey, embeddingReducer, EmbeddingState } from './embedding/+store/embedding.reducer';
import { memberReducer, membersFeatureKey, MemberState } from './member/+store/member.reducer';
import { paymentFeatureKey, paymentReducer, PaymentState } from './payment/+store/payment.reducer';
import { projectReducer, projectsFeatureKey, ProjectState } from './project/+store/project.reducer';
import { templateFeatureKey, templateReducer, TemplateState } from './template/store/template.reducer';
import { tenantReducer, tenantsFeatureKey, TenantState } from './tenant/+store/tenant.reducer';
import { uploadReducer, uploadsFeatureKey, UploadState } from './upload/+store/upload/upload.reducer';
import { userFeatureKey, userReducer, UserState } from './user/+store/user.reducer';
import {
    workflowOutputReducer,
    workflowOutputsFeatureKey,
    WorkflowOutputState,
} from './workflow/+store/workflow-outputs/workflow-output.reducer';
import { workflowReducer, workflowsFeatureKey, WorkflowState } from './workflow/+store/workflow/workflow.reducer';

export interface AppState {
    router: RouterState,
    [projectsFeatureKey]: ProjectState,
    [chatsFeatureKey]: ChatState,
    [uploadsFeatureKey]: UploadState,
    [authFeatureKey]: AuthState,
    [userFeatureKey]: UserState,
    [embeddingFeatureKey]: EmbeddingState,
    [templateFeatureKey]: TemplateState,
    [documentsFeatureKey]: DocumentState,
    [tenantsFeatureKey]: TenantState,
    [paymentFeatureKey]: PaymentState,
    [membersFeatureKey]: MemberState,
    [workflowsFeatureKey]: WorkflowState,
    [workflowOutputsFeatureKey]: WorkflowOutputState,
    [brandVoiceFeatureKey]: BrandVoiceState,
}

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
    [projectsFeatureKey]: projectReducer,
    [chatsFeatureKey]: chatReducer,
    [uploadsFeatureKey]: uploadReducer,
    [authFeatureKey]: authReducer,
    [userFeatureKey]: userReducer,
    [embeddingFeatureKey]: embeddingReducer,
    [templateFeatureKey]: templateReducer,
    [documentsFeatureKey]: documentReducer,
    [tenantsFeatureKey]: tenantReducer,
    [paymentFeatureKey]: paymentReducer,
    [membersFeatureKey]: memberReducer,
    [workflowsFeatureKey]: workflowReducer,
    [workflowOutputsFeatureKey]: workflowOutputReducer,
    [brandVoiceFeatureKey]: brandVoiceReducer,
};

export const selectRouter = createFeatureSelector<
    AppState,
    fromRouter.RouterReducerState<any>
>('router');

export const {
    selectQueryParams,    // select the current route query params
    selectQueryParam,     // factory function to select a query param
    selectRouteParams,    // select the current route params
    selectRouteParam,     // factory function to select a route param
    selectRouteData,      // select the current route data
    selectUrl,            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const metaReducers: MetaReducer<AppState>[] =  [localStorageSyncReducer, clearState];

export function clearState(reducer: any) {
    return (state: any, action: any) => {
        if (action.type === 'CLEAR_ACTION_TYPE') {
            return reducer(undefined, action);
        }
        return reducer(state, action);
    };
}

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return localStorageSync({
        keys: [authFeatureKey, userFeatureKey],
        rehydrate: true,

    })(reducer);
}
