import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {WorkflowOutputPreview} from '../../+store/workflow-outputs/workflow-output.model';
import {
    ModalContentContainerComponent
} from '../../../shared/modal/modal-content-container/modal-content-container.component';

@Component({
  selector: 'app-workflow-output-preview-modal',
  template: `
      <app-modal>
          <app-modal-title>
              <h3>
                  Workflow Preview Output:
              </h3>
          </app-modal-title>
          <app-modal-body>
              @if (outputPreview(); as output) {
                  <div class="whitespace-pre-wrap" [innerHTML]="output.text"></div>
              } @else {
                  <div class="max-w-80 mx-auto">
                    <app-loading-owl-animation></app-loading-owl-animation>
                  </div>
              }
          </app-modal-body>
          <app-modal-footer>
              <app-button type="button"
                          (click)="modalContainer.onDismiss()">
                 Close
              </app-button>
          </app-modal-footer>
      </app-modal>
  `,
  styleUrl: './workflow-output-preview-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowOutputPreviewModalComponent {
    outputPreview = signal<WorkflowOutputPreview | null>(null);
    modalContainer = inject(ModalContentContainerComponent);

    closeModal() {
        this.modalContainer.onClose();
    }
}
