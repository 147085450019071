import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ModalService } from '../../../shared/modal/modal.service';
import { CreateCollectionModalComponent } from '../../modals/create-collection-modal/create-collection-modal.component';

@Component({
    selector: 'app-create-collection-button',
    template: `
        <app-button size="xs" color="transparent" [square]="true" (click)="openModal()">
            <fa-icon [fixedWidth]="true" size="sm" [icon]="['fas', 'plus']"></fa-icon>
        </app-button>
    `,
    styleUrl: './create-collection-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCollectionButtonComponent {
    modalService = inject(ModalService);

    openModal() {
        this.modalService.open(CreateCollectionModalComponent);
    }
}
