import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Field } from '../../types/field.type';
import { OnSelectChangeFunction } from '../generic-textfield-with-selector/generic-textfield-with-selector.component';

@Component({
    selector: 'app-generic-form',
    template: `
        <div class="space-y-1 overflow-auto" [formGroup]="formGroup" *ngIf="formGroup">
            <ng-container *ngFor="let field of data?.fields">
                <div [class.px-10]="!edgeless">
                    <app-generic-text-field
                            *ngIf="field.type === 'text'"
                            [formControlName]="field.name"
                            [field]="field">
                    </app-generic-text-field>

                    <app-generic-number-field
                        *ngIf="field.type === 'number'"
                        [formControlName]="field.name"
                        [field]="field">
                    </app-generic-number-field>

                    <app-generic-text-area-field
                        *ngIf="field.type === 'textarea'"
                        [formControlName]="field.name"
                        [field]="field">
                    </app-generic-text-area-field>

                    <app-generic-textfield-with-selector
                        *ngIf="field.type === 'textselector'"
                        [formControlName]="field.name"
                        [field]="field">
                    </app-generic-textfield-with-selector>
                </div>
            </ng-container>
        </div>
    `,
    styleUrls: ['./generic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFormComponent implements OnInit {
    controlContainer = inject(ControlContainer);
    formGroup?: FormGroup;

    _data!: {
        type: string;
        fields: Field[]
    };

    get data() {
        return this._data;
    }

    @Input() edgeless?: boolean;

    @Input() set data(data: {
        type: string;
        fields: Field[]
    }) {
        this._data = data;
        this.addFieldControls();
    }

    ngOnInit() {
        if (this.controlContainer) {
            this.formGroup = this.controlContainer.control as FormGroup;
            this.addFieldControls();
        }
    }

    createTypeControl(field: Field) {
        const typeControlMap: {[k:string]: () => FormControl} = {
            text: () => new FormControl(field.initialValue ?? ''),
            textarea: () => new FormControl(field.initialValue ?? ''),
            number: () => new FormControl(field.initialValue ?? ''),
        }

        const fn = typeControlMap[field.type];

        return fn ? fn() : typeControlMap['text']();
    }

    addFieldControls() {
        if (!this.data?.fields || !this.formGroup) return;

        this.data.fields.forEach(field => {
            const control = this.createTypeControl(field);

            this.formGroup?.addControl(field.name, control);
            control.updateValueAndValidity()
        })
    }

    selectTest(cbFn: OnSelectChangeFunction) {
        cbFn({visibleValue: "Hier ist der sichtbare Text", value: "Hier ist der endwert im form"});
    }
}
