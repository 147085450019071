import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';

import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { UserEffects } from './+store/user.effects';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';


@NgModule({
    declarations: [
        UserProfileComponent,
        DeleteModalComponent,
    ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([UserEffects]),
        FontAwesomeModule,
        SharedModule,
        TippyDirective,
        NgOptimizedImage,
        ReactiveFormsModule,
    ],
})
export class UserModule {
}
