import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { exhaustMap, map, mergeMap } from 'rxjs/operators';
import { catchError, of, switchMap, tap } from "rxjs";
import { TemplateService } from '../template.service';
import { TemplateActions } from './template.actions';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable()
export class TemplateEffects {

    loadTemplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TemplateActions.loadTemplates),
            exhaustMap(() => this.templateService.loadAll().pipe(
                map(templates => TemplateActions.loadTemplatesSuccess({templates})),
            )),
        );
    });

    addTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.addTemplate),
        mergeMap(action => {
            return this.templateService.addTemplate(action.name, action.prompt).pipe(
                tap(() => this.toast.success('Template prompt saved')),
                map(template => TemplateActions.loadTemplatesSuccess({templates: [template]})),
                catchError(error => {
                    return of(TemplateActions.loadTemplatesFailure({error}))
                }),
            );
        })
    ));

    updateTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.updateTemplate),
        mergeMap(action => {
            return this.templateService.updateTemplate(action.id, action.name, action.prompt).pipe(
                tap(() => this.toast.success('Template prompt updated')),
                map(template => TemplateActions.loadTemplatesSuccess({templates: [template]})),
                catchError(error => {
                    return of(TemplateActions.loadTemplatesFailure({error}))
                }),
            )
        })
    ));

    deleteTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.deleteTemplate),
        switchMap(action => this.templateService.deleteTemplate(action.id).pipe(
            tap(() => this.toast.success('Template prompt deleted')),
            map(() => TemplateActions.deleteTemplateSuccess({id: action.id})),
            catchError(error => of(TemplateActions.loadTemplatesFailure({error}))),
        )),
    ));

    constructor(
        private actions$: Actions,
        private templateService: TemplateService,
        private toast: HotToastService,
    ) {
    }
}
