import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faFileLines, faPaperclip } from '@fortawesome/pro-duotone-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { distinctUntilKeyChanged, firstValueFrom, Observable, of, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ChatActions } from '../../../chat/+store/chat.actions';
import { Chat } from '../../../chat/+store/chat.model';
import { selectChatById, selectLoading } from '../../../chat/+store/chat.selectors';
import { RenameChatModalComponent } from '../../../chat/components/rename-chat-modal/rename-chat-modal.component';
import { DocumentActions } from '../../../document/+store/document.actions';
import { Document } from '../../../document/+store/document.model';
import { selectDocumentsByChatId } from '../../../document/+store/document.selectors';
import { selectCurrentTemplateId, selectTemplateById } from '../../../template/store/template.selectors';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ModalService } from '../../modal/modal.service';
import { filterNullish } from '../../utilities/filter-nullish.operator';

@UntilDestroy()
@Component({
    selector: 'app-chat',
    template: `
        <div class="w-full flex flex-col h-full">
            <header class="flex items-center h-[85px] py-5 px-7 border-b border-b-slate-700">
                <!--                <div class="h-full">-->
                <!--                    <img ngSrc="https://i.pravatar.cc/300" width="44" height="44" class="h-full rounded-full" alt="">-->
                <!--                </div>-->
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div *ngIf="(chat$ | async) as chat;else placeholder">
                            <h3 class="text-slate-200 font-medium">{{ chat.name ? chat.name : 'New Chat' }}</h3>
                        </div>
                        <ng-template #placeholder>
                            <h3 class="text-slate-200 font-medium">New Chat</h3>
                        </ng-template>
                        <button
                            (click)="renameChat()"
                            tp="Rename Chat"
                            class="bg-transparent text-slate-400 px-2 hover:text-white rounded shadow-sm">
                            <fa-icon [icon]="['fad', 'pencil']" size="sm" [fixedWidth]="true"></fa-icon>
                        </button>
                    </div>
                    <span class="text-slate-400 text-xs" *ngIf="(chat$ | async)?.createdAt">
                        {{(chat$ | async)?.createdAt | timeFromNow | async}}
                    </span>
                </div>
                <div class="flex ml-auto items-center">
                    <span class="text-slate-400 text-xs text-right" tp="Total token usage for this chat"
                          *ngIf="!!(chat$ | async)?.tokenUsage">
                        {{(chat$ | async)?.tokenUsage?.totalTokens}} Tokens
                    </span>
                    <button class="px-4 py-2 ml-4 font-semibold text-sm bg-cyan-500 text-white rounded shadow-sm"
                            *ngIf="projectId"
                            tp="Create new chat" [routerLink]="['/project', projectId]">
                        <fa-icon icon="plus"></fa-icon>
                    </button>
                </div>
            </header>

            <section *ngIf="!!(documents$ | async) && (documents$ | async)!.length > 0" class="border-b border-b-slate-700 p-3">
                <div class="text-xs text-gray-400 mb-2">
                    All documents which were uploaded and used in this chat.
                </div>
                <app-document-item *ngFor="let doc of documents$ | async" [document]="doc"></app-document-item>
            </section>

            <section class="overflow-auto" scrollbar>
                <div class="text-blue-300 italic mt-3 p-3" *ngIf="!projectId">
                    Please select a project or create a new project to start a chat.
                </div>
                <div *ngIf="!(chat$ | async) && projectId">
                    <app-template-chat-suggestion [projectId]="projectId"></app-template-chat-suggestion>
                </div>
                <!--                <app-chat-message *ngFor="let message of (chat$ | async)?.messages"-->
                <!--                                  [chatId]="(chat$ | async)?.id"-->
                <!--                                  [message]="message"></app-chat-message>-->
            </section>
            <div>
                <app-chat-loading [loading]="(loading$ | async)"></app-chat-loading>
            </div>

            <footer class="mt-auto bg-slate-950 pl-7 pt-7 pb-7 pr-2" *ngIf="projectId">
                <div class="text-red-600 mb-3 text-xs font-bold" *ngIf="droppedFilesSize / 1e+6 >= 20">
                    Note: Currently you can upload a maximum of 20Mb within a chat.
                </div>
                <div *ngIf="droppedFiles?.length" class="pb-4 border-b border-b-slate-700">
                    <div class="text-gray-500 mb-3 text-xs font-bold">
                        Currently we recommend GTP-3.5-turbo as a model if you want to use this feature. You can change
                        this in the settings on the right.
                    </div>
                    <app-dropped-item *ngFor="let el of droppedFiles" [file]="el.file"
                                      (removeEvent)="removeFile($event)"></app-dropped-item>
                </div>
                <form [formGroup]="inputForm" (ngSubmit)="onSubmit()" class="w-full flex relative mt-4">
                    <div class="grow">
                        <div class="max-h-[250px] relative" scrollbar>
                            <textarea class="text-slate-400 w-full bg-transparent outline-0 overflow-hidden pr-12"
                                      placeholder="Enter a prompt"
                                      formControlName="inputValue" cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="15"
                                      (keyup)="scrollbar.update()"
                            >
                            </textarea>
                        </div>
                        <div class="text-red-500 text-xs italic mt-3"
                             *ngIf="inputForm.get('inputValue')?.errors?.['required'] && inputForm.get('inputValue')?.touched">
                            Please enter a request.
                        </div>
                    </div>
                    <button type="button" class="absolute right-16 top-1/2 -translate-y-1/2"
                            (click)="hiddenFileInput.click()"
                            tp="Uploaded one or more files that will be used for your request. The files from your project
                    overview will not be searched. Use this feature if you want to have a summary for example.">
                        <fa-icon class="text-slate-400" size="xl" [icon]="fadPaperClip"></fa-icon>
                    </button>
                    <input type="file" class="hidden" (change)="addFile($event)" #hiddenFileInput/>
                    <button type="submit" class="absolute right-5 top-1/2 -translate-y-1/2">
                        <fa-icon class="text-slate-400" size="xl" [icon]="['fad', 'paper-plane-top']"></fa-icon>
                    </button>
                </form>
            </footer>
        </div>
    `,
    styleUrls: ['./chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit {
    @ViewChild(PerfectScrollbarDirective, {static: true}) scrollbar!: PerfectScrollbarDirective;

    chat$!: Observable<Chat | undefined>;
    documents$: Observable<Document[]> = of([]);
    loading$!: Observable<boolean>;

    inputForm = new FormGroup({
        inputValue: new FormControl('', Validators.required),
    });
    projectId!: string;
    fadPaperClip = faPaperclip;
    faFileLines = faFileLines;
    droppedFiles: { file: File, type: string, size: number }[] = [];
    droppedFilesSize: number = 0;

    constructor(
        private socket: Socket,
        private store: Store,
        private route: ActivatedRoute,
        private http: HttpClient,
        private router: Router,
        private modal: ModalService,
        private cdr: ChangeDetectorRef,
    ) {
    }


    ngOnInit() {
        this.route.paramMap.pipe(
            map(params => params.get('projectId')),
            filterNullish(),
            untilDestroyed(this),
        ).subscribe(id => {
            this.projectId = id;
            this.inputForm.patchValue({inputValue: null})
            this.inputForm.markAsUntouched();
        });

        this.route.paramMap.pipe(
            map(params => params.get('chatId')),
            filterNullish(),
            untilDestroyed(this),
        ).subscribe(id => {
            this.store.dispatch(ChatActions.loadChatById({id}))
        });

        this.chat$ = this.route.paramMap.pipe(
            map(params => params.get('chatId')),
            switchMap(chatId => {
                if (chatId) {
                    return this.store.pipe(select(selectChatById(chatId)));
                }

                return of(undefined);
            }),
        );

        this.store.pipe(
            untilDestroyed(this),
            select(selectCurrentTemplateId()),
            filterNullish(),
            switchMap(id => this.store.select(selectTemplateById(id))),
            filterNullish(),
        ).subscribe(template => {
            this.inputForm.patchValue({inputValue: template?.prompt})
        });

        this.loading$ = this.store.select(selectLoading);

        this.chat$.pipe(
            untilDestroyed(this),
            filterNullish(),
            distinctUntilKeyChanged('documents'),
            switchMap(async (chat) => this.store.dispatch(DocumentActions.loadDocumentsByChatId({chatId: chat.id}))),
        ).subscribe();

        this.documents$ = this.chat$.pipe(
            filterNullish(),
            switchMap(chat => this.store.select(selectDocumentsByChatId(chat.id))),
        )
    }


    async onSubmit() {
        if (this.inputForm.invalid) {
            this.inputForm.markAllAsTouched();
            return;
        }

        if (this.droppedFilesSize / 1e+6 >= 20) {
            return;
        }

        let chatId = this.route.snapshot.paramMap.get('chatId');
        let chat: Chat;

        if (!chatId) {
            const response = await firstValueFrom(this.http.post<Chat>(`${environment.apiUrl}/chat`, {
                projectId: this.route.snapshot.paramMap.get('projectId'),
                name: 'New Chat',
                files: this.droppedFiles,
            }));
            chatId = response.id;
            chat = response;
        } else {
            chat = await firstValueFrom(this.chat$.pipe(take(1), filterNullish()));
        }

        this.socket.emit('chatMessage', {
            text: this.inputForm.value.inputValue,
            chatId: chatId,
            files: this.droppedFiles,
            followUpQuestion: chat?.documents.length > 0
        });
        this.store.dispatch(ChatActions.setChatLoading({id: chatId, loading: true}));

        if (!this.route.snapshot.paramMap.get('chatId')) {
            await this.router.navigate(['./', 'chat', chatId], {relativeTo: this.route});
        }

        this.droppedFiles = [];
        this.inputForm.reset();
    }

    renameChat() {
        const chatId = this.route.snapshot.paramMap.get('chatId');
        const modal = this.modal.open(RenameChatModalComponent, {centered: true, size: 'auto'});
        if (chatId) {
            modal.contentInstance.id = chatId.toString();
        }
    }

    addFile($event: Event) {
        // @ts-ignore
        this.addFiles(($event.target as HTMLInputElement).files);
    }

    addFiles(files: FileList) {
        for (const file of Array.from(files)) {
            const res = {file, type: file.type, size: file.size, name: file.name};
            this.droppedFilesSize += file.size;
            this.droppedFiles.push(res);
        }
    }

    removeFile(file: any) {
        const index = this.droppedFiles.findIndex(el => el.file === file);
        if (index >= 0) {
            this.droppedFiles.splice(index, 1);
            this.droppedFilesSize -= file.size;
        }
    }
}
