import { createSelector } from '@ngrx/store';
import { brandVoiceFeature } from './brand-voice.reducer';

export const {
    selectIds: selectBrandVoiceIds,
    selectEntities: selectBrandVoiceEntities,
    selectAll: selectAllBrandVoices,
    selectTotal: selectTotalBrandVoices,
} = brandVoiceFeature;

export const selectBrandVoiceById = (id: string ) => createSelector(selectBrandVoiceEntities, entities => entities[id]);
