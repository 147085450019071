import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Template } from '../../../template/store/template.interface';
import { faMessage, faMessageSmile, faPenToSquare, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { ModalService } from '../../modal/modal.service';
import { TemplateDeleteModalComponent } from '../template-delete-modal/template-delete-modal.component';
import {
    TemplateCreateUpdateModalComponent
} from '../template-create-update-modal/template-create-update-modal.component';
import { HotToastService } from '@ngneat/hot-toast';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';

@Component({
    selector: 'app-template-list-item',
    template: `
        <div class="flex items-center px-5 py-4 group hover:bg-slate-800/50 cursor-pointer">
            <div class="text-slate-400">
                <fa-icon [icon]="faMessage"></fa-icon>
            </div>
            <div class="ml-4 flex flex-col" [tp]="template.prompt">
                <h4 class="text-slate-300 text-xs">{{ template.name }}</h4>
                <div></div>
            </div>
            <div class="ml-auto">
                <button
                    tp="Paste into chat" (click)="insertIntoChat()"
                    class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:text-white rounded shadow-sm">
                    <fa-icon [icon]="faMessageSmile" [fixedWidth]="true"></fa-icon>
                </button>
                <button
                    tp="Edit" (click)="openEditModal()"
                    class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:text-white rounded shadow-sm">
                    <fa-icon [icon]="faPenToSquare" [fixedWidth]="true"></fa-icon>
                </button>
                <button
                    tp="Delete" (click)="openDeleteModal()"
                    class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:text-white rounded shadow-sm">
                    <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./template-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListItemComponent {
    @Input('template') template!: Template;
    @Input('hasProjectId') hasProjectId!: boolean;

    faPenToSquare = faPenToSquare;
    faTrash = faTrashCan;
    faMessageSmile = faMessageSmile;
    faMessage = faMessage;

    constructor(
        private modalService: ModalService,
        private modal: ModalService,
        private toaster: HotToastService,
        private store: Store<AppState>,
    ) {
    }

    openDeleteModal() {
        const modal = this.modalService.open(TemplateDeleteModalComponent, {centered: true});
        modal.contentInstance.templateId = this.template.id;
    }

    openEditModal() {
        const modal = this.modal.open(TemplateCreateUpdateModalComponent, {centered: true, size: 'full'});
        modal.contentInstance.prompt = this.template.prompt;
        modal.contentInstance.id = this.template.id;
        modal.contentInstance.name = this.template.name;
    }

    insertIntoChat() {
        if (!this.hasProjectId) {
            this.toaster.info('Please select a project for your chat!', {duration: 5000, position: 'top-center'})
        }

        this.store.dispatch(TemplateActions.setCurrentTemplateId({id: this.template.id}));
    }
}
