import { createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';

export const userFeatureKey = 'user';

export interface UserState {
    loading: boolean;
    error: any,
    email: string | null,
    tenants: string[] | null,
    id: string | null,
    // temporary until correct permission management
    workflowEditPermission: boolean | null;
}

export const initialState: UserState = {
    loading: false,
    email: null,
    tenants: null,
    id: null,
    error: null,
    // temporary until correct permission management
    workflowEditPermission: null
};

export const userReducer = createReducer(
    initialState,
    on(
        UserActions.loadUser,
        UserActions.updatePassword,
        UserActions.deleteUser,
        (state, action) => ({...state, isLoading: true, error: null}),
    ),
    on(UserActions.loadUserSuccess,
        (state, {user}) => ({...state, ...user, isLoading: false}),
    ),
    on(UserActions.loadUserError,
        (state, {err}) => ({...state, isLoading: false, error: err}),
    ),

    on(UserActions.deleteUserSuccess, () => initialState),
);

