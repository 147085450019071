import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { popperVariation, provideTippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Socket, SocketIoModule } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasicAuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { ChatModule } from './chat/chat.module';
import { DocumentModule } from './document/document.module';
import { EmbeddingModule } from './embedding/embedding.module';
import { MemberModule } from './member/member.module';
import { PaymentModule } from './payment/payment.module';
import { ProjectModule } from './project/project.module';
import { metaReducers, reducers } from './reducers';
import { SettingModule } from './setting/setting.module';
import { SocketService } from './shared/services/socket/socket.service';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './template/template.module';
import { TenantModule } from './tenant/tenant.module';
import { UploadModule } from './upload/upload.module';
import { UserModule } from './user/user.module';
import {provideHotToastConfig} from '@ngneat/hot-toast';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        SharedModule,
        FontAwesomeModule,
        TippyDirective,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 100, logOnly: !isDevMode(), connectInZone: true }),
        SocketIoModule,
        ProjectModule,
        ChatModule,
        UploadModule,
        AuthModule,
        UserModule,
        EmbeddingModule,
        TemplateModule,
        DocumentModule,
        SettingModule,
        TenantModule,
        PaymentModule,
        MemberModule,
        AppRoutingModule], providers: [
        provideTippyConfig({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: tooltipVariation,
                popper: {
                    ...popperVariation,
                    theme: 'neptune',
                },
            },
        }),
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: Socket, useClass: SocketService },
        provideHttpClient(withInterceptorsFromDi()),
        provideHotToastConfig({
            position: 'top-right',
            duration: 2000,
            autoClose: true,
            theme: 'toast',
        }),
    ] })
export class AppModule { }
