import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faFaceFrown, faFaceMeh, faFaceSmile } from '@fortawesome/pro-solid-svg-icons';
import { FeedbackService } from '../../services/feedback.service';

@Component({
    selector: 'app-rating',
    template: `
        <div class="bg-opacity-75">
            <fa-icon tp="Bad answer" class="cursor-pointer opacity-20 hover:opacity-100" (click)="rate(1)"
                     [icon]="faFaceFrown"></fa-icon>
            <fa-icon class="ml-1 cursor-pointer opacity-20  hover:opacity-100" tp="Answer was okay"
                     [icon]="faFaceMeh" (click)="rate(2)"></fa-icon>
            <fa-icon class="ml-1 cursor-pointer opacity-20 hover:opacity-100" tp="Great answer!"
                     (click)="rate(3)"
                     [icon]="faFaceSmile"></fa-icon>
        </div>
    `,
    styleUrls: ['./rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent {
    @Input() chatId!: string;
    @Input() aiOutput!: string;
    @Input() messageId!: string;

    faFaceMeh = faFaceMeh;
    faFaceFrown = faFaceFrown;
    faFaceSmile = faFaceSmile;

    constructor(
        private feedbackService: FeedbackService,
    ) {
    }

    rate(rating: number) {
        this.feedbackService.rate(rating, this.chatId, this.aiOutput, this.messageId)
    }
}
