import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { catchError, from, mergeMap, of, tap } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { UserActions } from '../../user/+store/user.actions';
import { AuthService } from '../auth.service';
import { AuthActions } from './auth.actions';
import { AccessTokenDecoded } from './auth.reducer';

@Injectable()
export class AuthEffects {

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.login),
            exhaustMap((action) => this.authService.login(action.email, action.password).pipe(
                mergeMap(({accessToken, refreshToken}) => {
                    const decoded: AccessTokenDecoded = jwtDecode(accessToken);
                    return [AuthActions.loginSuccess({
                        accessToken,
                        refreshToken,
                        email: decoded.email
                    }), UserActions.loadUser()]
                }),
                catchError(err => of(AuthActions.loginFailure({err})))
            )),
        );
    });

    loginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            map(() => this.router.navigate(['/']))
        )
    }, {dispatch: false});

    signup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.signup),
            exhaustMap((action) => this.authService.signup(action.firstname, action.lastname, action.email).pipe(
                map(response => AuthActions.signupSuccess()),
                catchError(error => {
                    return of(AuthActions.signupFailure({err: error}));
                })
            )),
        )
    );

    signupSuccess$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AuthActions.signupSuccess),
                tap(() => {
                    this.router.navigate(['signup-success']);
                    this.toast.success('Successful');
                }),
            ),
        {dispatch: false},
    );


    getNewAccessToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.getNewAccessToken),
            exhaustMap((action) => this.authService.getNewAccessToken().pipe(
                mergeMap(({accessToken, refreshToken}) => {
                    const decoded: AccessTokenDecoded = jwtDecode(accessToken);
                    return [AuthActions.loginSuccess({
                        accessToken,
                        refreshToken,
                        email: decoded.email
                    }), UserActions.loadUser()]
                }),
                catchError(err => {
                    return of(AuthActions.logout())
                })
            )),
        );
    });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.logout),
            exhaustMap((action) => this.authService.logout().pipe(
                tap(() => this.router.navigate(['/auth/login'])),
                // TODO: Clear all collections?
                mergeMap(() => [AuthActions.logoutSuccess(), {type: 'CLEAR_ACTION_TYPE'}]),
                catchError(err => {
                    const actions: Action[] = [
                        AuthActions.logoutSuccess(),
                        {type: 'CLEAR_ACTION_TYPE'},
                    ];
                    return from(actions);
                }),
            )),
        );
    });

    acceptInvitation$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.acceptInvitation),
        exhaustMap(action => this.authService.acceptInvitation(action).pipe(
            map(() => AuthActions.acceptInvitationSuccess()),
            tap(() => {
                this.toast.success('Password successfully changed. You can now login.');
                this.router.navigate(['/auth/login']);
            }),
            catchError(error => {
                this.toast.error('An error occured. Please try again later or contact customer support.');
                return of(AuthActions.acceptInvitationFailure({error}));
            }),
        )),
    ));

    forgotPassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.forgotPassword),
        exhaustMap(action => this.authService.forgotPassword({ email: action.email }).pipe(
            map(() => AuthActions.forgotPasswordSuccess()),
            tap(() => this.router.navigate(['/login'], { queryParams: { reset: 1 } })),
            catchError(error => of(AuthActions.forgotPasswordFailure({ error }))),
        )),
    ));

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private toast: HotToastService,
        private store: Store,
    ) {
    }
}
