import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '../../shared/utilities/update.type';

import { Chat } from './chat.model';

export const ChatActions = createActionGroup({
    source: 'Chat/API',
    events: {
        'Send Chat Message': props<{text: string, chatId: string}>(),

        'Create Chat': props<{text: string, model: Chat['model'], settings: Chat['settings']}>(),
        'Create Chat Success': props<{chat: Chat}>(),
        'Create Chat Failure': props<{error: any}>(),

        'Load All Chats': emptyProps(),
        'Load All Chats Success': props<{chats: Chat[]}>(),
        'Load All Chats Failure': props<{error: any}>(),

        'Load Chat By Id': props<{id: string}>(),
        'Load Chat By Id Success': props<{chat: Chat}>(),
        'Load Chat By Id Failure': props<{error: any}>(),

        'Load Chats By Project Id': props<{projectId: string}>(),
        'Load Chats By Project Id Success': props<{chats: Chat[]}>(),
        'Load Chats By Project Id Failure': props<{error: any}>(),

        'Set Chat Loading': props<{id: string, loading: boolean}>(),

        'Upsert Chats': props<{chats: Chat[]}>(),

        'Update Chat': props<{chat: Update<Chat>}>(),
        'Update Chat Success': props<{chat: Chat}>(),
        'Update Chat Failure': props<{error: Error}>(),

        'Delete Chat': props<{id: string}>(),
        'Delete Chats': props<{ids: string[]}>(),
        'Clear Chats': emptyProps(),
    },
});
