import { ChangeDetectionStrategy, Component, computed, inject, Input, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { mergeMap, startWith, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroupFinderDirective } from '../../directives/form-group-finder/form-group-finder.directive';
import { TypedValidationErrors } from '../../types/typed-validation-errors.type';

@Component({
  selector: 'app-field-error-messages',
  template: `
      <div class="text-sm text-red-600 px-2 py-1 min-h-[28px]">
          <ng-container *ngIf="errorObject && (formGroupFinder.formGroup.submitted|| touched)">
              <div *ngIf="errorObject?.['required']">
                  This field is required.
              </div>
              <div *ngIf="errorObject?.['maxLength']; let maxLength">
                  Maximum {{maxLength}} characters allowed.
              </div>
              <div *ngIf="errorObject?.['minValue']; let minValue">
                  Must be minimum of {{minValue}}.
              </div>
              <div *ngIf="errorObject?.['maxValue']; let maxValue">
                  Must be smaller than {{maxValue}}.
              </div>
          </ng-container>
      </div>
  `,
  styleUrls: ['./field-error-messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldErrorMessagesComponent {
    formGroupFinder = inject(FormGroupFinderDirective);
    submit$ = toSignal(this.formGroupFinder.formGroup.ngSubmit.pipe(
        map(() => true),
        startWith(false),
    ));



    @Input() errorObject: TypedValidationErrors | null = null;
    @Input() touched?: boolean = false;
}
