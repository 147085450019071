export function containsNonEmptyValue(input: Record<any, any> | string | any[]) {
    function checkValue(value: Record<any, any> | string | any[]) {
        if (Array.isArray(value)) {
            return value.some(checkValue);
        } else if (typeof value === "object" && value !== null) {
            return Object.values(value).some(checkValue);
        } else {
            return value !== "";
        }
    }

    return checkValue(input);
}
