import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';
import { PerfectScrollbarDirective } from '../../directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-template-create-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3 *ngIf="!id">
                    Save a template prompt
                </h3>
                <h3 *ngIf="id">
                    Update your template prompt
                </h3>
            </app-modal-title>
            <app-modal-body class="max-h-80">
                <p class="text-white" *ngIf="!id">
                    You have the possibility to save your prompt as a template. <br>
                    This way you can easily reuse this template later. You are also able to update the prompt before
                    reusing it. <br>
                    Currently, the templates will be available in each of your projects.
                </p>
                <div [formGroup]="form" class="mt-4">
                    <div>
                        <label for="name" class="form-label text-blue-800">
                            Name
                        </label>
                        <input class="form-input px-2" type="text" id="name" formControlName="name">
                        <div class="text-red-500 text-xs italic mt-3"
                             *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                            Please enter a name for the prompt.
                        </div>
                    </div>
                    <div class="my-3">
                        <label for="prompt" class="form-label text-blue-800">
                            Prompt
                        </label>
                        <div scrollbar>
                                  <textarea class="w-full overflow-hidden form-input px-2"
                                            cdkTextareaAutosize cdkAutosizeMinRows="3"
                                            cdkAutosizeMaxRows="15" (keyup)="scrollbar.update()"
                                            type="text" id="prompt" formControlName="prompt">
                        </textarea>
                        </div>

                        <div class="text-red-500 text-xs italic mt-3"
                             *ngIf="form.get('prompt')?.errors?.['required'] && form.get('prompt')?.touched">
                            Please enter a prompt.
                        </div>
                    </div>
                </div>
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-between">
                    <button
                            (click)="modalContainer.onDismiss()"
                            type="button" class="btn-light">
                        Close
                    </button>

                    <button
                            (click)="save()"
                            type="button" class="btn-success">
                        Save
                    </button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./template-create-update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCreateUpdateModalComponent {
    @ViewChild(PerfectScrollbarDirective, {static: true}) scrollbar!: PerfectScrollbarDirective;
    @Input() prompt!: string;
    @Input() name!: string;
    @Input() id!: string;
    form!: FormGroup;

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl('', Validators.required),
            prompt: new FormControl('', Validators.required),
        });

        this.form.patchValue({
            prompt: this.prompt,
            name: this.name,
        });
    }

    async save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (!this.id) {
            this.store.dispatch(TemplateActions.addTemplate({
                name: this.form.value.name,
                prompt: this.form.value.prompt
            }));
        } else  {
            this.store.dispatch(TemplateActions.updateTemplate({
                name: this.form.value.name,
                prompt: this.form.value.prompt,
                id: this.id,
            }));
        }
        this.modalContainer.onDismiss();


    }
}
