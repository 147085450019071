import { createSelector } from '@ngrx/store';
import { workflowsFeature } from './workflow.reducer';

export const {
    selectIds: selectWorkflowIds,
    selectEntities: selectWorkflowEntities,
    selectAll: selectWorkflowAll,
    selectTotal: selectWorkflowTotal,
    selectLoading
} = workflowsFeature;

export const selectWorkflowById = (id: string) => createSelector(selectWorkflowEntities, entities => entities[id]);
export const selectWorkflowLoading = () => createSelector(selectLoading, loading => loading);
