import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import Lottie from 'lottie-web';

@Component({
    selector: 'app-loading-owl-animation',
    template: `
        <div class="w-full" #container></div>
    `,
    styleUrls: ['./loading-owl-animation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOwlAnimationComponent implements AfterViewInit {
    @ViewChild('container', { static: true }) container!: ElementRef<HTMLDivElement>;

    ngAfterViewInit() {
        Lottie.loadAnimation({
            container: this.container.nativeElement,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/assets/lotties/loading-owl.json',
        });
    }
}
