import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbeddingSearchResult } from '../../+store/embedding.model';
import { selectUploadCollectionEntities } from '../../../upload/+store/folder/upload-collection.selectors';

@Component({
    selector: 'app-embedding-result-item',
    template: `
        <div class="flex items-center mt-4">
            <div class="mr-4">
                <app-round-checkbox (change)="select($event)"></app-round-checkbox>
            </div>
            <div class="grow">
                <div class="flex items-end space-x-3 mb-2 w-full">
                    <div class="grow">
                        <h4 class="font-semibold text-slate-500 text-sm">{{ embedding.fileName }}</h4>
                        <div class="flex items-center text-xs text-slate-950/40 uppercase font-bold tracking-wider"
                             *ngIf="uploadCollections$ | async as uploadCollections">
                            <fa-icon [icon]="['fad', 'folder-open']" size="sm"
                                     class="text-slate-400 mr-2"></fa-icon>
                            {{ uploadCollections[embedding.uploadCollectionId]?.name }}
                        </div>
                    </div>
                    <div class="ml-auto">
                        <div class="shrink-0 ml-7 flex items-center space-x-3">
                            <div class="flex items-center w-full">
                                <div
                                    class="flex items-center text-xs text-slate-950/40 lowercase font-bold tracking-wider">
                                    Score
                                </div>
                                <div class="text-slate-500 font-semibold text-xs ml-2 text-right grow">
                                    {{ embedding.score | percent }}
                                </div>
                            </div>
                            <div class="flex items-center w-full">
                                <div
                                    class="flex items-center text-xs text-slate-950/40 lowercase font-bold tracking-wider">
                                    Page
                                </div>
                                <div
                                    class="text-slate-500 font-semibold text-xs ml-2 text-right grow whitespace-nowrap">
                                    {{ embedding.loc.pageNumber }}
                                </div>
                            </div>
                            <div class="flex items-center w-full">
                                <div
                                    class="flex items-center text-xs text-slate-950/40 lowercase font-bold tracking-wider">
                                    Line
                                </div>
                                <div
                                    class="text-slate-500 font-semibold text-xs ml-2 text-right grow whitespace-nowrap">
                                    {{ embedding.loc.lines.from }}-{{ embedding.loc.lines.to }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-xs text-slate-400 line-clamp-4 relative group hover:overflow-visible">
                    ...{{ embedding.pageContent }}...
                    <div
                        class="absolute top-0 left-0 bg-white hidden group-hover:block w-full z-10 p-5 rounded text-sm">
                        ...{{ embedding.pageContent }}...
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrl: './embedding-result-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbeddingResultItemComponent {
    store = inject(Store);
    @Input() embedding!: EmbeddingSearchResult;
    @Output() selected = new EventEmitter<[string, boolean]>();

    uploadCollections$ = this.store.select(selectUploadCollectionEntities);

    select($event: Event) {
        const target = $event.target as HTMLInputElement;
        this.selected.next([this.embedding.id, target.checked]);
    }
}
