import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-chat-loading',
  template: `
    <div class="p-6 mt-2 text-blue-300 font-bold text-sm " *ngIf="loading">
        <fa-icon [icon]="faSpinner" size="sm" [fixedWidth]="true" animation="spin" class="mr-1"></fa-icon>
        Please wait a moment. We have received your request and are trying to find the best possible answer as soon as
        possible. This may take some time depending on your request and documents.
    </div>
  `,
  styleUrls: ['./chat-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatLoadingComponent implements OnInit{
    @Input() loading!: boolean | null;
    faSpinner = faSpinner;

    constructor(
        private cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
    }

}
