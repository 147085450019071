import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Document } from '../../../document/+store/document.model';
import { faDownload, faFileLines } from '@fortawesome/pro-duotone-svg-icons';
import { DocumentService } from '../../../document/document.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-document-item',
    template: `
        <div class="flex items-center group mb-1">
            <div class="flex justify-center items-center bg-blue-200 w-[30px] h-[30px] rounded text-blue-800 shrink-0">
                <fa-icon [icon]="faFile" [fixedWidth]="true" size="1x"></fa-icon>
            </div>
            <div class="flex flex-col overflow-auto ml-3 grow">
                <h3 class="font-medium text-xs text-slate-300 line-clamp-2">
                    {{ document.fileName}}
                </h3>
            </div>
            <div class="flex text-blue-300">
                <fa-icon [icon]="faDownload" [fixedWidth]="true" size="1x" tp="Download document"
                         class="pointer" (click)="download()"></fa-icon>
            </div>
        </div>
    `,
    styleUrls: ['./document-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentItemComponent {
    @Input() document!: Document;
    faDownload = faDownload;
    faFile = faFileLines;

    constructor(
        private documentService: DocumentService,
    ) {
    }

    download() {
        this.documentService.download(this.document.id).pipe(
            untilDestroyed(this),
        ).subscribe(res => {
            const url = window.URL.createObjectURL(res.file);
            const a = document.createElement('a');
            a.href = url;
            a.download =  res.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }

}
