import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { Workflow } from './+store/workflow/workflow.model';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    http = inject(HttpClient);
    socket = inject(Socket);

    loadAll() {
        return this.http.get<Workflow[]>(`${environment.apiUrl}/workflow`);
    }

    // this is splitted to have a simple check if someone can view the view or edit workflow page
    loadById(id: string, forEdit?: boolean) {
        if (forEdit) {
            // this will make edit permission check
            return this.http.get<Workflow>(`${environment.apiUrl}/workflow/editable/${id}`);
        }
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/${id}`);
    }

    subscribe(id: string) {
        this.socket.emit('subscribe', `workflow/${id}`);
    }

    unsubscribe(id: string) {
        this.socket.emit('unsubscribe', `workflow/${id}`);
    }
}
