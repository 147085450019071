import { HttpErrorResponse } from '@angular/common/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

fetch(`${environment.apiUrl}`, { method: 'POST' })
    .then(response => {
        if (!response.ok || (response.status >= 400 && response.status < 600)) {
            console.log('Switch to default api as fallback');
            environment.apiUrl = environment.defaultApi;
        }
    })
    .catch((err: HttpErrorResponse) => {
        console.log('Switch to default api as fallback');
        environment.apiUrl = environment.defaultApi;
    })
    .finally(() => {
        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch(err => console.error(err));
    });
