import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    Input,
    OnInit,
    signal,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, delay, first, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandVoiceActions } from '../../+store/brand-voice.actions';
import { BrandVoice } from '../../+store/brand-voice.model';
import { selectBrandVoiceEntities } from '../../+store/brand-voice.selectors';
import { Field } from '../../../shared/custom-inputs/types/field.type';
import {
    ModalContentContainerComponent
} from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { Update } from '../../../shared/utilities/update.type';

@UntilDestroy()
@Component({
    selector: 'app-brand-voice-detail',
    template: `
        <app-modal>
            <app-modal-title>
                <h3>
                    <span *ngIf="!_brandVoice()">Create </span>Brand Voice
                </h3>
            </app-modal-title>
            <app-modal-body>
                <form [formGroup]="form" formGroupFinder>
                    <app-generic-form [data]="formStructure" [edgeless]="true"></app-generic-form>
                </form>
            </app-modal-body>
            <app-modal-footer>
                <app-button type="button" [loading]="loading()" [disabled]="loading()"
                            (click)="_brandVoice() ? update() : create()">
                    {{_brandVoice() ? 'Save' : 'Create' }}
                </app-button>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrl: './brand-voice-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandVoiceDetailComponent implements OnInit {
    @Input() set brandVoice(brandVoice: BrandVoice) {
        this._brandVoice.set(brandVoice);

        if (this.form) {
            this.form.patchValue(brandVoice);
        }
    }

    public _brandVoice = signal<BrandVoice | null>(null);
    public loading = signal(false);

    public formStructure: {type: string, fields: Field[]} = {
        type: 'generic-form',
        fields: [
            {
                type: 'text',
                label: 'Name',
                placeholder: 'Name',
                required: true,
                maxLength: 50,
                name: 'name',
            },
            {
                type: 'textarea',
                label: 'Description of the Brand Voice',
                placeholder: 'Description',
                required: true,
                maxLength: 7000,
                name: 'text',
            }
        ],
    };

    private fb = inject(FormBuilder);
    private store = inject(Store);
    private actions = inject(Actions);
    private modalContainer = inject(ModalContentContainerComponent);

    form = this.fb.nonNullable.group({
        name: ['', Validators.required],
        text: ['', Validators.required],
    });

    ngOnInit() {
        if (this._brandVoice()) {
            this.form.patchValue(this._brandVoice() as BrandVoice);
        }
    }

    create() {
        if (!this.form.valid) return;

        this.loading.set(true);
        this.store.dispatch(BrandVoiceActions.createBrandVoice({ brandVoice: this.form.getRawValue() }));

        this.actions.pipe(
            ofType(BrandVoiceActions.createBrandVoiceSuccess, BrandVoiceActions.createBrandVoiceFailure),
            first(),
        ).subscribe(action => {
            this.loading.set(false);

            if (action.type === BrandVoiceActions.createBrandVoiceSuccess.type) {
                this.modalContainer.onDismiss();
            }
        });
    }

    update() {

        if (!this.form.valid || !this._brandVoice()) return;
        console.log('update')
        const brandVoice = {
            ...this._brandVoice(),
            ...this.form.getRawValue(),
        } as Update<BrandVoice>;

        this.loading.set(true);
        this.store.dispatch(BrandVoiceActions.updateBrandVoice({ brandVoice }));

        this.actions.pipe(
            ofType(BrandVoiceActions.updateBrandVoiceSuccess, BrandVoiceActions.updateBrandVoiceFailure),
            first(),
        ).subscribe(action => {
            this.loading.set(false);

            if (action.type === BrandVoiceActions.updateBrandVoiceSuccess.type) {
                this.modalContainer.onDismiss();
            }
        });
    }
}
