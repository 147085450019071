import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChatActions } from '../../+store/chat.actions';
import { PromptLibrary } from '../../../prompt-library/+store/prompt-library.model';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { ChatInputFieldComponent } from '../../components/chat-input-field/chat-input-field.component';
import { ChatMessageComponent } from '../../components/chat-message/chat-message.component';
import { ChatModelSettingsComponent } from '../../components/chat-model-settings/chat-model-settings.component';
import { ChatModelSwitcherComponent } from '../../components/chat-model-switcher/chat-model-switcher.component';
import { ChatTopBarComponent } from '../../components/chat-top-bar/chat-top-bar.component';

@Component({
    selector: 'app-chat-create',
    standalone: true,
    imports: [
        AsyncPipe,
        ChatInputFieldComponent,
        ChatMessageComponent,
        ChatModelSettingsComponent,
        ChatModelSwitcherComponent,
        FormsModule,
        MainWrapperComponent,
        ReactiveFormsModule,
        ChatTopBarComponent,
    ],
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="flex flex-col items-center h-full w-full">
                <app-chat-top-bar class="max-w-[1200px]"></app-chat-top-bar>

                <div class="mt-auto mb-5 pt-5 w-full max-w-[1200px] px-5">
                    <app-chat-input-field (onSubmit)="submit()" formControlName="text"></app-chat-input-field>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrl: './chat-create.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatCreateComponent {
    store = inject(Store);
    route = inject(ActivatedRoute);
    router = inject(Router);
    fb = inject(FormBuilder);
    state = signal<string>('');

    form!: FormGroup;

    ngOnInit() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            settings: [{ temperature: 0.8, documentSearch: false }],
            private: [false],
            text: ['', Validators.required],
        });

        const state = this.router.lastSuccessfulNavigation?.extras.state as { promptTemplate: PromptLibrary };
        if (state) {
            this.form.patchValue({ text: state.promptTemplate.prompt });
        }
    }

    submit() {
        const formValue = this.form.getRawValue();
        this.store.dispatch(
            ChatActions.createChat({
                text: formValue.text,
                model: formValue.model,
                settings: formValue.settings,
                private: formValue.private,
            }),
        );
    }
}
