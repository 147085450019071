import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DropzoneFile } from 'dropzone';
import { map, switchMap } from 'rxjs/operators';
import { ModalService } from '../../../shared/modal/modal.service';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { UploadCollectionActions } from '../../../upload/+store/folder/upload-collection.actions';
import { UploadCollection } from '../../../upload/+store/folder/upload-collection.model';
import { selectUploadCollectionById } from '../../../upload/+store/folder/upload-collection.selectors';
import { UploadActions } from '../../../upload/+store/upload/upload.actions';
import { Upload } from '../../../upload/+store/upload/upload.model';
import { selectUploadByCollectionId } from '../../../upload/+store/upload/upload.selectors';
import { UpdateCollectionModalComponent } from '../../modals/update-collection-modal/update-collection-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-document-detail',
    template: `
        <div class="w-full p-5 bg-gray-50/60 h-full overflow-auto">
            <ng-container *ngIf="uploadCollection$ | async as uploadCollection">
                <div class="flex w-full flex-row justify-between">
                    <h2 class="font-bold text-lg w-full">
                        <fa-icon class="mr-3" size="sm" [icon]="['fad', 'folder-open']"></fa-icon>
                        {{ uploadCollection.name }}
                        <app-button color="transparent" [square]="true" size="sm"
                                    (click)="editCollection(uploadCollection)"
                                    tp="Edit Collection Name">
                            <fa-icon class="text-slate-400" [icon]="['fad', 'pencil']" size="xs"
                                     [fixedWidth]="true"></fa-icon>
                        </app-button>
                    </h2>

                    <app-button color="transparent" (confirmedClick)="deleteUploadCollection(uploadCollection)">
                        <fa-icon class="text-red-300 group-hover/button:text-red-400"
                                 [icon]="['fas', 'trash']"></fa-icon>
                    </app-button>
                </div>

                <div class="mt-5">
                    <app-drag-drop-upload [uploadCollectionId]="uploadCollection.id" (uploading)="uploading($event)"
                                          (finished)="refresh()"></app-drag-drop-upload>
                </div>
            </ng-container>


            <div class="mt-6 rounded-md overflow-hidden shadow-lg shadow-gray-200/80 bg-white">
                <table class="w-full table">
                    <thead>
                    <tr>
                        <th class="w-[1px]"></th>
                        <th>Name</th>
                        <th class="!text-right">Size</th>
                        <th class="!text-right">Tokens</th>
                        <th class="w-px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let file of uploadingFiles?.values()">
                        <td>

                        </td>
                        <td>
                            <div>{{ file.name }}</div>
                            <div class="text-sm text-slate-400">{{ file.type }}</div>
                        </td>
                        <td class="!text-right">
                            {{ file.size | bytes: 0 }}
                        </td>
                        <td></td>
                        <td>
                            {{ (file.upload!.progress / 100) | percent: '1.0-0' }}
                        </td>
                    </tr>

                    <tr *ngIf="(uploads$ | async)?.length === 0 && !uploadingFiles?.values()">
                        <td colspan="5">
                            <div class="p-12 text-center font-semibold">
                                Upload your first document
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let upload of uploads$ | async">
                        <td>
                            <app-round-checkbox></app-round-checkbox>
                        </td>
                        <td>
                            <div>{{ upload.fileName }}</div>
                            <div class="text-sm text-slate-400">{{ upload.mimeType }}</div>
                        </td>
                        <td class="!text-right">{{ +upload.fileSize | bytes: 0 }}</td>
                        <td class="!text-right">{{ upload.tokenLength | number}}</td>
                        <td class="!text-right">
                            <div class="flex justify-end">
                                <app-button color="transparent" (confirmedClick)="delete(upload)">
                                    <fa-icon [icon]="['fas', 'trash']" size="sm" [fixedWidth]="true"></fa-icon>
                                </app-button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
    styleUrl: './document-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDetailComponent implements OnInit {
    route = inject(ActivatedRoute);
    store = inject(Store);
    cdr = inject(ChangeDetectorRef);
    modalService = inject(ModalService);
    uploadingFiles?: Map<string, DropzoneFile>;

    uploadCollection$ = this.route.paramMap.pipe(
        map(params => params.get('id')),
        filterNullish(),
        switchMap(id => this.store.select(selectUploadCollectionById(id))),
        filterNullish(),
        untilDestroyed(this),
    );

    uploads$ = this.uploadCollection$.pipe(
        switchMap(uploadCollection => this.store.select(selectUploadByCollectionId(uploadCollection.id))),
    );

    ngOnInit() {
        this.uploadCollection$.subscribe(uploadCollection =>
            this.store.dispatch(UploadActions.loadUploadsByCollectionId({ uploadCollectionId: uploadCollection.id })));
    }

    refresh() {

    }

    uploading(uploadingFiles: Map<string, DropzoneFile>) {
        this.uploadingFiles = uploadingFiles;
        this.cdr.markForCheck();
    }

    delete(upload: Upload) {
        this.store.dispatch(UploadActions.deleteUpload({ id: upload.id }));
    }

    deleteUploadCollection(uploadCollection: UploadCollection) {
        this.store.dispatch(UploadCollectionActions.deleteUploadCollection({id: uploadCollection.id}));
    }

    editCollection(uploadCollection: {}) {
        const modalRef = this.modalService.open(UpdateCollectionModalComponent);
        modalRef.contentInstance.collection = uploadCollection;
    }
}
