import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-popover-wrapper',
    template: `
        <div class="rounded-xl overflow-hidden shadow-lg">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./popover-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverWrapperComponent implements AfterViewInit {
    @Output() close = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
    ) {
    }

    ngAfterViewInit(): void {
        fromEvent(document, 'click')
            .pipe(
                skip(1),
                filter(event => !this.elementRef.nativeElement.contains(event.target)),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.close.emit();
            });
    }

}
