import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { catchError, first, Observable, switchMap, take } from 'rxjs';
import { skip } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppState } from '../reducers';
import { filterNullish } from '../shared/utilities/filter-nullish.operator';
import { AuthActions } from './+store/auth.actions';
import { selectAccessToken } from './+store/auth.selectors';


@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<AppState>,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const isLoginUrl = request.url.includes('login') || request.url.includes('accept-invitation') || request.url.includes('signup') || request.url.includes('forgot-password');
        if (isApiUrl && !isLoginUrl) {
            return this.store.pipe(
                select(selectAccessToken),
                filterNullish(),
                take(1),
                switchMap((accessToken: string) => next.handle(this.addBearerToken(request, accessToken)).pipe(
                        catchError((err) => {
                            if (err instanceof HttpErrorResponse) {
                                if (err.status === 401) {
                                    this.store.dispatch(AuthActions.getNewAccessToken());

                                    return this.store.pipe(
                                        select(selectAccessToken),
                                        skip(1),
                                        filterNullish(),
                                        first(),
                                        switchMap((token: string) => next.handle(this.addBearerToken(request, token))
                                        .pipe(
                                            catchError(error => {
                                                if (error instanceof HttpErrorResponse) {
                                                    this.checkError(error);
                                                }
                                                throw error;
                                            })
                                        )),
                                    );
                                }
                            }
                            throw err;
                        }),
                    )
                ));
        }

        return next.handle(request).pipe(
            catchError(err => {
                throw err;
            })
        );
    }

    addBearerToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    checkError(err: any) {
        // TODO: Vielleicht irgendwann Fehler besser abfangen und Toasty anzeigen
        this.store.dispatch(AuthActions.logout());
    }

}
