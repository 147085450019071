import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat } from '../../../chat/+store/chat.model';
import { selectChatByProjectId } from '../../../chat/+store/chat.selectors';
import { ProjectActions } from '../../../project/+store/project.actions';
import { filterNullish } from '../../utilities/filter-nullish.operator';

@UntilDestroy()
@Component({
    selector: 'app-chat-list',
    template: `
        <div *ngFor="let chat of chats$ | async; let i = index">
            <!--          <div [class.bg-slate-800]="i === 1" class="hover:bg-slate-800/50 cursor-pointer">-->
            <app-chat-list-item [chat]="chat"></app-chat-list-item>
            <!--          </div>-->
        </div>
    `,
    styleUrls: ['./chat-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListComponent implements OnInit {
    chats$!: Observable<Chat[]>;

    constructor(
        private store: Store,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(ProjectActions.loadProjects());

        const projectId$ = this.route.paramMap.pipe(
            map(params => params.get('projectId')),
            filterNullish(),
            untilDestroyed(this),
        );

        this.chats$ = projectId$.pipe(
            switchMap(projectId => this.store.pipe(select(selectChatByProjectId(projectId)))),
        );
    }

}
