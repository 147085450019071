import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WorkflowOutput } from './+store/workflow-outputs/workflow-output.model';

@Injectable({
    providedIn: 'root',
})
export class WorkflowOutputService {
    http = inject(HttpClient);

    generateOutput(payload: {data: any[], outputs: number, workflowId: string}) {
        return this.http.post<boolean>(`${environment.apiUrl}/workflow/run/${payload.workflowId}`, {
            data: payload.data,
            outputs: payload.outputs,
        });
    }

    loadOutputsByWorkflowId(workflowId: string) {
        return this.http.get<WorkflowOutput[]>(`${environment.apiUrl}/workflow/${workflowId}/outputs`);
    }
}
