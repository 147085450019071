import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { catchError, exhaustMap, of, switchMap, tap } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ModalService } from '../../shared/modal/modal.service';
import { ChatService } from '../chat.service';
import { NewChatModalComponent } from '../components/new-chat-modal/new-chat-modal.component';
import { ChatActions } from './chat.actions';
import { Chat } from './chat.model';


@Injectable()
export class ChatEffects {
    // TODO: improve/do better and different!
    chatMessageResponse$ = createEffect(() => this.socket.fromEvent<Chat>('updateChat').pipe(
        mergeMap(chat => {
            const chatMessages = chat.messages;
            const lastMessage = chatMessages[chatMessages.length - 1];
            return lastMessage.type === 'ai' ? [
                ChatActions.upsertChats({ chats: [chat] }), ChatActions.setChatLoading({
                id: chat.id,
                loading: false
                })
            ] : [ChatActions.upsertChats({ chats: [chat] })]
        }),
    ));

    createChat$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.createChat),
        exhaustMap(action => this.chatService.createChat({ model: action.model, settings: action.settings }).pipe(
            mergeMap(chat => [
                ChatActions.createChatSuccess({ chat }),
                ChatActions.sendChatMessage({ text: action.text, chatId: chat.id }),
            ]),
            catchError(error => of(ChatActions.createChatFailure({ error }))),
        )),
    ));

    createChatSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.createChatSuccess),
        tap(action => this.router.navigate(['/chat', action.chat.id])),
    ), { dispatch: false });

    loadAllChats$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.loadAllChats),
        exhaustMap(() => this.chatService.loadAll().pipe(
            map(chats => ChatActions.loadAllChatsSuccess({ chats })),
            catchError(error => of(ChatActions.loadAllChatsFailure({ error }))),
        )),
    ));

    otherContextResponse$ = createEffect(() => this.socket.fromEvent<{ chatId: string, oldMessage: string,
        updatedMessage: string, updatedContext: string }>('otherContext').pipe(
        tap(({chatId, oldMessage, updatedMessage, updatedContext}) => {
            const modal = this.modalService.open(NewChatModalComponent, {centered: true, size: 'auto'});
            modal.contentInstance.chatId = chatId;
            modal.contentInstance.message = oldMessage;
            modal.contentInstance.updatedMessage = updatedMessage;
            modal.contentInstance.updatedContext = updatedContext;
        }),
        map(({chatId}) => ChatActions.setChatLoading({id: chatId, loading: false}))
    ))

    loadChatById$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.loadChatById),
        switchMap(action => this.chatService.loadById(action.id).pipe(
            map(chat => ChatActions.loadChatByIdSuccess({chat})),
            catchError(error => of(ChatActions.loadChatByIdFailure({error}))),
        )),
    ));

    loadChatsByProjectId$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.loadChatsByProjectId),
        switchMap(action => this.chatService.loadByProjectId(action.projectId).pipe(
            map(chats => ChatActions.loadChatsByProjectIdSuccess({chats})),
            catchError(error => of(ChatActions.loadChatsByProjectIdFailure({error}))),
        )),
    ));

    sendChatMessage$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.sendChatMessage),
        tap(action => this.chatService.sendChatMessage(action)),
    ), {dispatch: false});

    updateChat$ = createEffect(() => this.actions$.pipe(
        ofType(ChatActions.updateChat),
        mergeMap(action => {
            return this.chatService.updateChat(action.chat).pipe(
                map(chat => ChatActions.updateChatSuccess({chat})),
                tap(() => this.toast.success('Settings saved.')),
                catchError(error => {
                    return of(ChatActions.updateChatFailure({error}))
                }),
            )
        })
    ))

    constructor(
        private actions$: Actions,
        private chatService: ChatService,
        private socket: Socket,
        private modalService: ModalService,
        private router: Router,
        private toast: HotToastService,
    ) {
    }
}
