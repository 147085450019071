import { ChangeDetectionStrategy, Component, forwardRef, inject, Input, signal, WritableSignal } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import {
    BrandVoiceListComponent,
} from '../../../../brand-voice/components/brand-voice-list/brand-voice-list.component';
import { ModalService } from '../../../modal/modal.service';
import { isMaxLengthInvalid, isRequiredInvalid } from '../../../utilities/input.validations';
import { TextSelectorField } from '../../types/text-selector-field.type';
import { TypedValidationErrors } from '../../types/typed-validation-errors.type';

export type OnSelectChangeFunction = (args: {value: string, visibleValue: string}) => void;

@Component({
    selector: 'app-generic-textfield-with-selector',
    template: `
        <ng-container *ngIf="field">
            <label class="form-label" *ngIf="field.label.length > 0">
                {{ field.label }}<span *ngIf="field.required">*</span>
            </label>
            <div class="bg-slate-100 pl-2 pr-1 py-2 rounded relative flex items-center gap-1">
                <div class="w-full relative">
                    <input type="text" class="form-input"
                           [placeholder]="field.placeholder ?? ''"
                           [disabled]="isDisabled"
                           [class.invisible]="visibleValue()"
                           [value]="value()"
                           (input)="onChange($event)"
                           (blur)="onTouch()" />

                    <div class="form-input bg-white text-sky-500 absolute inset-0 font-bold flex pr-0" *ngIf="visibleValue(); let dummyValue">
                        <div class="overflow-hidden overflow-ellipsis whitespace-nowrap flex-grow">{{dummyValue}}</div>

                        <button class="group/button w-[40px] h-full flex items-center justify-center"
                                (click)="removeSelectedValue()" type="button">
                            <fa-icon [icon]="['fas', 'times']" size="lg" [fixedWidth]="true"
                                     class="text-slate-600 opacity-50 group-hover/button:opacity-100 group-hover/button:text-red-600 transition-opacity">
                            </fa-icon>
                        </button>
                    </div>
                </div>

                <button class="group/button w-[40px] h-[40px] flex items-center justify-center"
                        (click)="triggerSelect()" type="button">
                    <fa-icon [icon]="['fas', 'square-dashed-circle-plus']" size="lg" [fixedWidth]="true"
                             class="text-sky-500 opacity-50 group-hover/button:opacity-100 transition-opacity" *ngIf="!visibleValue(); else changeIcon">
                    </fa-icon>
                    <ng-template #changeIcon>
                        <fa-icon [icon]="['fas', 'arrows-rotate']" size="lg" [fixedWidth]="true"
                                 class="text-sky-500 opacity-50 group-hover/button:opacity-100 group-hover/button:rotate-45 transition-opacity transition-transform">
                        </fa-icon>
                    </ng-template>
                </button>

                <div class="absolute text-xs right-2 -bottom-4 tabular-nums"
                     [ngClass]="!!errorObject()?.['maxLength'] ? 'text-red-600' : 'text-slate-400'"
                     *ngIf="field.maxLength">
                    {{ value().length }} / {{ field.maxLength }}
                </div>
            </div>

            <app-field-error-messages
                [errorObject]="errorObject()"
                [touched]="touched()">
            </app-field-error-messages>
        </ng-container>
    `,
    styleUrl: './generic-textfield-with-selector.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericTextfieldWithSelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GenericTextfieldWithSelectorComponent),
            multi: true,
        },
    ],
})
export class GenericTextfieldWithSelectorComponent {
    @Input() field!: TextSelectorField;

    private modal = inject(ModalService);

    isDisabled = false;
    value = signal('');
    visibleValue = signal<string | null>(null);
    touched = signal(false);

    errorObject: WritableSignal<TypedValidationErrors | null> = signal(null);

    changeFn = (p: string) => {
    };

    onChange($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        this.changeFn(target.value);
        this.value.set(target.value);
        this.visibleValue.set(null);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    touchFn = () => {
    };

    onTouch() {
        this.touched.set(true);
        this.touchFn();
    }

    writeValue(value: string): void {
        this.value.set(value);
    }

    triggerSelect() {
        switch (this.field.source) {
            case 'brandVoice':
                this.selectBrandVoice();
        }
    }

    selectBrandVoice() {
        const modalref = this.modal.open(BrandVoiceListComponent);
        modalref.contentInstance.mode = 'select';
        modalref.contentInstance.selectFn = this.selectValue.bind(this);
    }

    selectValue<OnSelectChangeFunction>(selectedValue: {value: string, visibleValue: string}) {
        this.changeFn(selectedValue.value);
        this.value.set(selectedValue.value);
        this.visibleValue.set(selectedValue.visibleValue);
    }

    removeSelectedValue(){
        this.changeFn('');
        this.value.set('');
        this.visibleValue.set(null);
    }

    // error validation
    setErrorAndReturn(errors: TypedValidationErrors | null) {
        this.errorObject.set(errors);
        return errors;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Validate required
        if (isRequiredInvalid(control.value, this.field.required)) {
            return this.setErrorAndReturn({ required: true });
        }

        // Validate max length
        if (isMaxLengthInvalid(control.value.length, this.field.maxLength)) {
            return this.setErrorAndReturn({ maxLength: this.field.maxLength?.toString() });
        }

        // No errors found
        return this.setErrorAndReturn(null);
    }
}


