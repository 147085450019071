import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { timer } from 'rxjs';
import { Chat, ChatMessage } from '../../+store/chat.model';
import { ModalService } from '../../../shared/modal/modal.service';
import {
    ChatEmbeddingSearchModalComponent,
} from '../../modals/chat-embedding-search-modal/chat-embedding-search-modal.component';

@Component({
    selector: 'app-chat-message-document-search',
    template: `
        <div class="w-full py-7 flex justify-center bg-blue-100/50" *ngIf="isLastMessage">
            <div class="flex flex-col justify-center max-w-[1200px] w-full px-5">
                <div class="grow mb-4" [innerHTML]="message.text"></div>
                <app-button (click)="openModal()">Search in Documents</app-button>
            </div>
        </div>
    `,
    styleUrls: ['./chat-message-document-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageDocumentSearchComponent implements OnInit {
    modalService = inject(ModalService);
    form!: FormGroup;
    message!: ChatMessage<{searchString: string}>;
    selectedFolderPaths = new Set<string>();

    @Input() chat!: Chat | null;

    @Input('message') set _message(message: ChatMessage<{searchString: string}>) {
        this.message = message;

        if (this.form) {
            this.form.patchValue({ search: message.arguments.searchString });
        }
    }

    get isLastMessage() {
        if (this.chat?.messages && this.chat.messages.length > 0) {
            const [lastMessage] = this.chat?.messages.slice(-1);
            return lastMessage.id === this.message.id;
        }
        return true;
    }

    ngOnInit() {
    }

    openModal() {
        const modalRef = this.modalService.open(ChatEmbeddingSearchModalComponent, { size: 'auto' });
        modalRef.contentInstance.chat = this.chat;
        timer(1).subscribe(() => modalRef.contentInstance.form.patchValue({ searchText: this.message.arguments.searchString }));
    }
}
