import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { exhaustMap, map, mergeMap } from 'rxjs/operators';
import { TenantActions } from '../../tenant/+store/tenant.actions';
import { StripeService } from '../stripe.service';
import { PaymentActions } from './payment.actions';

@Injectable()
export class PaymentEffects {

    cancelSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.cancelSubscription),
            exhaustMap(action => this.stripeService.cancelSubscription(action.subscriptionId).pipe(
                tap(() => this.toast.success('Subscription cancelled')),
                mergeMap(subscription => [
                    PaymentActions.cancelSubscriptionSuccess({ subscription }),
                    TenantActions.loadUserTenants(),
                ]),
            )),
        );
    });

    loadInvoices$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadInvoicesOfTenant),
            exhaustMap(action => this.stripeService.loadInvoiceList().pipe(
                map(invoices => PaymentActions.loadInvoicesSuccess({ invoices })),
            )),
        );
    });

    getSubscriptionInvoices$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadInvoicesOfSubscription),
            exhaustMap(action => this.stripeService.getSubscriptionInvoices(action.subscriptionId).pipe(
                map(invoices => PaymentActions.loadInvoicesSuccess({ invoices })),
            )),
        );
    });

    loadSubscriptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadSubscriptionsByTenant),
            exhaustMap(action => this.stripeService.loadSubscriptionList().pipe(
                map(subscriptions => PaymentActions.loadSubscriptionsSuccess({ subscriptions })),
            )),
        );
    });

    loadSubscriptionById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadSubscriptionById),
            exhaustMap(action => this.stripeService.getSubscriptionDetails(action.subscription).pipe(
                map(subscription => PaymentActions.loadSubscriptionsSuccess({ subscriptions: [subscription] })),
            )),
        );
    });

    loadPrices$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadPrices),
            exhaustMap(() => this.stripeService.loadPrices().pipe(
                map(prices => PaymentActions.loadPricesSuccess({ prices })),
            )),
        );
    });

    loadProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PaymentActions.loadProducts),
            exhaustMap(() => this.stripeService.loadProducts().pipe(
                map(products => PaymentActions.loadProductsSuccess({ products })),
            )),
        );
    });


    constructor(
        private actions$: Actions,
        private stripeService: StripeService,
        private toast: HotToastService,
    ) {
    }
}
