import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { diffWordsWithSpace } from 'diff';
import { WorkflowOutput } from '../../../workflow/+store/workflow-outputs/workflow-output.model';

@Component({
    selector: 'app-render-spellcheck',
    template: `
        <div class="text-sm -mx-2 flex">
            <app-button size="sm" color="transparent" [square]="true" tp="Copy"
                        [cdkCopyToClipboard]="output.text">
                <fa-icon class="text-slate-300 group-hover:text-slate-700"
                         [icon]="['fas', 'clipboard']"></fa-icon>
            </app-button>

            <div class="ml-auto">
                <app-button size="sm" color="transparent" tp="Copy" (click)="toggleShowChanges()">
                    <ng-container *ngIf="showChanges">
                        <fa-icon [icon]="['fad', 'eye']" class="mr-1"></fa-icon>
                        hide changes
                    </ng-container>
                    <ng-container *ngIf="!showChanges">
                        <fa-icon [icon]="['fad', 'eye-slash']" class="mr-1"></fa-icon>
                        show changes
                    </ng-container>
                </app-button>
            </div>
        </div>

        <div [innerHTML]="html"></div>
    `,
    styleUrl: './render-spellcheck.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderSpellcheckComponent {
    @Input() output!: WorkflowOutput;
    @Input() showChanges = false;

    cdr = inject(ChangeDetectorRef);

    get html() {
        if (this.output.text && this.output.inputs[0]['textToProofread'] && this.showChanges) {
            const differences = diffWordsWithSpace(this.output.text, this.output.inputs[0]['textToProofread'], {});

            return differences
                .map(d => {
                    console.log(d);

                    if (d.added) {
                        return ` <span class="text-red-600 font-semibold line-through">${d.value.trim()}</span> `;
                    }

                    if (d.removed) {
                        return `<span class="text-green-600 font-semibold">${d.value}</span>`;
                    }

                    return d.value;
                }).join('');
        } else if (this.output.text) {
            return this.output.text;
        }

        return '';
    }

    toggleShowChanges() {
        this.showChanges = !this.showChanges;
        this.cdr.markForCheck();
    }
}
