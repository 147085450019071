import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthActions } from '../../+store/auth.actions';
import { selectAuthError, selectAuthLoading } from '../../+store/auth.selectors';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';

@Component({
    selector: 'app-login',
    template: `
        <app-auth-wrapper>
            <div class="w-full">
                <img src="assets/logo/logo.svg" width="150" alt="Logo" class="mb-8">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    @if (reset()) {
                        <div class="bg-green-300 p-3 text-sm text-green-800 rounded border border-green-400 mb-3"
                        >
                            If we were able to find your mail address in our system, you should receive an mail shortly.
                        </div>
                    }

                    <div class="form-group mb-2">
                        <label class="block text-slate-600 mb-1" for="username">
                            E-Mail
                        </label>
                        <input
                            class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="text" placeholder="E-Mail Address" formControlName="email">
                    </div>
                    <div class="form-group mt-5">
                        <label class="block text-slate-600 mb-1" for="password">
                            Password
                        </label>
                        <input
                            class="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password" type="password" placeholder="Password" formControlName="password">
                    </div>

                    @if (!!(error$ |async)) {
                        <div class="text-red-500 text-xs italic mt-3">
                            {{ (error$ |async).error.message }}
                        </div>
                    }


                    <div class="mt-8">
                        <app-button type="submit" [fullWidth]="true">
                            <div class="py-1">Login</div>
                        </app-button>
                    </div>

                    <div class="mt-4 text-sm">
                        Forgot Password? <a class="font-semibold underline cursor-pointer"
                                            [routerLink]="['/forgot-password']">Reset Password</a>
                    </div>

                    <div class="mt-2 text-sm">
                        No account yet? <a class="font-semibold underline cursor-pointer" [routerLink]="['/signup']">Signup
                        Now</a>
                    </div>
                </form>
            </div>
        </app-auth-wrapper>
    `,
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AuthWrapperComponent, ReactiveFormsModule, ButtonComponent, RouterLink, AsyncPipe],
})
export class LoginComponent implements OnInit {
    store = inject(Store);
    route = inject(ActivatedRoute);

    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required])
    })
    loading$: Observable<boolean> = of(false);
    error$: Observable<any> = of(null);
    reset = toSignal(this.route.queryParams.pipe(map(params => params['reset']), filter(reset => reset === '1')));

    ngOnInit() {
        this.loading$ = this.store.select(selectAuthLoading);
        this.error$ = this.store.select(selectAuthError);
    }

    login() {
        console.log('test', this.loginForm.valid, this.loginForm.errors);
        if (!this.loginForm.valid) {
            return;
        }

        this.store.dispatch(AuthActions.login({
            email: this.loginForm.value.email as string,
            password: this.loginForm.value.password as string
        }));
    }

}
