import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { AuthEffects } from './+store/auth.effects';
import { AuthWrapperComponent } from './components/auth-wrapper/auth-wrapper.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { SignupSuccessComponent } from './pages/signup-success/signup-success.component';
import { SignupComponent } from './pages/signup/signup.component';


@NgModule({
  declarations: [
      LoginComponent,
      PasswordResetComponent,
      AuthWrapperComponent,
      SignupComponent,
      SignupSuccessComponent,
      ForgotPasswordComponent,
  ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([AuthEffects]),
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
    ],
})
export class AuthModule { }
