import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@Component({
  selector: 'app-template-delete-modal',
  template: `
      <app-modal>
          <app-modal-title>
              <h3>
                  Delete prompt
              </h3>
          </app-modal-title>
          <app-modal-body class="max-h-80">
              <p class="text-white">
                  Do you want to delete the template prompt? You will not be able to undo this action.
              </p>
          </app-modal-body>
          <app-modal-footer>
              <div class="flex justify-between">
                  <button
                          (click)="modalContainer.onDismiss()"
                          type="button" class="btn-light">
                      Cancel
                  </button>

                  <button
                          (click)="delete()"
                          type="button" class="btn-danger">
                      Delete
                  </button>
              </div>
          </app-modal-footer>
      </app-modal>
  `,
  styleUrls: ['./template-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateDeleteModalComponent {
    @Input() templateId!: string;

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
    }

    async delete() {
        this.store.dispatch(TemplateActions.deleteTemplate({id: this.templateId}));
        this.modalContainer.onDismiss();

    }
}
