import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { filterNullish } from '../../utilities/filter-nullish.operator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ChatActions } from '../../../chat/+store/chat.actions';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { selectChatById } from '../../../chat/+store/chat.selectors';
import { Chat } from '../../../chat/+store/chat.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { faInfo, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@UntilDestroy()
@Component({
    selector: 'app-chat-settings',
    template: `
        <form [formGroup]="form" class="p-3" (ngSubmit)="save()">
            <div class="form-group mb-3">
                <label class="block text-gray-400 text-sm font-bold mb-2" for="temperature">
                    Temperature
                    <fa-icon [icon]="faInfo" class="ml-1 pointer"
                             tp="Temperature is a parameter that controls the “creativity” or randomness of the text generated by the AI.
                             A higher temperature (e.g., 0.7) results in more diverse and creative output,
                             while a lower temperature (e.g., 0.2) makes the output more deterministic and focused.
                            The temperature is a number between 0 and 1.">
                    </fa-icon>
                </label>
                <input
                    class="shadow appearance-none bg-gray-300 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="temperature" type="number" formControlName="temperature" step="0.02" min="0" max="1">
                <div class="text-red-500 text-xs italic mt-3" *ngIf="form.get('temperature')?.errors">
                    The field must not be empty. The value must be between 0 and 1.
                </div>
            </div>
            <div class="form-group mt-6">
                <label class="block text-gray-400 text-sm font-bold mb-2" for="modelName">
                    Model name
                    <fa-icon [icon]="faInfo" class="ml-1 pointer"
                             tp="You can choose from different GPT modals. Depending on your selection, the maximum
                             number of tokens and the price per 1000 tokens will change.  There may also be differences
                             in the processing time. On our price overview page (tbd) you can read which model
                             probably fits your needs best.">
                    </fa-icon>
                </label>
                <select formControlName="modelName"
                        class="shadow bg-gray-300 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option *ngFor="let model of models" [value]="model.value">
                        {{model.name}}
                    </option>
                </select>
            </div>

            <div class="mt-6 text-xs italic text-red-400" *ngIf="form.dirty">
                Attention: Unsaved changes. All requests from the current chat will be performed with the old values.
            </div>

            <div class="mt-10 flex justify-end">
                <button class="btn-success" [disabled]="!form.valid"
                        type="submit">
                    Save
                </button>
            </div>
        </form>
    `,
    styleUrls: ['./chat-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSettingsComponent implements OnInit {
    form!: FormGroup;
    chatId$!: Observable<string | null>;
    models = [{
        name: 'GPT 4',
        value: 'gpt-4',
    }, {
        name: 'GPT 3.5 Turbo',
        value: 'gpt-3.5-turbo'
    }, {
        name: 'GPT 3',
        value: 'gpt-3'
    }];
    faInfo = faInfoCircle;

    constructor(
        public route: ActivatedRoute,
        private store: Store<AppState>,
        private http: HttpClient,
        private router: Router,
    ) {
    }


    ngOnInit() {
        this.form = new FormGroup({
            temperature: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(1)]),
            modelName: new FormControl('gpt-4', Validators.required),
        });

        this.chatId$ = this.route.paramMap.pipe(
            map(params => params.get('chatId')),
        );

        this.chatId$.pipe(
            untilDestroyed(this),
            filterNullish(),
            switchMap(id => this.store.select(selectChatById(id))),
            filterNullish(),
        ).subscribe(chat => {
            this.form.patchValue(chat);
        })
    }

    async save() {
        if (this.form.invalid) {
            console.log('invalid');
            this.form.markAllAsTouched();
            return;
        }

        let chatId = await firstValueFrom(this.chatId$);
        if (!chatId) {
            const chat = await firstValueFrom(this.http.post<Chat>(`${environment.apiUrl}/chat`, {
                projectId: this.route.snapshot.paramMap.get('projectId'),
                name: 'New Chat',
                modelName: this.form.value.modelName,
                temperature: this.form.value.temperature
            }));
            await this.router.navigate(['./', 'chat', chat.id], {relativeTo: this.route});
            chatId = chat.id;
        }

        this.store.dispatch(ChatActions.updateChat({
            chat: {
                id: chatId, modelName: this.form.value.modelName, temperature: this.form.value.temperature
            }
        }));
        this.form.markAsPristine();
    }
}
