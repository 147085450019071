import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { EmbeddingEffects } from './+store/embedding.effects';
import { EmbeddingResultItemComponent } from './components/embedding-result-item/embedding-result-item.component';


@NgModule({
    declarations: [
        EmbeddingResultItemComponent,
    ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([EmbeddingEffects]),
        SharedModule,
    ],
    exports: [
        EmbeddingResultItemComponent,
    ],
})
export class EmbeddingModule {
}
