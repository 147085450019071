import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { filter, firstValueFrom, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PaymentActions } from '../../payment/+store/payment.actions';
import {
    selectActiveSubscription,
    selectAllInvoices,
    selectAllSubscription,
    selectProductById,
} from '../../payment/+store/payment.selectors';
import { StripeInvoice } from '../../payment/interfaces/stripe-invoice.interface';
import { StripeProduct } from '../../payment/interfaces/stripe-product.interface';
import { StripeSubscription } from '../../payment/interfaces/stripe-subscription.interface';
import {
    DeleteSubscriptionModalComponent,
} from '../../payment/modals/delete-subscription-modal/delete-subscription-modal.component';
import { StripeService } from '../../payment/stripe.service';
import { AppState } from '../../reducers';
import { ModalService } from '../../shared/modal/modal.service';
import { filterNullish } from '../../shared/utilities/filter-nullish.operator';
import { TenantActions } from '../../tenant/+store/tenant.actions';
import { selectUserTenant } from '../../tenant/+store/tenant.selectors';
import { PaymentSubscription, Tenant } from '../../tenant/tenant.interface';

@UntilDestroy()
@Component({
    selector: 'app-setting-payment',
    template: `
        <div class="px-12 py-6">
            <div *ngIf="!(activeSubscription$ | async) && (tenantCanceledActiveSubscription$ | async)"
                 class="w-full max-w-[700px] bg-yellow-100 px-6 py-5 rounded shadow flex md:items-center flex-col md:flex-row my-6">
                <div class="pr-6">
                    <div class="text-sm text-gray-400 mt-1">
                        You have canceled your subscription. You can still use our services until
                        <strong>{{(tenantCanceledActiveSubscription$ | async)?.endedAt | date: 'dd.MM.yyyy'}}</strong>
                    </div>
                </div>
            </div>

            <div *ngIf="!(activeSubscription$ | async)"
                 class="w-full max-w-[700px] bg-blue-50 px-6 py-5 rounded shadow flex md:items-center flex-col md:flex-row">
                <div class="pr-6">
                    <div class="text-slate-600 font-semibold">Free Plan</div>
                    <div class="text-sm text-gray-400 mt-1">Jumpstart your journey with essential features at no
                        cost. Zero commitment, upgrade when ready.
                    </div>
                </div>
                <div class="md:ml-auto mt-3 md:mt-0">
                    <app-button>
                        <div class="whitespace-nowrap" (click)="upgradeToPro()">Upgrade to Pro</div>
                    </app-button>
                </div>
            </div>

            <div *ngIf="activeSubscription$ | async as details"
                 class="w-full max-w-[700px] bg-blue-50 px-6 py-5 rounded shadow flex items-center">
                <div class="grow">
                    <div class="text-slate-600 font-semibold mb-2">Subscription
                        of {{(product$ |async)?.name}}</div>
                    <div class="text-sm text-gray-500">Status: <span
                        class="font-semibold capitalize">{{details.status}}</span></div>
                    <div class="text-sm text-gray-500">Interval: <span
                        class="font-semibold capitalize">{{details.plan.interval}}</span></div>
                    <!--                <div class="text-sm text-gray-400 mt-1">-->
                    <!--                    Amount: {{details.plan.amount / 100 | currency: details.plan.currency.toUpperCase()}}-->
                    <!--                </div>-->
                    <div class="text-sm text-gray-500">Next billing
                        date: <span
                            class="font-semibold<">{{details.current_period_end * 1000 | date: 'shortDate'}}</span>
                    </div>
                    <div class="mt-3">
                        <app-button color="white" [wrap]="false" (click)="openBillingModal()">
                            Update Billing Information
                        </app-button>
                    </div>
                </div>

                <div>
                    <app-button [wrap]="false"
                                (click)="openEndSubscriptionModal(details.id, details.current_period_end * 1000)">
                        End subscription
                    </app-button>
                </div>
            </div>

            <div *ngIf="(invoices$ | async) as invoices"
                 class="w-full max-w-[700px] flex flex-col mt-6">
                <div class="text-slate-600 mb-3 font-semibold">Invoices</div>
                <app-stripe-invoice-item *ngFor="let invoice of invoices"
                                         [invoice]="invoice"></app-stripe-invoice-item>
            </div>
        </div>
    `,
    styleUrls: ['./setting-payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingPaymentComponent implements OnInit {
    tenant$!: Observable<Tenant>;
    invoices$!: Observable<StripeInvoice[]>;

    subscriptions$!: Observable<StripeSubscription[]>;
    activeSubscription$!: Observable<StripeSubscription>;

    tenantCanceledActiveSubscription$!: Observable<PaymentSubscription | undefined>;
    product$!: Observable<StripeProduct>;

    constructor(
        private store: Store<AppState>,
        private stripeService: StripeService,
        private modal: ModalService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: HotToastService,
    ) {
    }

    ngOnInit() {
        this.route.queryParams
            .pipe(untilDestroyed(this))
            .subscribe(params => {
                const success = params['success'];
                const error = params['error'];

                if (success) {
                    this.toast.success('Subscription completed', {
                        duration: 5000,
                        position: 'top-center',
                    });
                }

                if (error) {
                    this.toast.error('Subscription could not be completed', {
                        duration: 5000,
                        position: 'top-center',
                    });
                }

                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: { success: null, error: null },
                });
            });


        this.store.dispatch(TenantActions.loadUserTenants());
        this.store.dispatch(PaymentActions.loadSubscriptionsByTenant());
        this.store.dispatch(PaymentActions.loadInvoicesOfTenant());
        this.store.dispatch(PaymentActions.loadProducts());

        this.tenant$ = this.store.pipe(
            select(selectUserTenant),
            filterNullish(),
        );

        this.invoices$ = this.store.pipe(select(selectAllInvoices), filter(x => x.length > 0));

        this.subscriptions$ = this.store.select(selectAllSubscription).pipe(
            filterNullish(),
            filter(x => x.length > 0),
        );

        this.activeSubscription$ = this.store.select(selectActiveSubscription).pipe(
            filterNullish(),
        );

        this.product$ = this.activeSubscription$.pipe(
            switchMap(subscription => this.store.select(selectProductById(subscription.plan.product))),
            filterNullish(),
        );

        // wenn man eine Subscription beendet bei Stripe ist es direkt cancelled auch wenn man noch bis zum
        // Ende (bis zur nächsten Abrechungen) "kündigt"
        // -> bei unseren Daten in der DB schauen ob es cancelled Subscriptions gibt die noch "laufen"
        this.tenantCanceledActiveSubscription$ = this.tenant$.pipe(
            map(tenant => tenant.paymentSubscriptions),
            map(paymentSubscriptions => paymentSubscriptions.filter(payment => !!payment.endedAt)),
            map(paymentSubscriptions => paymentSubscriptions.find(payment => {
                const now = DateTime.now();
                const endedAt = DateTime.fromISO(payment.endedAt.toString(), { zone: 'utc' });
                return payment.status === 'canceled' && now <= endedAt;
            })),
        );
    }

    async upgradeToPro() {
        const { sessionUrl } = await firstValueFrom(this.stripeService.createCheckoutSession(environment.stripeDefaultPrice));
        window.location.href = sessionUrl;
    }

    async openEndSubscriptionModal(id: string, nextBillingDate: number) {
        const modal = this.modal.open(DeleteSubscriptionModalComponent, { centered: true, size: 'auto' });
        modal.contentInstance.nextBillingDate = nextBillingDate;
        modal.contentInstance.id = id;
    }

    async openBillingModal() {
        const { sessionUrl } = await firstValueFrom(this.stripeService.getBillingPortalUrl());
        window.location.href = sessionUrl;
    }

}
