import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Template } from '../../../template/store/template.interface';
import { TemplateActions } from '../../../template/store/template.actions';
import { selectAllTemplates } from '../../../template/store/template.selectors';
import { map } from 'rxjs/operators';
import { filterNullish } from '../../utilities/filter-nullish.operator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-template-list-global',
    template: `
        <div *ngFor="let template of templates$ | async; let i = index">
            <app-template-list-item [hasProjectId]="!!(projectId$ | async)" [template]="template"></app-template-list-item>
        </div>
    `,
    styleUrls: ['./template-list-global.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListGlobalComponent {
    templates$!: Observable<Template[]>;
    projectId$!: Observable<string>;


    constructor(
        private store: Store,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(TemplateActions.loadTemplates());
        this.templates$ = this.store.pipe(select(selectAllTemplates));

        this.projectId$ = this.route.paramMap.pipe(
            map(params => params.get('projectId')),
            filterNullish(),
            untilDestroyed(this),
        );
    }
}
