import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserLoading } from '../../+store/user.selectors';
import { AppState } from '../../../reducers';
import { ModalService } from '../../../shared/modal/modal.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-user-profile',
  template: `
      <div class="h-screen flex flex-col">
          <app-nav></app-nav>
          <main class="grow p-7 overflow-auto">
              <div class="rounded-xl bg-slate-900 grow overflow-hidden shadow-lg mt-8 p-6">
                  <div *ngIf="(loading$ | async)" class="text-red-600 font-bold py-3 pb-5">
                      <fa-icon [icon]="faSpinner" size="sm" [fixedWidth]="true" animation="spin" class="mr-1"></fa-icon>
                      Please wait, account is being deleted.
                  </div>
                  <button class="btn-danger" (click)="openDeleteModal()" [disabled]="(loading$ |async)">
                      Delete Account
                  </button>
              </div>
          </main>
      </div>
  `,
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit{
    loading$!: Observable<boolean>;

    constructor(
        private modal: ModalService,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
        this.loading$ = this.store.select(selectUserLoading)
    }

    async openDeleteModal() {
        this.modal.open(DeleteModalComponent, {centered: true, size: 'auto'});
    }

    protected readonly faSpinner = faSpinner;
}
