import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrash, faFileLines } from '@fortawesome/pro-duotone-svg-icons';


@Component({
  selector: 'app-dropped-item',
  template: `
      <div class="flex items-center group py-2 pr-2">
          <div class="flex justify-center items-center bg-blue-200 w-[30px] h-[30px] rounded text-blue-800 shrink-0">
              <fa-icon [icon]="faFile" [fixedWidth]="true" size="1x"></fa-icon>
          </div>
          <div class="flex flex-col overflow-auto ml-3 grow">
              <h3 class="font-medium text-xs text-slate-300 line-clamp-2">
                  {{ file.name}}
              </h3>
          </div>
          <div class="flex text-blue-300">
              <fa-icon [icon]="faTrash" [fixedWidth]="true" size="1x" tp="Remove file"
                       class="pointer" (click)="remove()"></fa-icon>
          </div>
      </div>
  `,
  styleUrls: ['./dropped-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DroppedItemComponent {
    @Input() file!: File;
    @Output() removeEvent: EventEmitter<File> = new EventEmitter();

    faTrash = faTrash;
    faFile = faFileLines;

    remove() {
        this.removeEvent.emit(this.file);
    }
}
