import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Workflow } from './workflow.model';

export const WorkflowActions = createActionGroup({
    source: 'Workflow/API',
    events: {
        'Load Workflows': emptyProps(),
        'Load Workflows Success': props<{workflows: Workflow[]}>(),
        'Load Workflows Failure': props<{error: any}>(),

        'Load Workflow By Id': props<{id: string}>(),
        'Load Workflow By Id Success': props<{workflow: Workflow}>(),
        'Load Workflow By Id Failure': props<{error: any}>(),
    },
});
