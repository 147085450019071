import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkflowOutput } from '../../+store/workflow-outputs/workflow-output.model';
import { selectWorkflowOutputLoading } from '../../+store/workflow-outputs/workflow-output.selectors';

@Component({
    selector: 'app-workflow-output-list',
    template: `
        <div class="px-6 py-4 border-b" *ngFor="let output of outputs">

            <ng-container [ngSwitch]="output.renderType">
                <ng-container *ngSwitchCase="'spellcheck'">
                    <app-render-spellcheck [output]="output"></app-render-spellcheck>
                </ng-container>
                <ng-container *ngSwitchDefault>

                    <div class="text-sm -mx-2">
                        <app-button size="sm" color="transparent" [square]="true" tp="Copy"
                                    [cdkCopyToClipboard]="output.text">
                            <fa-icon class="text-slate-300 group/button-hover:text-slate-700"
                                     [icon]="['fas', 'clipboard']"></fa-icon>
                        </app-button>
                    </div>

                    <div class="whitespace-pre-wrap" [innerHTML]="output.html"></div>
                </ng-container>
            </ng-container>

        </div>
    `,
    styleUrls: ['./workflow-output-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowOutputListComponent {
    @Input() outputs?: WorkflowOutput[] | null;

    store = inject(Store);
    outputLoading = this.store.selectSignal(selectWorkflowOutputLoading);
}
