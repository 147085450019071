import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { MemberEffects } from './+store/member.effects';
import { InviteMemberModalComponent } from './components/invite-member-modal/invite-member-modal.component';


@NgModule({
    declarations: [
        InviteMemberModalComponent,
    ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([MemberEffects]),
        SharedModule,
        ReactiveFormsModule,
    ],
})
export class MemberModule {
}
