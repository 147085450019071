import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StripeInvoice } from '../../interfaces/stripe-invoice.interface';

@Component({
  selector: 'app-stripe-invoice-item',
  template: `
      <div class="bg-slate-50 rounded shadow flex md:items-center flex-col md:flex-row w-full p-4 my-2">
       <div class="pr-6">
           <div class="text-sm text-gray-500 mt-1">Invoice number: {{invoice.number}}</div>
           <div class="text-sm text-gray-500 mt-1">Invoice date: {{invoice.created * 1000 | date: 'dd.MM.yyyy'}}</div>
<!--           <div class="text-sm text-gray-400 mt-1">Paid: {{invoice.paid}}</div>-->
       </div>

       <div class="md:ml-auto mt-3 md:mt-0">
           <app-button tp="Download invoice" (click)="openPdf()">
               <div class="whitespace-nowrap">
                   <fa-icon icon="file-download"></fa-icon>
               </div>
           </app-button>
       </div>
   </div>
  `,
  styleUrls: ['./stripe-invoice-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripeInvoiceItemComponent {
    @Input() invoice!: StripeInvoice;

    openPdf() {
        window.open(this.invoice.hosted_invoice_url as string)
    }
}
