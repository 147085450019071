import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faFileMagnifyingGlass, faFloppyDisk, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Chat } from '../../../../chat/+store/chat.model';
import { ModalService } from '../../../modal/modal.service';
import { MessageChunksModalComponent } from '../../message-chunks-modal/message-chunks-modal.component';
import {
    TemplateCreateUpdateModalComponent,
} from '../../template-create-update-modal/template-create-update-modal.component';

@Component({
    selector: 'app-chat-message2',
    template: `
        <div class="flex py-7" [ngClass]="{'bg-slate-800/75': message.type === 'ai'}">
            <div class="grow px-9">
                <div class="text-slate-400 flex justify-between">
                    <div class="text-xs">
                        {{message.createdAt | timeFromNow | async}}
<!--                        <fa-icon *ngIf="message.type === 'ai'" [icon]="faInfo" class="ml-1"-->
<!--                                 tp="Token usage for this request: {{message.tokenUsage.totalTokens}} tokens. Used chatModel: {{message.modelName}}. Used temperature: {{+message.temperature}}"></fa-icon>-->
                        <fa-icon *ngIf="message.type === 'human' && !!message.embeddingSearchObjects[0]?.embeddingId"
                                 [icon]="faFile" class="ml-1 cursor-pointer"
                                 (click)="openModal()"
                                 tp="Overview which chunks have been used to answer your request"></fa-icon>
                        <fa-icon *ngIf="message.type === 'human'"
                                 [icon]="faFloppyDisk" class="ml-2 cursor-pointer"
                                 (click)="openSaveModal()"
                                 tp="Save this message as a template prompt"></fa-icon>
                    </div>
                    <div *ngIf="message.type === 'ai'">
                        <app-rating [chatId]="chatId" [aiOutput]="message.text" [messageId]="message.id"></app-rating>
                    </div>


                </div>
                <div class="text-slate-300 mt-3" [innerHTML]="message.text"></div>
            </div>
        </div>
    `,
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatMessageComponent {
    @Input() message!: Chat['messages'][0];
    @Input() chatId!: string | any;

    faInfo = faInfoCircle;
    faFile = faFileMagnifyingGlass;
    faFloppyDisk = faFloppyDisk;

    constructor(
        private modal: ModalService,
    ) {
    }

    openModal() {
        const modal = this.modal.open(MessageChunksModalComponent, {centered: true, size: 'full'});
        modal.contentInstance.embeddingSearchObjects = this.message.embeddingSearchObjects;
    }

    openSaveModal() {
        const modal = this.modal.open(TemplateCreateUpdateModalComponent, {centered: true, size: 'full'});
        modal.contentInstance.prompt = this.message.text;
    }
}
