import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { ModalService } from '../modal/modal.service';
import { RatingFeedbackModalComponent } from '../components/rating-feedback-modal/rating-feedback-modal.component';
import { Feedback } from '../interfaces/feedback.interface';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(
        private http: HttpClient,
        private toast: HotToastService,
        private modalService: ModalService,
    ) {
    }

    rate(rating: number, chatId: string, aiOutput: string, messageId: string) {
        this.http.post<Feedback>(environment.apiUrl + '/feedback', {
            rating,
            aiOutput,
            chatId,
            messageId,
        }).pipe(
            untilDestroyed(this),
            catchError(err => {
                this.toast.error('Feedback not saved:' + err.err.message)
                throw err;
            })
        ).subscribe((feedback: Feedback) => {
            this.toast.success('Feedback saved!', {duration: 5000});

            if (rating < 3) {
                const modal = this.modalService.open(RatingFeedbackModalComponent, {centered: true});
                modal.contentInstance.feedbackId = feedback.id;
            }
        })
    }
}
