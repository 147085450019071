import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ChatActions } from '../../+store/chat.actions';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { WorkflowActions } from '../../../workflow/+store/workflow/workflow.actions';
import { selectWorkflowAll } from '../../../workflow/+store/workflow/workflow.selectors';
import { WorkflowTilesComponent } from '../../../workflow/components/workflow-tiles/workflow-tiles.component';
import { ChatInputFieldComponent } from '../../components/chat-input-field/chat-input-field.component';
import { ChatModelSettingsComponent } from '../../components/chat-model-settings/chat-model-settings.component';
import { ChatModelSwitcherComponent } from '../../components/chat-model-switcher/chat-model-switcher.component';
import { ChatTopBarComponent } from '../../components/chat-top-bar/chat-top-bar.component';

@Component({
    selector: 'app-chat-index',
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="p-5 w-full grow flex flex-col items-center bg-slate-50">
                <app-chat-top-bar class="max-w-[992px]"></app-chat-top-bar>

                <div class="w-full max-w-[992px]">
                    <div class="flex items-center justify-center mt-12 mb-6">
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                        <h2 class="font-medium text-slate-400 mx-5 text-center text-sm uppercase">Start a Chat</h2>
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                    </div>

                    <app-chat-input-field (onSubmit)="createChat($event)"></app-chat-input-field>

                    <div class="flex items-center justify-center mt-12 mb-5">
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                        <h2 class="font-medium text-slate-400 mx-5 text-center text-sm uppercase">
                            Or choose a Workflow
                        </h2>
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                    </div>

                    <app-workflow-tiles [workflows]="workflows()"></app-workflow-tiles>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MainWrapperComponent,
        ReactiveFormsModule,
        ChatModelSwitcherComponent,
        ChatModelSettingsComponent,
        ChatInputFieldComponent,
        WorkflowTilesComponent,
        ChatTopBarComponent,
    ],
})
export class ChatIndexComponent implements OnInit {
    form!: FormGroup;
    store = inject(Store);
    fb = inject(FormBuilder);

    workflows = this.store.selectSignal(selectWorkflowAll);

    ngOnInit() {
        this.store.dispatch(WorkflowActions.loadWorkflows());

        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            private: [false],
            settings: [{ temperature: 0.8 }],
        });
    }

    createChat(message: { text: string }) {
        const formValue = this.form.getRawValue();
        this.store.dispatch(
            ChatActions.createChat({
                text: message.text,
                model: formValue.model,
                settings: formValue.settings,
                private: formValue.private,
            }),
        );
    }
}
