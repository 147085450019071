import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Tenant } from './tenant.interface';

@Injectable({
    providedIn: 'root',
})
export class TenantService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getUserTenants(): Observable<Tenant[]> {
        return this.http.get<Tenant[]>(environment.apiUrl + '/tenants/user')
    }

}
