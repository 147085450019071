import { DOCUMENT } from '@angular/common';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Injectable,
    Injector,
    Renderer2,
    RendererFactory2,
} from '@angular/core';
import { ModalBackdropComponent } from './modal-backdrop/modal-backdrop.component';
import { ModalContentContainerComponent } from './modal-content-container/modal-content-container.component';

export interface ModalOptions {
    size: 'full' | 'auto';
    centered: boolean;
    backdrop: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    open<T>(content: new (...args: any) => T, { centered = true, backdrop = true }: Partial<ModalOptions> = {}) {
        let backdropComponent: ComponentRef<any>;
        if (backdrop) {
            const backdropComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalBackdropComponent);
            backdropComponent = backdropComponentFactory.create(this.injector);
            this.appRef.attachView(backdropComponent.hostView);
            this.renderer.appendChild(this.document.body, backdropComponent.location.nativeElement);
        }

        const contentContainerComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContentContainerComponent);
        const contentContainerComponent = contentContainerComponentFactory.create(this.injector);
        contentContainerComponent.instance.centered = centered;
        this.appRef.attachView(contentContainerComponent.hostView);

        const contentRef = contentContainerComponent.instance.contentRef.createComponent<T>(content as any);

        this.renderer.appendChild(this.document.body, contentContainerComponent.location.nativeElement);

        const closeFn = () => {
            contentContainerComponent.hostView.destroy();
            backdropComponent?.hostView.destroy();
        };

        contentContainerComponent.instance.close.subscribe(closeFn);
        contentContainerComponent.instance.dismiss.subscribe(closeFn);

        return {
            close: closeFn,
            onClose: contentContainerComponent.instance.close.asObservable(),
            onDismiss: contentContainerComponent.instance.dismiss.asObservable(),
            contentInstance: contentRef.instance,
        };
    }
}
