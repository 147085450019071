import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-button',
    template: `
        <button [type]="type" class="btn group/button" [class]="class" [class.w-full]="fullWidth" *ngIf="!link"
                [class.btn-outline]="outline"
                [class.square]="square" [class.unequal-border]="unequalBorder" [disabled]="disabled"
                [class.whitespace-nowrap]="!wrap" [id]="id">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>

        <a class="btn group" [class]="class" [class.w-full]="fullWidth" *ngIf="link" [routerLink]="link"
           [fragment]="fragment"
           [class.unequal-border]="unequalBorder" [id]="id" [class.btn-outline]="outline">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </a>

        <ng-template #contentTpl>
            <ng-content></ng-content>
            <fa-icon [icon]="faSpinner" animation="spin" class="ml-2" *ngIf="loading"></fa-icon>
        </ng-template>
    `,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    faSpinner = faSpinner;

    @Input() color: 'primary' | 'white' | 'blue' | 'green' | 'red' | 'beige' | 'transparent' = 'primary';
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
    @Input() fullWidth: boolean = false;
    @Input() rounded: 'rounded-md' | 'rounded-full' = 'rounded-md';
    @Input() square = false;
    @Input() unequalBorder = false;
    @Input() link?: any[];
    @Input() fragment?: string;
    @Input() type: string = 'button';
    @Input() disabled: boolean | null = false;
    @Input() wrap = true;
    @Input() loading: boolean | null = false;
    @Input() id: string | null = null;
    @Input() outline = false;

    @HostBinding('class.w-full') get fullWidthBinding() {
        return this.fullWidth;
    }

    get class() {
        return `btn-${this.color} ${this.rounded} btn-${this.size}`;
    }
}
