import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../shared/modal/modal.service';
import {
    selectUploadCollectionAll,
    selectUploadCollectionLoading,
} from '../../../upload/+store/folder/upload-collection.selectors';
import { CreateCollectionModalComponent } from '../../modals/create-collection-modal/create-collection-modal.component';

@Component({
    selector: 'app-upload-collection-list',
    template: `
        <ng-container *ngFor="let uploadCollection of uploadCollections$ | async">
            <a class="collection-item" [routerLink]="['/documents', uploadCollection.id]" routerLinkActive="active">
                {{ uploadCollection.name }}
            </a>
        </ng-container>
        <div class="text-center text-slate-600 font-semibold text-lg tracking-wide px-5 mt-5"
             *ngIf="(uploadCollections$ | async)?.length === 0 && !(uploadCollectionLoading$ | async)">
            Add your first collection now and start uploading your documents.
            <div class="mt-6">
                <app-button (click)="openModal()">Create Collection</app-button>
            </div>
        </div>
    `,
    styleUrl: './upload-collection-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadCollectionListComponent {
    store = inject(Store);
    modalService = inject(ModalService);

    uploadCollections$ = this.store.select(selectUploadCollectionAll);
    uploadCollectionLoading$ = this.store.select(selectUploadCollectionLoading);

    openModal() {
        this.modalService.open(CreateCollectionModalComponent);
    }
}
