import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { ForgotPasswordComponent } from './auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { PasswordResetComponent } from './auth/pages/password-reset/password-reset.component';
import { SignupSuccessComponent } from './auth/pages/signup-success/signup-success.component';
import { SignupComponent } from './auth/pages/signup/signup.component';
import { ChatDetailComponent } from './chat/pages/chat-detail/chat-detail.component';
import { ChatIndexComponent } from './chat/pages/chat-index/chat-index.component';
import { DocumentDetailComponent } from './document/pages/document-detail/document-detail.component';
import { DocumentIndexComponent } from './document/pages/document-index/document-index.component';
import { SettingIndexComponent } from './setting/setting-index/setting-index.component';
import { SettingMembersComponent } from './setting/setting-members/setting-members.component';
import { SettingPaymentComponent } from './setting/setting-payment/setting-payment.component';
import { SettingProfileComponent } from './setting/setting-profile/setting-profile.component';
import { UserProfileComponent } from './user/components/user-profile/user-profile.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: ChatIndexComponent,
                pathMatch: 'full',
            },
            {
                path: 'chat',
                children: [
                    {
                        path: '',
                        component: ChatIndexComponent,
                    },
                    {
                        path: ':id',
                        component: ChatDetailComponent,
                    },
                ],
            },
            {
                path: 'settings',
                component: SettingIndexComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/settings/profile',
                    },
                    {
                        path: 'payment',
                        component: SettingPaymentComponent,
                    },
                    {
                        path: 'profile',
                        component: SettingProfileComponent,
                    },
                    {
                        path: 'members',
                        component: SettingMembersComponent,
                    },
                ],
            },
            {
                path: 'documents',
                component: DocumentIndexComponent,
                children: [
                    {
                        path: ':id',
                        component: DocumentDetailComponent,
                    },
                ],
            },
            {
                path: 'profile',
                component: UserProfileComponent,
            },
            {
                path: 'workflow',
                loadChildren: () => import('./workflow/workflow-routing.module').then(m => m.WorkflowRoutingModule),
            },
            {
                path: 'brand-voice',
                loadChildren: () => import('./brand-voice/brand-voice-routing.module').then(m => m.BrandVoiceRoutingModule),
            },
        ],
    },
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'accept-invitation/:token',
                component: PasswordResetComponent,
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'signup',
        component: SignupComponent,
    },
    {
        path: 'signup-success',
        component: SignupSuccessComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'complete-signup/:token',
        component: PasswordResetComponent,
    },
    {
        path: 'reset-password/:token',
        component: PasswordResetComponent,
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
