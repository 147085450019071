import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { faBookOpen, faFolder } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Chat } from 'src/app/chat/+store/chat.model';
import { AuthActions } from '../../../auth/+store/auth.actions';
import { selectAuthEmail } from '../../../auth/+store/auth.selectors';
import { ChatActions } from '../../../chat/+store/chat.actions';
import { selectRecentChats } from '../../../chat/+store/chat.selectors';
import { AppState } from '../../../reducers';

@Component({
    selector: 'app-nav',
    template: `
        <aside
            class="relative bg-gradient-to-b from-slate-50 to-slate-200/80  border-slate-200 shadow-xl shadow-gray-300/90 w-[300px] h-full flex flex-col shrink-0 p-5 overflow-auto"
            scrollbar>
            <div class="flex justify-center">
                <a [routerLink]="['/']">
                    <img src="assets/logo/logo.svg" width="150" alt="">
                </a>
            </div>
            <app-button color="primary" [fullWidth]="true" [outline]="true" rounded="rounded-full" class="mt-8"
                        [link]="['/']">
                <div class="font-bold tracking-wider text-sm">New Chat</div>
            </app-button>
            <!--            <a router-->
            <!--                    class="mt-8 border border-slate-600 hover:bg-slate-600 hover:text-white text-slate-600 shadow-md rounded-md p-2">-->
            <!--                New Chat-->
            <!--            </a>-->

            <app-navigation-category-header>General</app-navigation-category-header>

            <div class="text-sm">
                <a class="px-3 py-2 rounded-md hover:bg-slate-100 block cursor-not-allowed"
                   [class.opacity-50]="!fakeMode"
                   tp="available soon">
                    <fa-icon class="mr-1" [icon]="faBookOpen" [fixedWidth]="true" size="sm"></fa-icon>
                    Prompt Library
                </a>
                <a [routerLink]="['/documents']" routerLinkActive="bg-blue-100"
                   class="px-3 py-2 rounded-md hover:bg-blue-100 cursor-pointer block mt-1">
                    <fa-icon class="mr-1" [icon]="faFolder" [fixedWidth]="true" size="sm"></fa-icon>
                    Documents
                </a>
                <a [routerLink]="['/brand-voice']" routerLinkActive="bg-blue-100"
                   class="px-3 py-2 rounded-md hover:bg-blue-100 cursor-pointer block mt-1">
                    <fa-icon class="mr-1" [icon]="['fal', 'heart']" [fixedWidth]="true" size="sm"></fa-icon>
                    Brand Voice
                </a>
            </div>

            <app-navigation-category-header>History</app-navigation-category-header>

            <div class="text-sm" *ngIf="fakeMode">
                <a
                    class="py-1 px-3 block rounded-md hover:bg-slate-100 cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden"
                    *ngFor="let title of fakeChats"
                >
                    {{ title }}
                </a>
            </div>

            <div class="text-sm" *ngIf="!fakeMode">
                <a
                    [routerLink]="['/chat', chat.id]"
                    class="py-1 px-3 block rounded-md hover:bg-slate-100 cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden"
                    *ngFor="let chat of (recentChats$ | async)"
                    [tp]="chat.name"
                    [tpDelay]="500"
                >
                    {{ chat.name }}
                </a>
            </div>

            <div class="text-sm text-slate-400 text-center px-10 mt-3" *ngIf="(recentChats$ | async)?.length === 0">
                You don't have any chats yet. Try to start one.
            </div>

            <div class="mt-auto text-sm">
                <app-navigation-category-header>Settings</app-navigation-category-header>
                <a [routerLink]="['/settings']" class="px-3 py-2 block rounded-md hover:bg-slate-100 cursor-pointer">
                    <fa-icon class="mr-1" [icon]="faCog" [fixedWidth]="true" size="sm"></fa-icon>
                    Settings
                </a>
                <a [routerLink]="['/settings', 'members']" class="px-3 py-2 rounded-md hover:bg-slate-100 block">
                    <fa-icon class="mr-1" [icon]="faUsers" [fixedWidth]="true" size="sm"></fa-icon>
                    Members
                </a>
                <button type="button" (click)="logout()"
                        class="px-3 py-2 rounded-md hover:bg-slate-100 text-left w-full">
                    <fa-icon class="mr-1" [icon]="['fad', 'right-from-bracket']" [fixedWidth]="true"
                             size="sm"></fa-icon>
                    Logout
                </button>
            </div>
        </aside>

    `,
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
    faBookOpen = faBookOpen;
    faCog = faCog;
    faFolder = faFolder;
    faUsers = faUsers;

    email$: Observable<string | null> = of(null);
    recentChats$!: Observable<Chat[]>;
    fakeChats = [
        'Maximizing Social Media Engagement Strategies',
        'Elevating Sales Outcomes through Data',
        'Boosting Email Marketing ROI',
        'Cutting-edge Customer Segmentation Techniques',
        'Revolutionizing Product Launch Strategies',
        'In-depth Market Research and Insights',
        'Effective Lead Generation and Conversion',
        'Improving Brand Visibility through Data Analysis',
        'Developing Content Marketing Strategies',
        'Leveraging Data for Competitive Analysis',
    ];
    fakeMode = false;

    constructor(
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef,
    ) {
    }

    @HostListener('window:keydown.shift.control.meta.j') activateFakeMode() {
        this.fakeMode = !this.fakeMode;
        this.cdr.markForCheck();
    }

    ngOnInit() {
        this.email$ = this.store.select(selectAuthEmail);
        this.store.dispatch(ChatActions.loadAllChats());
        this.recentChats$ = this.store.select(selectRecentChats());
    }

    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
