import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-modal-body',
  template: `
      <div class="modal-body p-4">
          <ng-content></ng-content>
      </div>
  `,
  styleUrls: ['./app-modal-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalBodyComponent {
}
