import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatActions } from '../../+store/chat.actions';
import { Chat } from '../../+store/chat.model';
import { selectChatById } from '../../+store/chat.selectors';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { ChatService } from '../../chat.service';

@UntilDestroy()
@Component({
    selector: 'app-chat-detail',
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="flex flex-col items-center h-full w-full">
                <div class="flex items-center mt-5 mb-3">
                    <app-chat-model-switcher formControlName="model"></app-chat-model-switcher>
                    <app-chat-model-settings formControlName="settings" class="ml-2"></app-chat-model-settings>
                </div>

                <div class="w-full grow overflow-auto" #scrollArea>
                    <ng-container *ngFor="let message of (chat$ | async)?.messages">
                        <ng-container [ngSwitch]="message.type">
                            <app-chat-message-document-search
                                [message]="message"
                                [chat]="chat$ | async"
                                *ngSwitchCase="'documentSearch'">
                            </app-chat-message-document-search>

                            <app-chat-message-document-search-result *ngSwitchCase="'documentSearchResult'">
                            </app-chat-message-document-search-result>

                            <app-chat-message-spellcheck [message]="message"
                                                         *ngSwitchCase="'spellcheck'"></app-chat-message-spellcheck>

                            <app-chat-message [message]="message" *ngSwitchDefault></app-chat-message>
                        </ng-container>
                    </ng-container>
                </div>


                <div class="mt-auto mb-5 pt-5 w-full max-w-[1200px] px-5">
                    <app-chat-input-field (onSubmit)="submit($event)"></app-chat-input-field>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDetailComponent implements OnInit, OnDestroy {
    @ViewChild('scrollArea', { static: true }) scrollArea!: ElementRef<HTMLDivElement>;

    chat$!: Observable<Chat>;
    chatId?: string;
    form!: FormGroup;

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private chatService: ChatService,
        private fb: FormBuilder,
    ) {
    }

    ngOnDestroy() {
        if (this.chatId) {
            this.chatService.unsubscribe(this.chatId);
        }
    }

    ngOnInit() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-3.5-turbo', Validators.required],
            settings: [{ temperature: 0.8, documentSearch: false }],
        });

        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(values => {
            this.store.dispatch(ChatActions.updateChat({ chat: { id: this.chatId, ...values } }));
        });

        const route$ = this.route.paramMap.pipe(
            untilDestroyed(this),
            map(params => params.get('id')),
            filterNullish(),
        );

        route$.subscribe(id => {
            this.chatId = id;
            this.chatService.subscribe(id);
            this.store.dispatch(ChatActions.loadChatById({ id }));
        });

        this.chat$ = route$.pipe(switchMap(id => this.store.select(selectChatById(id))), filterNullish(), shareReplay(1));
        this.chat$.pipe(untilDestroyed(this)).subscribe(chat => {
            this.scrollArea.nativeElement.scrollTo({
                top: this.scrollArea.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
            this.form.patchValue(chat, { emitEvent: false });
        });
    }

    submit(message: {text: string}) {
        if (this.chatId) {
            this.store.dispatch(ChatActions.sendChatMessage({ text: message.text, chatId: this.chatId }));
        }
    }
}
