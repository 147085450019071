import { ClipboardModule } from '@angular/cdk/clipboard';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faComments,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFiles,
    faFolderOpen as faFolderOpenDuo,
    faFolders,
    faHome,
    faMagnifyingGlass,
    faPaperPlane,
    faPaperPlaneTop,
    faPencil,
    faRectangleHistory,
    faRightFromBracket,
    faSpinnerThird,
    faStars,
} from '@fortawesome/pro-duotone-svg-icons';
import { faChevronRight, faHeart, faArrowProgress } from '@fortawesome/pro-light-svg-icons';
import {
    faEllipsisH,
    faFolder,
    faFolderOpen,
    faGear,
    faPaperclipVertical,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faArrowsRotate,
    faBullhorn,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCircleCheck,
    faClipboard,
    faCommentArrowUpRight,
    faFileDownload,
    faGears,
    faPlus,
    faSquareDashedCirclePlus,
    faTimes,
    faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { NgDatePipesModule, NgMathPipesModule } from 'ngx-pipes';
import { AppModalBodyComponent } from './components/app-modal/app-modal-body/app-modal-body.component';
import { AppModalFooterComponent } from './components/app-modal/app-modal-footer/app-modal-footer.component';
import { AppModalTitleComponent } from './components/app-modal/app-modal-title/app-modal-title.component';
import { AppModalComponent } from './components/app-modal/app-modal.component';
import { ButtonComponent } from './components/button/button.component';
import { ChatListItemComponent } from './components/chat-list-item/chat-list-item.component';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatLoadingComponent } from './components/chat-loading/chat-loading.component';
import { ChatSettingsComponent } from './components/chat-settings/chat-settings.component';
import { ChatMessageComponent } from './components/chat/chat-message/chat-message.component';
import { ChatComponent } from './components/chat/chat.component';
import {
    ConfirmedDeleteDialogComponent,
} from './components/confirmed-click/confirmed-delete-dialog/confirmed-delete-dialog.component';
import { DocumentItemComponent } from './components/document-item/document-item.component';
import { DragDropUploadComponent } from './components/drag-drop-upload/drag-drop-upload.component';
import { DroppedItemComponent } from './components/dropped-item/dropped-item.component';
import { FileListItemComponent } from './components/file-list-item/file-list-item.component';
import { LoadingOwlAnimationComponent } from './components/loading-owl-animation/loading-owl-animation.component';
import { MainWrapperComponent } from './components/main-wrapper/main-wrapper.component';
import { MessageChunkComponent } from './components/message-chunk/message-chunk.component';
import { MessageChunksModalComponent } from './components/message-chunks-modal/message-chunks-modal.component';
import {
    NavigationCategoryHeaderComponent,
} from './components/navigation-category-header/navigation-category-header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProjectListItemComponent } from './components/project-list-item/project-list-item.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { RatingFeedbackModalComponent } from './components/rating-feedback-modal/rating-feedback-modal.component';
import { RatingModalComponent } from './components/rating-modal/rating-modal.component';
import { RatingComponent } from './components/rating/rating.component';
import { RenderSpellcheckComponent } from './components/render-spellcheck/render-spellcheck.component';
import { RoundCheckboxComponent } from './components/round-checkbox/round-checkbox.component';
import {
    TemplateChatSuggestionComponent,
} from './components/template-chat-suggestion/template-chat-suggestion.component';
import {
    TemplateCreateUpdateModalComponent,
} from './components/template-create-update-modal/template-create-update-modal.component';
import { TemplateDeleteModalComponent } from './components/template-delete-modal/template-delete-modal.component';
import { TemplateListGlobalComponent } from './components/template-list-global/template-list-global.component';
import { TemplateListItemComponent } from './components/template-list-item/template-list-item.component';
import {
    TemplateSuggestionItemComponent,
} from './components/template-suggestion-item/template-suggestion-item.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadListComponent } from './components/upload-list/upload-list.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import {
    FieldErrorMessagesComponent,
} from './custom-inputs/components/field-error-messages/field-error-messages.component';
import { GenericFormComponent } from './custom-inputs/components/generic-form/generic-form.component';
import {
    GenericNumberFieldComponent,
} from './custom-inputs/components/generic-numberfield.component.ts/generic-number-field.component';
import {
    GenericTextareaFieldComponent,
} from './custom-inputs/components/generic-textarea/generic-textarea-field.component';
import {
    GenericTextfieldWithSelectorComponent,
} from './custom-inputs/components/generic-textfield-with-selector/generic-textfield-with-selector.component';
import { GenericTextFieldComponent } from './custom-inputs/components/generic-textfield/generic-text-field.component';
import { FormGroupFinderDirective } from './custom-inputs/directives/form-group-finder/form-group-finder.directive';
import { ConfirmedClickDirective } from './directives/confirmed-click/confirmed-click.directive';
import { PerfectScrollbarDirective } from './directives/perfect-scrollbar/perfect-scrollbar.directive';
import { ModalModule } from './modal/modal.module';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { PopoverModule } from './popover/popover.module';

@NgModule({
    declarations: [
        AppModalComponent,
        AppModalTitleComponent,
        AppModalBodyComponent,
        AppModalFooterComponent,
        ChatComponent,
        DragDropUploadComponent,
        FileListItemComponent,
        ProjectListComponent,
        ProjectListItemComponent,
        PerfectScrollbarDirective,
        ChatMessageComponent,
        ChatListComponent,
        ChatListItemComponent,
        UploadListComponent,
        ConfirmedClickDirective,
        ConfirmedDeleteDialogComponent,
        NavigationComponent,
        TimeFromNowPipe,
        ChatLoadingComponent,
        MessageChunksModalComponent,
        MessageChunkComponent,
        RatingModalComponent,
        RatingComponent,
        RatingFeedbackModalComponent,
        TemplateCreateUpdateModalComponent,
        TemplateListGlobalComponent,
        TemplateListItemComponent,
        TemplateDeleteModalComponent,
        TemplateChatSuggestionComponent,
        TemplateSuggestionItemComponent,
        DocumentItemComponent,
        DroppedItemComponent,
        NavigationCategoryHeaderComponent,
        ButtonComponent,
        MainWrapperComponent,
        ChatSettingsComponent,
        UserAvatarComponent,
        LoadingOwlAnimationComponent,
        ToggleSwitchComponent,
        RenderSpellcheckComponent,
        RoundCheckboxComponent,

        // custom-inputs
        FormGroupFinderDirective,
        FieldErrorMessagesComponent,
        GenericFormComponent,
        GenericTextFieldComponent,
        GenericTextareaFieldComponent,
        GenericNumberFieldComponent,
        GenericTextfieldWithSelectorComponent,
    ],
    exports: [
        FontAwesomeModule,
        ChatComponent,
        DragDropUploadComponent,
        FileListItemComponent,
        ProjectListComponent,
        ProjectListItemComponent,
        PerfectScrollbarDirective,
        ChatListComponent,
        UploadListComponent,
        ConfirmedClickDirective,
        ConfirmedDeleteDialogComponent,
        NavigationComponent,
        AppModalComponent,
        AppModalTitleComponent,
        AppModalBodyComponent,
        AppModalFooterComponent,
        ChatLoadingComponent,
        TemplateListGlobalComponent,
        NavigationCategoryHeaderComponent,
        ButtonComponent,
        MainWrapperComponent,
        ChatMessageComponent,
        UserAvatarComponent,
        // ChatMessageComponent,
        ChatSettingsComponent,
        LoadingOwlAnimationComponent,
        ToggleSwitchComponent,
        RenderSpellcheckComponent,
        RoundCheckboxComponent,

        // custom-inputs
        FormGroupFinderDirective,
        // FieldErrorMessagesComponent,
        GenericFormComponent,
        GenericTextareaFieldComponent,
        GenericTextFieldComponent,
        // GenericTextFieldComponent,
        // GenericTextareaFieldComponent,
        // GenericNumberFieldComponent,
        // GenericTextfieldWithSelectorComponent
    ],
    imports: [
        CommonModule,
        ModalModule,
        NgOptimizedImage,
        FontAwesomeModule,
        TippyDirective,
        ReactiveFormsModule,
        RouterModule,
        NgMathPipesModule,
        PopoverModule,
        NgDatePipesModule,
        TextFieldModule,
        ClipboardModule
    ],
})
export class SharedModule {
    constructor(
        private iconLibrary: FaIconLibrary,
    ) {
        iconLibrary.addIcons(
            faPlus,
            faPaperPlaneTop,
            faPaperPlane,
            faFilePdf,
            faEllipsisH,
            faRectangleHistory,
            faComments,
            faPencil,
            faGear,
            faGears,
            faFiles,
            faTimes,
            faTrash,
            faPaperclipVertical,
            faMagnifyingGlass,
            faFolders,
            faCircleCheck,
            faRightFromBracket,
            faFileDownload,
            faCommentArrowUpRight,
            faLinkedin,
            faClipboard,
            faCaretRight,
            faCaretDown,
            faFolder,
            faFolderOpen,
            faChevronRight,
            faHome,
            faSpinnerThird,
            faEye,
            faEyeSlash,
            faFolderOpenDuo,
            faCheck,
            faCaretUp,
            faStars,
            faBullhorn,
            faSquareDashedCirclePlus,
            faArrowsRotate,
            faHeart,
            faArrowProgress
        );
    }

}
