import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbeddingSearchObject } from '../../../chat/+store/chat.model';
import { EmbeddingActions } from '../../../embedding/+store/embedding.actions';
import { AppState } from '../../../reducers';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@Component({
  selector: 'app-message-chunks-modal',
  template: `
          <app-modal>
              <app-modal-title>
                  <h3>
                      Display chunks
                  </h3>
              </app-modal-title>
              <app-modal-body class="max-h-80">
                    <app-message-chunk *ngFor="let searchObject of embeddingSearchObjects"
                    [embeddingSearch]="searchObject">
                    </app-message-chunk>
              </app-modal-body>
              <app-modal-footer>
                  <button
                      (click)="modalContainer.onDismiss()"
                      type="button" class="btn-light">
                      Close
                  </button>
              </app-modal-footer>
          </app-modal>
  `,
  styleUrls: ['./message-chunks-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageChunksModalComponent implements OnInit {
    @Input() embeddingSearchObjects!: EmbeddingSearchObject[];

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
        const ids = this.embeddingSearchObjects.map(el => el.embeddingId);
        this.store.dispatch(EmbeddingActions.loadEmbeddingsForMessage({ids}))
    }

}
