import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { Upload } from '../../../upload/+store/upload/upload.model';

@Component({
    selector: 'app-upload-list',
    template: `
        <div class="mb-5" *ngFor="let upload of uploads$ | async">
            <app-file-list-item [upload]="upload"></app-file-list-item>
        </div>
    `,
    styleUrls: ['./upload-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadListComponent implements OnInit {
    @Input() set projectId(projectId: string | null) {
        if (projectId) {
            this.$projectId.next(projectId);
        }
    }

    $projectId = new ReplaySubject<string>(1);
    uploads$!: Observable<Upload[]>;

    constructor(
        private store: Store,
    ) {
    }

    ngOnInit() {
        // this.uploads$ = this.$projectId.pipe(
        //     switchMap(projectId => this.store.pipe(select(selectUploadByProjectId(projectId)))),
        // );
    }

}
