import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';
import { selectAllTemplates } from '../../../template/store/template.selectors';
import { Observable } from 'rxjs';
import { Template } from '../../../template/store/template.interface';

@Component({
  selector: 'app-template-chat-suggestion',
  template: `
    <div class="p-3" *ngIf="templates$ |async">
        <div class="text-gray-400 text-sm mb-3">
            Start your chat with one of your saved prompts.
        </div>

        <div *ngFor="let template of (templates$ |async)">
            <app-template-suggestion-item [template]="template"></app-template-suggestion-item>
        </div>
    </div>
  `,
  styleUrls: ['./template-chat-suggestion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateChatSuggestionComponent implements OnInit{
    @Input('projectId') projectId!: string;
    templates$!: Observable<Template[]>;


    constructor(
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(TemplateActions.loadTemplates());
        this.templates$ = this.store.pipe(select(selectAllTemplates));
    }
}
