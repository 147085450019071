import { createSelector } from '@ngrx/store';
import { workflowOutputsFeature } from './workflow-output.reducer';

export const {
    selectIds: selectWorkflowOutputIds,
    selectEntities: selectWorkflowOutputEntities,
    selectAll: selectWorkflowOutputAll,
    selectTotal: selectWorkflowOutputTotal,
} = workflowOutputsFeature;

export const selectWorkflowOutputByWorkflowId = (workflowId: string | undefined) =>
    createSelector(selectWorkflowOutputAll, entities => entities.filter(entity => entity.workflowId === workflowId));

export const selectNewWorkflowOutputByWorkflowId = (workflowId: string | undefined) =>
    createSelector(selectWorkflowOutputByWorkflowId(workflowId), entities => entities.filter(entity => entity.new));

export const selectWorkflowOutputLoading = createSelector(workflowOutputsFeature.selectWorkflowOutputsState, state => state.loading);
