import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Workflow} from './+store/workflow/workflow.model';
import {WorkflowGenerateDto} from './+store/workflow/dto/workflow-generate.dto';
import {WorkflowOutputPreview} from './+store/workflow-outputs/workflow-output.model';
import {WorkflowSetDraftDto} from './+store/workflow/dto/workflow-set-draft.dto';

@Injectable({
    providedIn: 'root'
})
export class WorkflowBuilderService {
    http = inject(HttpClient);

    constructor() {
    }

    generateWorkflow(dto: WorkflowGenerateDto) {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/generate`, dto);
    }

    setWorkflowDraft(id: string, dto: WorkflowSetDraftDto) {
        return this.http.post<Workflow>(`${environment.apiUrl}/workflow/draft/${id}`, dto);
    }

    removeWorkflowDraft(id: string) {
        return this.http.delete<Workflow>(`${environment.apiUrl}/workflow/draft/${id}`);
    }

    publishWorkflowDraft(id: string) {
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/publish/${id}`);
    }

    toggleWorkflowFavorite(id: string) {
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/toggle-favorite/${id}`);
    }

    generatePreviewOutput(payload: {data: any[], workflowId: string}) {
        return this.http.post<WorkflowOutputPreview>(`${environment.apiUrl}/workflow/preview/${payload.workflowId}`, {
            data: payload.data
        });
    }

    loadWorkflowSchema() {
        return this.http.get(`${environment.apiUrl}/workflow/schema`);
    }

    deleteWorkflow(id: string) {
        return this.http.delete<Workflow>(`${environment.apiUrl}/workflow/${id}`);
    }
}
