import { Directive, ElementRef, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
    selector: '[scrollbar]',
})
export class PerfectScrollbarDirective implements OnInit {
    perfectScrollbar!: PerfectScrollbar;

    constructor(
        private elRef: ElementRef<HTMLElement>,
    ) {
    }

    ngOnInit() {
        this.elRef.nativeElement.classList.add('relative');
        this.perfectScrollbar = new PerfectScrollbar(this.elRef.nativeElement);
    }

    update() {
        this.perfectScrollbar.update();
    }

}
