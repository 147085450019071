import {ChangeDetectionStrategy, Component, computed, input, signal} from '@angular/core';
import {NgForOf, NgOptimizedImage} from '@angular/common';
import {Workflow} from '../../+store/workflow/workflow.model';
import {RouterLink} from '@angular/router';
import {WorkflowTileComponent} from '../workflow-tile/workflow-tile.component';

@Component({
    selector: 'app-workflow-tiles',
    template: `
        @if(filter()) {
            <div class="flex mb-2 text-slate-500 flex-wrap pb-3">
                <div
                    class="bg-white shadow shadow-neutral-200 rounded-full text-slate-500 font-medium text-sm py-2 px-4 cursor-pointer whitespace-nowrap mr-3 mb-3"
                    *ngFor="let category of categories()" (click)="toggleCategory(category)"
                    [class.active]="selectedCategories().includes(category)">
                    {{ category }}
                </div>
            </div>
        }

        <div class="w-full grid grid-cols-3 auto-rows-fr justify-center gap-5" [class.mt-2]="filter()">
            @for (workflow of filteredWorkflows(); track workflow.id) {
                <app-workflow-tile [workflow]="workflow" [editable]="editable()"></app-workflow-tile>
            }
        </div>

    `,
    styleUrl: './workflow-tiles.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgForOf,
        NgOptimizedImage,
        RouterLink,
        WorkflowTileComponent
    ],
    standalone: true
})
export class WorkflowTilesComponent {
    workflows = input.required<Workflow[]>();
    editable = input<boolean>(false);
    filter = input<boolean>(true);

    selectedCategories = signal<string[]>([]);

    categories = computed(() => {
        const workflows = this.workflows();
        const categories = new Set<string>();

        workflows.forEach(workflow => workflow.categories.forEach(category => categories.add(category)));
        return categories;
    });

    filteredWorkflows = computed(() => {
        const workflows = this.workflows();
        const selectedCategories = this.selectedCategories();

        if (selectedCategories.length > 0) {
            return workflows.filter(workflow => workflow.categories.some(category => selectedCategories.includes(category)));
        }

        return workflows;
    });

    toggleCategory(category: string) {
        const selectedCategories = new Set([...this.selectedCategories()]);

        if (selectedCategories.has(category)) {
            selectedCategories.delete(category);
        } else {
            selectedCategories.add(category);
        }

        this.selectedCategories.set(Array.from(selectedCategories));
    }
}
