import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatActions } from '../../+store/chat.actions';
import { Chat } from '../../+store/chat.model';
import { selectChatById } from '../../+store/chat.selectors';
import { MainWrapperComponent } from '../../../shared/components/main-wrapper/main-wrapper.component';
import { ToggleSwitchComponent } from '../../../shared/components/toggle-switch/toggle-switch.component';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { ChatService } from '../../chat.service';
import { ChatInputFieldComponent } from '../../components/chat-input-field/chat-input-field.component';
import { ChatMessageDocumentSearchResultComponent } from '../../components/chat-message-document-search-result/chat-message-document-search-result.component';
import { ChatMessageDocumentSearchComponent } from '../../components/chat-message-document-search/chat-message-document-search.component';
import { ChatMessageSpellcheckComponent } from '../../components/chat-message-spellcheck/chat-message-spellcheck.component';
import { ChatMessageComponent } from '../../components/chat-message/chat-message.component';
import { ChatModelSettingsComponent } from '../../components/chat-model-settings/chat-model-settings.component';
import { ChatModelSwitcherComponent } from '../../components/chat-model-switcher/chat-model-switcher.component';
import { ChatTopBarComponent } from '../../components/chat-top-bar/chat-top-bar.component';

@UntilDestroy()
@Component({
    selector: 'app-chat-detail',
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="flex flex-col items-center h-full w-full">
                <app-chat-top-bar class="max-w-[1200px]"></app-chat-top-bar>

                <div class="w-full grow overflow-auto" #scrollArea>
                    @for (message of (chat$ | async)?.messages; track message) {
                        @switch (message.type) {
                            <!--                  @case ('documentSearch') {-->
                            <!--                    <app-chat-message-document-search-->
                            <!--                      [message]="message"-->
                            <!--                      [chat]="chat$ | async"-->
                            <!--                      >-->
                            <!--                    </app-chat-message-document-search>-->
                            <!--                  }-->
                            <!--                  @case ('documentSearchResult') {-->
                            <!--                    <app-chat-message-document-search-result>-->
                            <!--                    </app-chat-message-document-search-result>-->
                            <!--                  }-->
                            @default {
                                <app-chat-message [message]="message"></app-chat-message>
                            }
                        }
                    }
                </div>

                <div class="mt-auto mb-5 pt-5 w-full max-w-[1200px] px-5">
                    <app-chat-input-field (onSubmit)="submit($event)"></app-chat-input-field>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MainWrapperComponent,
        ReactiveFormsModule,
        ChatModelSwitcherComponent,
        ChatModelSettingsComponent,
        ChatMessageDocumentSearchComponent,
        ChatMessageDocumentSearchResultComponent,
        ChatMessageSpellcheckComponent,
        ChatMessageComponent,
        ChatInputFieldComponent,
        AsyncPipe,
        ToggleSwitchComponent,
        ChatTopBarComponent,
    ],
})
export class ChatDetailComponent implements OnInit, OnDestroy {
    private store = inject(Store);
    private route = inject(ActivatedRoute);
    private chatService = inject(ChatService);
    private fb = inject(FormBuilder);

    @ViewChild('scrollArea', { static: true }) scrollArea!: ElementRef<HTMLDivElement>;

    chat$!: Observable<Chat>;
    chatId?: string;
    form!: FormGroup;

    ngOnDestroy() {
        if (this.chatId) {
            this.chatService.unsubscribe(this.chatId);
        }
    }

    ngOnInit() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-4o', Validators.required],
            private: [false],
            settings: [{ temperature: 0.8, documentSearch: false }],
        });

        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(values => {
            this.store.dispatch(ChatActions.updateChat({ chat: { id: this.chatId, ...values } }));
        });

        const route$ = this.route.paramMap.pipe(
            untilDestroyed(this),
            map(params => params.get('id')),
            filterNullish(),
        );

        route$.subscribe(id => {
            this.chatId = id;
            this.chatService.subscribe(id);
            this.store.dispatch(ChatActions.loadChatById({ id }));
        });

        this.chat$ = route$.pipe(
            switchMap(id => this.store.select(selectChatById(id))),
            filterNullish(),
            shareReplay(1),
        );
        this.chat$.pipe(untilDestroyed(this)).subscribe(chat => {
            this.scrollArea.nativeElement.scrollTo({
                top: this.scrollArea.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
            this.form.patchValue(chat, { emitEvent: false });
        });
    }

    submit(message: { text: string }) {
        if (this.chatId) {
            this.store.dispatch(ChatActions.sendChatMessage({ text: message.text, chatId: this.chatId }));
        }
    }
}
