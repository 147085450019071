import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@UntilDestroy()
@Component({
    selector: 'app-rating-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3>
                    How did we do ?
                </h3>
            </app-modal-title>
            <app-modal-body class="max-h-80">
                <p class="text-gray-500 text-sm pb-6">Please let us know how we did with your support request. All
                    feedback is appreciated to help us improve our offering! <br>
                    The rating is from 1 not good to 5 very good.</p>

                <div class="flex justify-between">
                    <ng-container *ngFor="let button of buttons; let i=index">

                        <button
                            [ngClass]="activeButton === i ? 'bg-blue-400 text-white' : 'text-gray-500 bg-blue-300 bg-opacity-10'"
                            class="w-11 h-11 rounded-full p-3 hover:bg-blue-400 hover:bg-opacity-100 hover:text-white"
                            (click)="setActive(i)">
                            <span class="">{{i + 1}}</span>
                        </button>

                    </ng-container>
                </div>

                <div class="text-red-500 mt-3 text-xs italic" *ngIf="error">
                    Please select a number.
                </div>

                <p class="text-gray-500 text-sm pb-4 mt-5">
                    In addition, you can also leave a message about what was particularly good or bad about the answer.
                </p>
                <div [formGroup]="form">
                    <input class="form-input px-2" type="text" id="feedbackText" formControlName="feedbackText">
                </div>
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-between">
                    <button
                        (click)="modalContainer.onDismiss()" type="button" class="btn-light">
                        Cancel
                    </button>
                    <button class="btn-success" (click)="submit()">
                        Submit
                    </button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./rating-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingModalComponent {
    @Input() chatId!: string;
    @Input() aiOutput!: string;
    @Input() humanInput!: string;

    activeButton!: number;
    buttons = Array.from([1, 2, 3, 4, 5]);
    form!: FormGroup;
    error: boolean = false;

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private http: HttpClient,
        private toast: HotToastService,
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            feedbackText: new FormControl(),
        })
    }

    setActive(number: number) {
        this.activeButton = number;
    }

    submit() {
        this.error = false;

        if (Number.isNaN(this.activeButton) || this.activeButton === undefined) {
            this.error = true;
            return;
        }

        this.http.post(environment.apiUrl + '/feedback', {
            rating: this.activeButton + 1,
            feedbackText: this.form.value.feedbackText,
            aiOutput: this.aiOutput,
            chatId: this.chatId,
        }).pipe(
            untilDestroyed(this),
            catchError(err => {
                this.toast.error('Feedback not saved:' + err.err.message)
                throw err;
            })
        ).subscribe(() => {
            this.toast.success('Feedback saved!');
            this.modalContainer.onDismiss();
        })
    }
}
