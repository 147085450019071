import { HttpClient } from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, startWith, tap } from 'rxjs';
import { map, pairwise, switchMap } from 'rxjs/operators';
import { WorkflowActions } from '../../+store/workflow/workflow.actions';
import { selectWorkflowById } from '../../+store/workflow/workflow.selectors';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { WorkflowService } from '../../workflow.service';

@UntilDestroy()
@Component({
    selector: 'app-workflow-detail',
    template: `
        <app-main-wrapper>
            <ng-container [ngSwitch]="workflow()?.type" *ngIf="workflow()">
                <ng-container *ngSwitchCase="'generic-wrapper'">
                    <app-generic-wrapper [workflow]="workflow()!"></app-generic-wrapper>
                </ng-container>
            </ng-container>
        </app-main-wrapper>
    `,
    styleUrls: ['./workflow-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowDetailComponent implements OnInit, OnDestroy {
    http = inject(HttpClient);
    route = inject(ActivatedRoute);
    store = inject(Store);
    workflow$ = this.route.paramMap.pipe(
        map(params => params.get('id')),
        filterNullish(),
        tap(id => this.store.dispatch(WorkflowActions.loadWorkflowById({ id }))),
        switchMap(id => this.store.select(selectWorkflowById(id))),
    );
    workflow = toSignal(this.workflow$);
    workflowService = inject(WorkflowService);

    ngOnDestroy() {
        if (this.workflow()?.id) {
            this.workflowService.unsubscribe(this.workflow()!.id);
        }
    }

    ngOnInit() {
        this.workflow$.pipe(
            startWith(null),
            map(workflow => workflow?.id),
            distinctUntilChanged(),
            pairwise(),
            untilDestroyed(this),
        ).subscribe(([oldId, newId]) => {
            if (oldId) {
                this.workflowService.unsubscribe(oldId);
            }

            if (newId) {
                this.workflowService.subscribe(newId);
            }
        });
    }
}
