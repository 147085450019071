import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BrandVoiceActions } from '../../+store/brand-voice.actions';
import { BrandVoice } from '../../+store/brand-voice.model';
import { selectAllBrandVoices } from '../../+store/brand-voice.selectors';
import {
    OnSelectChangeFunction
} from '../../../shared/custom-inputs/components/generic-textfield-with-selector/generic-textfield-with-selector.component';
import {
    ModalContentContainerComponent
} from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { ModalService } from '../../../shared/modal/modal.service';
import { BrandVoiceDetailComponent } from '../brand-voice-detail/brand-voice-detail.component';

@Component({
  selector: 'app-brand-voice-list',
  template: `

    <ng-container  *ngIf="brandVoices(); let brandVoices">
        <div class="mt-6 rounded-md overflow-hidden shadow-lg shadow-gray-200/80 bg-white max-w-[1200px]">
            <table class="w-full table">
                <thead>
                <tr>
                    <th class="w-px"></th>
                    <th>Brand Voice</th>
                    <th class="w-px"></th>
                </tr>
                </thead>
                <tbody>

                <!--                <tr *ngIf="(uploads$ | async)?.length === 0 && !uploadingFiles?.values()">-->
                <!--                    <td colspan="5">-->
                <!--                        <div class="p-12 text-center font-semibold">-->
                <!--                            Upload your first document-->
                <!--                        </div>-->
                <!--                    </td>-->
                <!--                </tr>-->
                <tr *ngFor="let brandVoice of brandVoices">
                    <td>
<!--                        <app-round-checkbox></app-round-checkbox>-->
                    </td>
                    <td class="relative">
                        <button type="button" class="absolute inset-0" (click)="openModal(brandVoice)"></button>
                        <div class="font-bold">{{ brandVoice.name }}</div>
                        <div class="text-sm text-slate-400 line-clamp-3">{{ brandVoice.text }}</div>
                    </td>
                    <td class="!text-right">
                        <div class="flex justify-end">
                            <app-button color="transparent" (confirmedClick)="delete(brandVoice)" *ngIf="mode === 'edit'">
                                <fa-icon [icon]="['fas', 'trash']" size="sm" [fixedWidth]="true"></fa-icon>
                            </app-button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </ng-container>
  `,
  styleUrl: './brand-voice-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandVoiceListComponent implements OnInit {
    @Input() mode: 'edit' | 'select' = 'edit'
    @Input() selectFn?: OnSelectChangeFunction;

    private modal = inject(ModalService)
    private store = inject(Store)
    private actions$ = inject(Actions)

    private modalContainer = inject(ModalContentContainerComponent, {optional: true});

    brandVoices: Signal<BrandVoice[] | undefined> = toSignal(this.store.pipe(
        select(selectAllBrandVoices)
    ))

    ngOnInit() {
        this.store.dispatch(BrandVoiceActions.loadBrandVoices());
    }

    openModal(brandVoice: BrandVoice) {
        if (this.mode === 'edit') {
            const modal = this.modal.open(BrandVoiceDetailComponent, {centered: true, size: 'full'});
            modal.contentInstance.brandVoice = brandVoice;
        }
        if (this.mode === 'select' && this.selectFn && this.modalContainer) {
            this.selectFn({value: brandVoice.text, visibleValue: brandVoice.name});
            this.modalContainer.onDismiss();
        }
    }

    delete(brandVoice: BrandVoice) {
        this.store.dispatch(BrandVoiceActions.deleteBrandVoiceById({ id: brandVoice.id }));

        // this.actions$.pipe(
        //     ofType(BrandVoiceActions.deleteBrandVoiceByIdSuccess, BrandVoiceActions.deleteBrandVoiceByIdFailure),
        //     first(),
        // ).subscribe(action => {
        //
        //     if (action.type === BrandVoiceActions.deleteBrandVoiceByIdSuccess.type) {
        //         // this.navigateToOverview();
        //     }
        // });
    }
}
