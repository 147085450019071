import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, tap } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { AuthActions } from '../../auth/+store/auth.actions';
import { UserService } from '../user.service';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {

    loadUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadUser),
            exhaustMap((action) => this.userService.getMe().pipe(
                map((user) => {
                    return UserActions.loadUserSuccess({user})
                }),
                catchError(err => {
                    return of(UserActions.loadUserError({err}))
                })
            )),
        );
    });

    updatePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.updatePassword),
            exhaustMap((action) => this.userService.updatePassword(action).pipe(
                tap((user) => {
                    this.toast.success('Password successfully changed.');
                }),
                map(() => UserActions.updatePasswordSuccess()),
                catchError(err => {
                    this.toast.error(err.error.message);
                    return of(UserActions.loadUserError({err}))
                }),
            )),
        );
    });

    deleteUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.deleteUser),
            exhaustMap(({id}) => this.userService.deleteUser(id).pipe(
                map(() => UserActions.deleteUserSuccess()),
                catchError(err => {
                    this.toast.error('Account not deleted: ' + err.error.message);
                    return of(UserActions.loadUserError({err}))
                }),
            ))
        )
    });

    deleteUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.deleteUserSuccess),
            tap(() => {
                this.router.navigate(['/auth/login']);
                this.toast.success('Account deleted' );
            }),
            map(() => AuthActions.logoutSuccess()),
        )
    })


    constructor(
        private actions$: Actions,
        private userService: UserService,
        private toast: HotToastService,
        private router: Router,
    ) {
    }
}
