import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat } from '../../../chat/+store/chat.model';
import { filterNullish } from '../../utilities/filter-nullish.operator';

@Component({
    selector: 'app-chat-list-item',
    template: `
        <a class="flex items-center px-5 py-4 group hover:bg-slate-800/50 cursor-pointer"
           [routerLink]="['./', {chatId: chat.id}]" [class.bg-slate-800]="isActive$ | async">
            <div class="text-slate-400">
                <fa-icon [icon]="['fad', 'rectangle-history']"></fa-icon>
            </div>
            <div class="ml-4 flex flex-col">
                <h4 class="text-slate-300 text-xs">{{ chat.name }}</h4>
                <div class="text-slate-400 text-xs">
                    {{ chat.updatedAt | date: 'dd.MM.yyyy HH:mm' }} Uhr
                </div>
            </div>
            <div class="ml-auto">
                <button
                    tp="Settings"
                    class="bg-transparent text-slate-400 ml-auto group-[:not(:hover)]:hidden px-1 py-1 hover:text-white rounded shadow-sm">
                    <fa-icon [icon]="['far', 'gear']" [fixedWidth]="true"></fa-icon>
                </button>
            </div>
        </a>
    `,
    styleUrls: ['./chat-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListItemComponent implements OnInit {
    @Input('chat') chat!: Chat;

    isActive$!: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.isActive$ = this.route.paramMap.pipe(
            map(params => params.get('chatId')),
            filterNullish(),
            map(chatId => chatId === this.chat.id),
            startWith(false),
        );
    }
}
