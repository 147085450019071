import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TippyDirective} from '@ngneat/helipopper';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';
import {WorkflowOutputEffects} from './+store/workflow-outputs/workflow-output.effects';
import {WorkflowEffects} from './+store/workflow/workflow.effects';
import {GenericWrapperComponent} from './components/generic-wrapper/generic-wrapper.component';
import {WorkflowHistoryListComponent} from './components/workflow-history-list/workflow-history-list.component';
import {WorkflowOutputListComponent} from './components/workflow-output-list/workflow-output-list.component';
import {WorkflowDetailComponent} from './pages/workflow-detail/workflow-detail.component';
import {WorkflowEditorComponent} from './pages/workflow-editor/workflow-editor.component';
import {EditorComponent} from 'ngx-monaco-editor-v2';
import {WorkflowEditFormComponent} from './components/workflow-edit-form/workflow-edit-form.component';
import {WorkflowEditPreviewComponent} from './components/workflow-edit-preview/workflow-edit-preview.component';
import {
    WorkflowOutputPreviewModalComponent
} from './components/workflow-output-preview/workflow-output-preview-modal.component';
import {WorkflowOverviewComponent} from './pages/workflow-overview/workflow-overview.component';
import {WorkflowTilesComponent} from './components/workflow-tiles/workflow-tiles.component';
import {FavoriteCheckerComponent} from '../shared/components/favorite-checker/favorite-checker.component';

@NgModule({
    declarations: [
        WorkflowDetailComponent,
        GenericWrapperComponent,
        WorkflowOutputListComponent,
        WorkflowHistoryListComponent,
        WorkflowEditorComponent,
        WorkflowEditFormComponent,
        WorkflowEditPreviewComponent,
        WorkflowOutputPreviewModalComponent,
        WorkflowOverviewComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        // TextFieldModule,
        TippyDirective,
        // ReactiveFormsModule,
        EffectsModule.forFeature([WorkflowEffects, WorkflowOutputEffects]),
        ClipboardModule,
        ReactiveFormsModule,
        FormsModule,
        EditorComponent,
        WorkflowTilesComponent,
        FavoriteCheckerComponent
    ],
    exports: [
        WorkflowEditorComponent
    ]
})
export class WorkflowModule {}
