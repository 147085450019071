import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectActions } from '../../../project/+store/project.actions';
import { Project } from '../../../project/+store/project.model';
import { selectAllProjects } from '../../../project/+store/project.selectors';

@Component({
    selector: 'app-project-list',
    template: `
        <div *ngFor="let project of projects$ | async; let i = index">
            <!--            <div [class.bg-slate-800]="i === 1" class="hover:bg-slate-800/50 cursor-pointer">-->
            <app-project-list-item [project]="project"></app-project-list-item>
            <!--            </div>-->
        </div>
    `,
    styleUrls: ['./project-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListComponent implements OnInit {
    projects$!: Observable<Project[]>;

    constructor(
        private store: Store,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(ProjectActions.loadProjects());
        this.projects$ = this.store.pipe(select(selectAllProjects));
    }
}
