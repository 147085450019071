import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-modal',
  template: `
      <div class="modal max-w-[100vw]" [ngClass]="cssClasses">
          <div class="modal-title">
              <ng-content select="app-modal-title"></ng-content>
          </div>
          <div class="modal-body max-h-[75vh] overflow-auto">
              <ng-content select="app-modal-body"></ng-content>
          </div>
          <div class="modal-footer">
              <ng-content select="app-modal-footer"></ng-content>
          </div>
      </div>
  `,
  styleUrls: ['./app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalComponent {
    @Input() id!: string;
    @Input() title!: string;
    @Input() inputTitle!: string;
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

    constructor(
        private modalContainer: ModalContentContainerComponent,
    ) {
    }

    close() {
        this.modalContainer.onDismiss();
    }

    get cssClasses() {
        return {
            'w-[400px]': this.size === 'sm',
            'w-[800px]': this.size === 'md',
            'w-[1200px]': this.size === 'lg',
            'w-[1400px]': this.size === 'xl',
        };
    }
}
