import {ChangeDetectionStrategy, Component, effect, inject, input, signal} from '@angular/core';
import {switchMap, tap} from 'rxjs';
import {Store} from '@ngrx/store';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {Workflow, WorkflowBase} from '../../+store/workflow/workflow.model';
import {selectWorkflowById} from '../../+store/workflow/workflow.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WorkflowActions} from '../../+store/workflow/workflow.actions';


@UntilDestroy()
@Component({
    selector: 'app-workflow-editor',
    template: `
        <app-main-wrapper>
            <div class="w-full h-full overflow-hidden grid grid-cols-2">

                @if (currentWorkflow() && draftWorkflow()) {
                    <section class="bg-slate-100 overflow-hidden h-full border-r border-r-slate-200 flex flex-col">
                        <div class="px-6 py-7 border-b flex text-sm items-center">

<!--                            todo add toggle for different editor options-->
<!--                            <a [routerLink]="['.']" fragment="outputs" class="py-2 px-3 tab"-->
<!--                               routerLinkActive="active"-->
<!--                            >Link1</a>-->
<!--                            <a [routerLink]="['.']" fragment="history" class="py-2 px-3 tab"-->
<!--                               routerLinkActive="active"-->
<!--                            >Link2</a>-->
                        </div>

                        <app-workflow-edit-form [workflow]="draftWorkflow() ?? currentWorkflow()"
                                                (workflowUpdate)="updateWorkflowDraft($event)"
                                                class="flex-grow h-full">
                        </app-workflow-edit-form>
                    </section>
                    <section class="flex flex-col overflow-hidden">
                        <app-workflow-edit-preview [workflow]="draftWorkflow()!"
                                                   [workflowId]="currentWorkflow()!.id"
                                                   [isDraft]="!!currentWorkflow()?.draft"
                                                   (publish)="publishWorkflowDraft()">
                        </app-workflow-edit-preview>
                    </section>
                }
            </div>
        </app-main-wrapper>
    `,
    styleUrl: './workflow-editor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowEditorComponent {
    store = inject(Store);

    id = input.required<string>()
    selectedWorkflow = toSignal(toObservable(this.id).pipe(
        switchMap(id => this.store.select(selectWorkflowById(id))),
        untilDestroyed(this)
    ));

    workflowChange = effect(() => {
        const workflow = this.selectedWorkflow();
        this.currentWorkflow.set(workflow);
        this.draftWorkflow.set(workflow?.draft ?? workflow);

    }, {allowSignalWrites: true});

    currentWorkflow = signal<Workflow | undefined>(undefined);
    draftWorkflow = signal<WorkflowBase | undefined>(undefined);

    updateWorkflowDraft(workflowDraft: WorkflowBase) {
        if (!this.currentWorkflow()?.id) {
            return;
        }

        this.store.dispatch(WorkflowActions.setWorkflowDraft({id: this.currentWorkflow()!.id, dto: {workflowDraft}}))
    }

    publishWorkflowDraft() {
        if (!this.currentWorkflow()?.id) {
            return;
        }
        this.store.dispatch(WorkflowActions.publishWorkflowDraft({id: this.currentWorkflow()!.id}))
    }
}
