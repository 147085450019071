import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    OnChanges,
    output,
    signal,
    SimpleChanges
} from '@angular/core';
import {WorkflowBase} from '../../+store/workflow/workflow.model';
import {HttpClient} from '@angular/common/http';
import {FormGroup, NonNullableFormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {WorkflowBuilderService} from '../../workflow-builder.service';
import {ModalService} from '../../../shared/modal/modal.service';
import {WorkflowOutputPreviewModalComponent} from '../workflow-output-preview/workflow-output-preview-modal.component';
import {firstValueFrom, takeUntil} from 'rxjs';
import {selectWorkflowLoading} from '../../+store/workflow/workflow.selectors';
import {containsNonEmptyValue} from '../../../shared/utilities/contains-none-empty-value.helper';
import {faPenField, faRotateBack} from '@fortawesome/pro-solid-svg-icons';
import {WorkflowActions} from '../../+store/workflow/workflow.actions';

@Component({
    selector: 'app-workflow-edit-preview',
    template: `

        @if (workflow(); as workflow) {
            <div class="flex gap-x-4 border-b px-10 py-6 items-center">
                <div class="flex items-center gap-8">
                    @if (workflow.image) {
                        <div>
                            <img [src]="workflow?.image" width="30">
                        </div>
                    }
                    <div>
                        <div class="font-semibold text-slate-700">{{ workflow?.label }}</div>
                        <div class="text-sm text-slate-500">{{ workflow?.description }}</div>
                    </div>
                </div>
                @if (isDraft()) {
                    <div class="flex ml-auto items-center gap-2">
                        <fa-icon [icon]="faPenField" size="2x" class="text-orange-500"></fa-icon>
                        <app-button type="button" color="transparent" [square]="true" (click)="deleteDraft()">
                            <fa-icon [icon]="faRotateBack"></fa-icon>
                        </app-button>
                    </div>
                }
            </div>


            <!--         todo own shared component ?-->
            <form class="flex flex-col grow overflow-hidden pt-5" [formGroup]="form"
                  (ngSubmit)="workflowPreviewSubmit()"
                  formGroupFinder>

                <div class="overflow-auto">
                    <ng-container formArrayName="elements">
                        <ng-container *ngFor="let item of workflow!.data; let i = index">
                            <ng-container [ngSwitch]="item.type" [formGroupName]="i">
                                <ng-container *ngSwitchCase="'generic-form'">
                                    <app-generic-form [data]="workflow!.data[i]"></app-generic-form>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="mt-auto border-t px-12 ">
                    @if (noInputError()) {
                        <div class="text-red-600 mt-2">Keine Werte im Form ausgefüllt</div>
                    }
                    <div class="py-6 justify-between items-center w-ful flex">
                        <app-button color="primary" type="submit">
                            @if (!previewLoading()) {
                                Generate Preview
                            } @else {
                                <fa-icon animation="spin" [icon]="['fad', 'spinner-third']"></fa-icon>
                            }
                        </app-button>

                        @if (isDraft()) {
                            <app-button color="orange" type="button" class="ml-auto" (click)="publishWorkflow()">
                                @if (!workflowLoading()) {
                                    Publish
                                } @else {
                                    <fa-icon animation="spin" [icon]="['fad', 'spinner-third']"></fa-icon>
                                }
                            </app-button>
                        }
                    </div>
                </div>
            </form>
        }
    `,
    styleUrl: './workflow-edit-preview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents'
    }
})
export class WorkflowEditPreviewComponent implements OnChanges, AfterViewChecked {
    store = inject(Store);
    workflowBuilderService = inject(WorkflowBuilderService);
    modalService = inject(ModalService);

    workflow = input.required<WorkflowBase>();
    workflowId = input.required<string>();
    isDraft = input(false);
    publish = output();

    noInputError = signal<boolean>(false);

    workflowLoading = this.store.selectSignal(selectWorkflowLoading());
    previewLoading = signal<boolean>(false);

    loading = computed(() => this.workflowLoading() && this.previewLoading());

    chachedFormData?: any[];

    // stupid way of using input signals without async effect and not using setter input
    ngOnChanges(changes: SimpleChanges) {
        if (changes['workflow']) {
            const workflow = this.workflow();

            this.chachedFormData = this.form.getRawValue().elements;
            this.form.controls.elements.clear({emitEvent: false});

            workflow?.data.forEach((value, index) => {
                this.form.controls.elements.push(this.fb.group({}));
            });

            this.noInputError.set(false);
        }
    }

    ngAfterViewChecked() {
        // we have to patch this in the lifecycle hook because the formgroups get created in the child components
        if (this.chachedFormData && this.chachedFormData.length > 0) {
            this.form.controls.elements.patchValue(this.chachedFormData);
            this.chachedFormData = [];
        }
    }

    fb = inject(NonNullableFormBuilder);
    http = inject(HttpClient);

    // <--------------- workflow stuff
    form = this.fb.group({
        elements: this.fb.array<FormGroup>([]),
    });

    // <--------------- workflow stuff

    workflowPreviewSubmit() {
        if (this.form.valid && this.loading()) {
            return;
        }

        const values = this.form.getRawValue();

        // only when some user inputs in form we submit
        if (!containsNonEmptyValue(values.elements)) {
            this.noInputError.set(true);
            return;
        }

        let outputModalRef = this.modalService.open(WorkflowOutputPreviewModalComponent);
        this.noInputError.set(false);
        this.previewLoading.set(true);

        firstValueFrom(outputModalRef.onDismiss).then(() => this.previewLoading.set(false));

        this.workflowBuilderService.generatePreviewOutput({
            data: values.elements,
            workflowId: this.workflowId(),
        }).pipe(
            takeUntil(outputModalRef.onDismiss)
        ).subscribe({
            next: output => {
                this.previewLoading.set(false);
                outputModalRef.contentInstance.outputPreview.set(output);
                console.log(output);
            },
            error: () => this.previewLoading.set(false)
        })

    }

    publishWorkflow() {
        if (this.form.valid && this.loading()) {
            return;
        }

        this.publish.emit();
    }

    deleteDraft() {
        this.store.dispatch(WorkflowActions.removeWorkflowDraft({id: this.workflowId()}));
    }

    protected readonly faPenField = faPenField;
    protected readonly faRotateBack = faRotateBack;
}
