import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectWorkflowAll} from '../../+store/workflow/workflow.selectors';
import {selectUserState} from '../../../user/+store/user.selectors';

@Component({
  selector: 'app-workflow-overview',
  template: `
      <app-main-wrapper>
          <section class="w-full flex flex-col max-w-[1200px] box-content py-6">
              <header class="flex px-5 items-center gap-10 w-full mb-10">
                  <div>
                      <h1 class="font-bold text-lg">Workflows</h1>
                  </div>
                  @if(!!user().workflowEditPermission){
                      <div class="ml-auto">
                          <app-button [routerLink]="['/workflow/create']">
                              <span class="whitespace-nowrap">Create Workflow</span>
                          </app-button>
                      </div>
                  }
              </header>
              <div class="px-5 mt-2">
                <app-workflow-tiles [workflows]="workflows()" [editable]="!!user().workflowEditPermission"></app-workflow-tiles>
              </div>
          </section>
      </app-main-wrapper>
  `,
  styleUrl: './workflow-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowOverviewComponent {
    store = inject(Store);
    user = this.store.selectSignal(selectUserState);
    workflows = this.store.selectSignal(selectWorkflowAll);
}
