import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalContentContainerComponent } from '../../modal/modal-content-container/modal-content-container.component';

@UntilDestroy()
@Component({
  selector: 'app-rating-feedback-modal',
  template: `
      <app-modal>
          <app-modal-title>
              <h3>
                  Please give us more information
              </h3>
          </app-modal-title>
          <app-modal-body class="max-h-80">
              <p class="text-white text-sm pb-6">We are sorry that the answer to your request was not sufficient. In
                  order for us to keep improving, we would appreciate if you could leave us a short feedback on how the
                  answer could have helped you better.</p>

              <div [formGroup]="form">
                  <input class="form-input px-2" type="text" id="feedbackText" placeholder="Enter information"
                         formControlName="feedbackText">
              </div>
          </app-modal-body>
          <app-modal-footer>
              <div class="flex justify-between">
                  <button
                          (click)="modalContainer.onDismiss()" type="button" class="btn-light">
                      Cancel
                  </button>
                  <button class="btn-success" (click)="submit()">
                      Submit
                  </button>
              </div>
          </app-modal-footer>
      </app-modal>
  `,
  styleUrls: ['./rating-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingFeedbackModalComponent {
    @Input() feedbackId!: string;

    form!: FormGroup;

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private http: HttpClient,
        private toast: HotToastService,
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            feedbackText: new FormControl(),
        })
    }

    submit() {
        this.http.patch(environment.apiUrl + '/feedback/' + this.feedbackId, {
            feedbackText: this.form.value.feedbackText,
        }).pipe(
            untilDestroyed(this),
            catchError(err => {
                this.toast.error('Feedback not saved:' + err.err.message)
                throw err;
            })
        ).subscribe(() => {
            this.toast.success('Feedback message saved!');
            this.modalContainer.onDismiss();
        })
    }
}
