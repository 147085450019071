import {ChangeDetectionStrategy, Component, input, output} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faStar as faStarSolid} from '@fortawesome/pro-solid-svg-icons';
import {faStar as faStarRegular} from '@fortawesome/pro-regular-svg-icons';
import {NgClass} from '@angular/common';

@Component({
    selector: 'app-favorite-checker',
    template: `
        <button class="text-3xl text-yellow-500 group/favorite p-1" (click)="toggle.emit(!selected())">
            <div class="inline-block transition-all" [ngClass]="{'scale-110': selected(), 'scale-100': !selected()}">
                <fa-icon [icon]="faStarSolid" [ngClass]="{
                'hover:text-yellow-400': selected(),
                'hidden group-hover/favorite:inline': !selected(),
            }"></fa-icon>
                <fa-icon [icon]="faStarOutline"
                         [ngClass]="{
                'hidden': selected(),
                'group-hover/favorite:hidden': !selected(),
            }"
                ></fa-icon>
            </div>
        </button>
    `,
    styleUrl: './favorite-checker.component.scss',
    standalone: true,
    imports: [
        FaIconComponent,
        NgClass
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteCheckerComponent {
    selected = input<boolean | undefined>(false);
    toggle = output<boolean>();

    protected readonly faStarSolid = faStarSolid;
    protected readonly faStarOutline = faStarRegular;
}
