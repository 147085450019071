import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-chat-message-document-search-result',
    template: `
        <div class="w-full py-7 flex justify-center bg-blue-100/50">
            <div class="max-w-[1200px] w-full px-5 font-semibold text-slate-400 text-center">
                At this point you have searched for a document and inserted information.
            </div>
        </div>
    `,
    styleUrls: ['./chat-message-document-search-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageDocumentSearchResultComponent {

}
