import { createSelector } from '@ngrx/store';
import { workflowsFeature } from './workflow.reducer';

export const {
    selectIds: selectWorkflowIds,
    selectEntities: selectWorkflowEntities,
    selectAll: selectWorkflowAll,
    selectTotal: selectWorkflowTotal,
} = workflowsFeature;

export const selectWorkflowById = (id: string) => createSelector(selectWorkflowEntities, entities => entities[id]);
