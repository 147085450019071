import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { UploadActions } from '../../../upload/+store/upload/upload.actions';
import { Upload } from '../../../upload/+store/upload/upload.model';

@Component({
    selector: 'app-file-list-item',
    template: `
        <div class="flex items-center group">
            <div class="flex justify-center items-center bg-zinc-300 w-[75px] h-[75px] rounded text-red-700 shrink-0">
                <fa-icon [icon]="['fad', 'file-pdf']" [fixedWidth]="true" size="2xl"></fa-icon>
            </div>
            <div class="flex flex-col overflow-auto ml-3 grow">
                <h3 class="font-medium text-xs text-slate-300 line-clamp-2">
                    {{ upload.fileName}}
                </h3>
                <span class="text-slate-400 text-xs mt-1">{{ +upload.fileSize | bytes: 0 }}
                    <span *ngIf="upload.tokenLength"> | {{upload.tokenLength}} Tokens</span>
                       <span *ngIf="upload.embeddingLength"> | {{upload.embeddingLength}} Chunks</span>
                </span>
                <span class="text-slate-400 text-xs mt-1">{{ upload.createdAt | date: 'short' }}</span>
            </div>
            <div class="flex">
                <button
                    [tp]="popover"
                    tpVariation="popper"
                    (tpVisible)="$popoverVisible.next($event)"
                    class="bg-transparent text-slate-400 ml-auto px-1 py-1 hover:bg-blue-300/10 hover:text-white rounded shadow-sm"
                    [class.group-[:not(:hover)]:hidden]="!($popoverVisible | async)">
                    <fa-icon [icon]="['far', 'ellipsis-h']" size="lg" [fixedWidth]="true"></fa-icon>
                </button>
                <ng-template #popover>
                    <div class="px-3 py-2">
                        <button class="text-slate-200" (confirmedClick)="deleteItem(upload.id)">
                            <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
                            Delete {{upload.fileName}}
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    styleUrls: ['./file-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListItemComponent {
    protected readonly faTrash = faTrash;
    @Input() upload!: Upload;

    $popoverVisible = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store,
    ) {
    }

    deleteItem(id: string) {
        this.store.dispatch(UploadActions.deleteUpload({id}));
    }

}
