import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProjectActions } from '../../+store/project.actions';
import {
    ModalContentContainerComponent,
} from '../../../shared/modal/modal-content-container/modal-content-container.component';

@Component({
  selector: 'app-rename-project',
  template: `
      <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
          <app-modal>
              <app-modal-title>
                  <h3>
                      Update Project Name: {{ currentName }}
                  </h3>
              </app-modal-title>
              <app-modal-body>
                  <label for="project-name" class="form-label">Project Name</label>
                  <input class="form-input px-2" type="text" id="project-name" formControlName="name">
              </app-modal-body>
              <app-modal-footer>
                  <button
                      type="submit"
                      class="btn-light"
                      [disabled]="!projectForm.valid"
                  >
                      Submit
                  </button>
              </app-modal-footer>
          </app-modal>
      </form>
  `,
  styleUrls: ['./rename-project-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameProjectModalComponent {
    @Input() id!: string;
    @Input() currentName!: string;

    projectForm = new FormGroup({
        name: new FormControl('', {nonNullable: true}),
    });

    constructor(
        private modalContainer: ModalContentContainerComponent,
        private store: Store,
    ) {
    }

    close() {
        this.modalContainer.onDismiss();
    }

    onSubmit() {
        this.store.dispatch(ProjectActions.updateProject({project: { id: this.id, name: this.projectForm.getRawValue().name }}));
        this.modalContainer.onDismiss();
    }
}
