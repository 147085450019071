import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';

import { exhaustMap, map, mergeMap } from 'rxjs/operators';
import { ProjectService } from '../project.service';
import { ProjectActions } from './project.actions';

@Injectable()
export class ProjectEffects {


    loadProjects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectActions.loadProjects),
            exhaustMap(() => this.projectService.loadAll().pipe(
                map(projects => ProjectActions.loadProjectsSuccess({projects})),
            )),
        );
    });

    addProject$ = createEffect(() => this.actions$.pipe(
        ofType(ProjectActions.addProject),
        mergeMap(action => {
            return this.projectService.addProject(action.project).pipe(
                map(project => ProjectActions.addProjectSuccess({project})),
                catchError(error => {
                    return of(ProjectActions.addProjectFailure({error}))
                }),
            );
        })
    ));

    updateProject$ = createEffect(() => this.actions$.pipe(
        ofType(ProjectActions.updateProject),
        mergeMap(action => {
            return this.projectService.updateProject(action.project).pipe(
                map(project => ProjectActions.updateProjectSuccess({project})),
                catchError(error => {
                    return of(ProjectActions.updateProjectFailure({error}))
                }),
            )
        })

    ));

    // deleteProject$ = createEffect(() => this.actions$.pipe(
    //     ofType(ProjectActions.deleteProject),
    //     switchMap(action => this.projectService.deleteProject(action.id).pipe(
    //         mergeMap(project => [ProjectActions.deleteProjectSuccess({id: action.id}), UploadActions.({uploadCollectionId: action.id})]),
    //         catchError(error => of(ProjectActions.deleteProjectFailure({error}))),
    //     )),
    // ));

    constructor(
        private actions$: Actions,
        private projectService: ProjectService,
    ) {
    }
}
