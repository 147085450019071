import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Document } from './+store/document.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullish } from '../shared/utilities/filter-nullish.operator';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {

    constructor(
        private http: HttpClient,
    ) {
    }

    loadDocumentsByChat(chatId: string): Observable<Document[]> {
        return this.http.get<Document[]>(`${environment.apiUrl}/documents`, {params: {chatId}});
    }

    download(documentId: string) {
        return this.http.get(`${environment.apiUrl}/documents/download/${documentId}`, {
            responseType: 'blob',
            observe: 'response',
        }).pipe(
            filterNullish(),
            map((res) => {
                    const contentDisposition = res.headers.get('content-disposition');
                    // @ts-ignore
                    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    return {
                        // @ts-ignore
                        file: new Blob([res.body], {type: res.headers.get('Content-Type')}),
                        fileName: fileName.slice(1, -1), // slice weil fileName in "" steht sonst
                    };
                },
            ),
        );
    }
}
