import { createActionGroup, props } from '@ngrx/store';

import { WorkflowOutput } from './workflow-output.model';

export const WorkflowOutputActions = createActionGroup({
    source: 'Workflow/API',
    events: {
        'Generate Output': props<{payload: {data: any[], outputs: number, workflowId: string}}>(),
        'Generate Output Success': props<{workflowOutputs: WorkflowOutput[]}>(),
        'Generate Output Failure': props<{error: any}>(),

        'Load Outputs By Workflow Id': props<{workflowId: string}>(),
        'Load Outputs By Workflow Id Success': props<{workflowOutputs: WorkflowOutput[]}>(),
        'Load Outputs By Workflow Id Failure': props<{error: any}>(),
    },
});
