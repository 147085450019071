import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import { ModalContentContainerComponent } from 'src/app/shared/modal/modal-content-container/modal-content-container.component';
import {Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";
import { UploadCollectionActions } from 'src/app/upload/+store/folder/upload-collection.actions';

@Component({
  selector: 'app-update-collection-modal',
  template: `
      <form [formGroup]="form" (ngSubmit)="submit()">
          <app-modal size="sm">
              <app-modal-title>
                  <h3 class="font-bold">Update collection name</h3>
              </app-modal-title>
              <app-modal-body>
                  <label for="name" class="form-label">Collection Name</label>
                  <input type="text" formControlName="name" id="name" class="form-input" />
              </app-modal-body>
              <app-modal-footer>
                  <div class="flex justify-end">
                      <app-button color="transparent" type="button" class="mr-2" (click)="modal.onDismiss()">Cancel
                      </app-button>
                      <app-button color="primary" type="submit">Save</app-button>
                  </div>
              </app-modal-footer>
          </app-modal>
      </form>
  `,
  styleUrl: './update-collection-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateCollectionModalComponent implements OnInit {
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        name: ['', Validators.required]
    })
    store = inject(Store);
    modal = inject(ModalContentContainerComponent);
    collection: any;

    ngOnInit() {
        this.form.setValue({ name: this.collection.name})
    }

    submit() {
        console.log('collection: ', this.collection);
        if (this.form.valid) {
            this.store.dispatch(UploadCollectionActions.updateUploadCollection(
                    {
                        id: this.collection.id,
                        name: this.form.getRawValue().name,
                    }
                ),
            );

        }
        this.modal.onClose();
    }
}
