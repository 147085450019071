import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowService } from '../../workflow.service';
import { WorkflowActions } from './workflow.actions';


@Injectable()
export class WorkflowEffects {
    actions$ = inject(Actions);
    workflowService = inject(WorkflowService);

    loadWorkflows$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.loadWorkflows),
        exhaustMap(() => this.workflowService.loadAll().pipe(
            map(workflows => WorkflowActions.loadWorkflowsSuccess({ workflows })),
            catchError(error => of(WorkflowActions.loadWorkflowsFailure({ error }))),
        )),
    ));

    loadWorkflowById$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.loadWorkflowById),
        mergeMap(action => this.workflowService.loadById(action.id).pipe(
            map(workflow => WorkflowActions.loadWorkflowByIdSuccess({ workflow })),
            catchError(error => of(WorkflowActions.loadWorkflowByIdFailure({ error }))),
        )),
    ));

}
