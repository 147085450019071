import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    Input,
    signal,
    WritableSignal
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { isMaxLengthInvalid, isRequiredInvalid } from '../../../utilities/input.validations';
import { TextareaField } from '../../types/textarea-field.type';
import { TypedValidationErrors } from '../../types/typed-validation-errors.type';

@Component({
    selector: 'app-generic-text-area-field',
    template: `
        <ng-container *ngIf="field">
            <label class="form-label" *ngIf="field.label.length > 0">
                {{ field.label }}<span *ngIf="field.required">*</span>
            </label>
            <div class="bg-slate-100 p-2 rounded relative">
                <textarea class="form-input w-full"
                          cdkTextareaAutosize
                          [cdkAutosizeMinRows]="field.rows ?? 5"
                          [placeholder]="field.placeholder ?? ''"
                          [disabled]="disabled()"
                          [value]="value()"
                          (input)="onChange($event)"
                          (blur)="onTouch()">
                </textarea>
                <div class="absolute text-xs right-2 -bottom-4 tabular-nums"
                     [ngClass]="!!errorObject()?.['maxLength'] ? 'text-red-600' : 'text-slate-400'"
                     *ngIf="field.maxLength">
                    {{ value().length }} / {{ field.maxLength }}
                </div>
            </div>

            <app-field-error-messages
                [errorObject]="errorObject()"
                [touched]="touched()">
            </app-field-error-messages>
        </ng-container>
    `,
    styleUrls: ['./generic-textarea-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericTextareaFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GenericTextareaFieldComponent),
            multi: true,
        },
    ],
})
export class GenericTextareaFieldComponent implements ControlValueAccessor {
    @Input() field!: TextareaField;

    value = signal('');
    touched = signal(false);
    disabled = signal(false);

    errorObject: WritableSignal<TypedValidationErrors | null> = signal(null);

    changeFn = (p: string) => {
    };

    onChange($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        this.changeFn(target.value);
        this.value.set(target.value);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled.set(isDisabled)
    }

    touchFn = () => {
    };

    onTouch()  {
        this.touched.set(true);
        this.touchFn();
    }

    writeValue(value: string): void {
        this.value.set(value);
    }

    // error validation
    setErrorAndReturn(errors: TypedValidationErrors | null) {
        this.errorObject.set(errors);
        return errors;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Validate required
        if (isRequiredInvalid(control.value, this.field.required)) {
            return this.setErrorAndReturn({ required: true });
        }

        // Validate max length
        if (isMaxLengthInvalid(control.value.length, this.field.maxLength)) {
            return this.setErrorAndReturn({ maxLength: this.field.maxLength?.toString() });
        }

        // No errors found
        return this.setErrorAndReturn(null);
    }
}
