import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { PaymentEffects } from './+store/payment.effects';
import { StripeInvoiceItemComponent } from './components/stripe-invoice-item/stripe-invoice-item.component';
import {
    DeleteSubscriptionModalComponent
} from './modals/delete-subscription-modal/delete-subscription-modal.component';
import { StripeSubscriptionHistoryItemComponent } from './components/stripe-subscription-history-item/stripe-subscription-history-item.component';
import { ListInvoicesModalComponent } from './modals/list-invoices-modal/list-invoices-modal.component';


@NgModule({
    declarations: [
        StripeInvoiceItemComponent,
        DeleteSubscriptionModalComponent,
        StripeSubscriptionHistoryItemComponent,
        ListInvoicesModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterOutlet,
        TippyDirective,
        EffectsModule.forFeature([PaymentEffects])
    ],
    exports: [
        StripeInvoiceItemComponent,
        DeleteSubscriptionModalComponent,
        StripeSubscriptionHistoryItemComponent,
    ],
})
export class PaymentModule {
}
