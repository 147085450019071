import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../project/+store/project.model';
import { environment } from '../../environments/environment';
import { Update } from '../shared/utilities/update.type';
import { Template } from './store/template.interface';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

    constructor(
        private http: HttpClient,
    ) {
    }

    loadAll() {
        return this.http.get<Template[]>(`${environment.apiUrl}/templates`);
    }

    addTemplate(name: string, prompt: string) {
        return this.http.post<Template>(`${environment.apiUrl}/templates`, {name, prompt});
    }

    updateTemplate(id: string, name: string, prompt: string) {
        return this.http.patch<Template>(`${environment.apiUrl}/templates/${id}`, {name, prompt});
    }

    deleteTemplate(id: string) {
        return this.http.delete<Template>(`${environment.apiUrl}/templates/${id}`);
    }
}
