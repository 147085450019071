import { TextFieldModule } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    inject,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NonNullableFormBuilder,
    ReactiveFormsModule,
} from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { finalize } from 'rxjs';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ChatService } from '../../chat.service';

@Component({
    selector: 'app-chat-input-field',
    template: `
        <form class="flex bg-slate-100 p-2 rounded-md w-full relative" (ngSubmit)="submit()" [formGroup]="form">
            <textarea
                class="textarea border-slate-200 rounded shadow w-full py-3 pl-2 pr-[90px]"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="15"
                placeholder="How can I help you?"
                formControlName="text"
                (keyup)="onKeyup($event)"
                (keydown)="onKeydown($event)"></textarea>

            <div class="absolute right-5 top-1/2 -translate-y-1/2 flex">
                <app-button color="transparent" tp="Improve Prompt" type="button" (click)="enhancePrompt()">
                    <fa-icon
                        [animation]="loadingEnhancedPrompt() ? 'beat-fade' : undefined"
                        [class.text-yellow-500]="loadingEnhancedPrompt()"
                        class="text-slate-400 group-hover/button:text-yellow-500"
                        [icon]="['fad', 'stars']"></fa-icon>
                </app-button>

                <app-button color="transparent" tp="Submit" type="submit">
                    <fa-icon class="text-blue-700" [icon]="['fad', 'paper-plane-top']"></fa-icon>
                </app-button>
            </div>
        </form>
    `,
    styleUrls: ['./chat-input-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ReactiveFormsModule, TextFieldModule, ButtonComponent, TippyDirective, FaIconComponent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ChatInputFieldComponent),
        },
    ],
})
export class ChatInputFieldComponent implements OnInit, ControlValueAccessor {
    changeFn?: (v: any) => void;
    touchFn?: () => void;
    disabled = signal(false);
    fb = inject(NonNullableFormBuilder);
    chatService = inject(ChatService);

    @Output('onSubmit') $submit = new EventEmitter<{ text: string }>();

    form!: FormGroup<{ text: FormControl<string> }>;
    loadingEnhancedPrompt = signal(false);

    ngOnInit() {
        this.form = this.fb.group({
            text: [''],
        });
    }

    submit() {
        if (this.form.valid) {
            this.$submit.emit(this.form.getRawValue());
            this.form.reset();
        }
    }

    enhancePrompt() {
        const values = this.form.getRawValue();

        if (values.text) {
            this.loadingEnhancedPrompt.set(true);
            this.chatService
                .enhancePrompt(values.text)
                .pipe(finalize(() => this.loadingEnhancedPrompt.set(false)))
                .subscribe(response => {
                    this.form.patchValue(response, { emitEvent: false });
                });
        }
    }

    onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter' && !event.shiftKey && !event.altKey) {
            event.preventDefault();
            this.submit();
        }
    }

    onKeyup(event: KeyboardEvent) {
        if (this.changeFn) {
            const value = this.form.getRawValue();
            this.changeFn(value.text);
        }
    }

    writeValue(text: string): void {
        this.form.patchValue({ text });
    }
    registerOnChange(fn: (v: any) => void): void {
        this.changeFn = fn;
    }
    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled.set(isDisabled);
    }
}
