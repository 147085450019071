import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalBackdropComponent } from './modal-backdrop/modal-backdrop.component';
import { ModalContentContainerComponent } from './modal-content-container/modal-content-container.component';


@NgModule({
    declarations: [
        ModalBackdropComponent,
        ModalContentContainerComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ModalBackdropComponent,
    ],
})
export class ModalModule {
}
