import { createSelector } from '@ngrx/store';
import { embeddingsFeature } from './embedding.reducer';

export const {
    selectIds: selectEmbeddingIds,
    selectEntities: selectEmbeddingEntities,
    selectAll: selectAllEmbedding,
} = embeddingsFeature;

export const selectEmbeddingById = (id: string) => createSelector(selectAllEmbedding, embeddings => embeddings.find(embedding => embedding.id === id));
