import { Directive, inject } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[formGroupFinder]',
})
export class FormGroupFinderDirective {
    formGroup = inject(FormGroupDirective, { host: true });
}
