import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { WorkflowOutputEffects } from './+store/workflow-outputs/workflow-output.effects';
import { WorkflowEffects } from './+store/workflow/workflow.effects';
import { GenericWrapperComponent } from './components/generic-wrapper/generic-wrapper.component';
import { WorkflowHistoryListComponent } from './components/workflow-history-list/workflow-history-list.component';
import { WorkflowOutputListComponent } from './components/workflow-output-list/workflow-output-list.component';
import { WorkflowDetailComponent } from './pages/workflow-detail/workflow-detail.component';


@NgModule({
    declarations: [
        WorkflowDetailComponent,
        GenericWrapperComponent,
        WorkflowOutputListComponent,
        WorkflowHistoryListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        // TextFieldModule,
        TippyDirective,
        // ReactiveFormsModule,
        EffectsModule.forFeature([WorkflowEffects, WorkflowOutputEffects]),
        ClipboardModule,
        ReactiveFormsModule,
    ],
})
export class WorkflowModule {
}
