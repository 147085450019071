import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Workflow } from './workflow.model';
import {WorkflowGenerateDto} from './dto/workflow-generate.dto';
import {WorkflowSetDraftDto} from './dto/workflow-set-draft.dto';

export const WorkflowActions = createActionGroup({
    source: 'Workflow/API',
    events: {
        'Generate Workflow': props<{dto: WorkflowGenerateDto}>(),
        'Generate Workflow Failure': props<{error: any}>(),

        'Load Workflows': emptyProps(),
        'Load Workflows Success': props<{workflows: Workflow[]}>(),
        'Load Workflows Failure': props<{error: any}>(),

        'Load Workflow By Id': props<{id: string, forEdit?: boolean}>(),
        'Load Workflow By Id Success': props<{workflow: Workflow}>(),
        'Load Workflow By Id Failure': props<{error: any}>(),

        'Set Workflow Draft': props<{id: string, dto: WorkflowSetDraftDto}>(),
        'Set Workflow Draft Success': props<{workflow: Workflow}>(),
        'Set Workflow Draft Failure': props<{error: any}>(),

        'Remove Workflow Draft': props<{id: string}>(),
        'Remove Workflow Draft Success': props<{workflow: Workflow}>(),
        'Remove Workflow Draft Failure': props<{error: any}>(),

        'Toggle Workflow Favorite': props<{id: string}>(),
        'Toggle Workflow Favorite Success': props<{workflow: Workflow}>(),
        'Toggle Workflow Favorite Failure': props<{error: any}>(),

        'Publish Workflow Draft': props<{id: string}>(),
        'Publish Workflow Draft Success': props<{workflow: Workflow}>(),
        'Publish Workflow Draft Failure': props<{error: any}>(),

        'Delete Workflow': props<{id: string}>(),
        'Delete Workflow Success': props<{workflow: Workflow}>(),
        'Delete Workflow Failure': props<{error: any}>(),
    },
});
