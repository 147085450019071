import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Embedding } from './+store/embedding.model';

@Injectable({
    providedIn: 'root'
})
export class EmbeddingService {

    constructor(
        private http: HttpClient
    ) {
    }

    getEmbeddingsForMessage(ids: string[]): Observable<Embedding[]> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`ids[]`, id);
        })
        return this.http.get<Embedding[]>(`${environment.apiUrl}/embedding/`, {params});
    }

}
