import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Template } from '../../../template/store/template.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { TemplateActions } from '../../../template/store/template.actions';
import { faMessage } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-template-suggestion-item',
  template: `
      <div class="flex items-center text-sm px-4 mb-3 py-3 bg-slate-800/50 text-gray-500 rounded cursor-pointer"
           (click)="insertIntoChat()">
          <fa-icon [icon]="faMessage" class="mr-2"></fa-icon>
          <span> {{template.prompt}}</span>
      </div>
  `,
  styleUrls: ['./template-suggestion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateSuggestionItemComponent {
    @Input('template') template!: Template;
    faMessage = faMessage;

    constructor(
        private store: Store<AppState>,
    ) {
    }

    insertIntoChat() {
        this.store.dispatch(TemplateActions.setCurrentTemplateId({id: this.template.id}));
    }

}
