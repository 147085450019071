import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ChatActions } from '../../+store/chat.actions';

@Component({
    selector: 'app-chat-index',
    template: `
        <app-main-wrapper>
            <form [formGroup]="form" class="p-5 w-full grow flex flex-col items-center bg-slate-50">
                <div class="flex items-center">
                    <app-chat-model-switcher formControlName="model"></app-chat-model-switcher>
                    <app-chat-model-settings formControlName="settings" class="ml-2"></app-chat-model-settings>
                </div>

                <div class="w-full max-w-[992px]">
                    <div class="flex items-center justify-center mt-12 mb-6">
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                        <h2 class="font-medium text-slate-400 mx-5 text-center text-sm uppercase">Start a Chat</h2>
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                    </div>

                    <app-chat-input-field (onSubmit)="createChat($event)"></app-chat-input-field>

                    <div class="flex items-center justify-center mt-12 mb-5">
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                        <h2 class="font-medium text-slate-400 mx-5 text-center text-sm uppercase">Or choose a
                            Workflow</h2>
                        <div class="h-px bg-slate-200 w-[100px]"></div>
                    </div>

                    <app-workflow-tiles></app-workflow-tiles>
                </div>
            </form>
        </app-main-wrapper>
    `,
    styleUrls: ['./chat-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatIndexComponent implements OnInit {
    form!: FormGroup;

    constructor(
        private store: Store,
        private fb: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.nonNullable.group({
            model: ['gpt-3.5-turbo', Validators.required],
            settings: [{ temperature: 0.8 }],
        });
    }

    createChat(message: {text: string}) {
        const formValue = this.form.getRawValue();
        this.store.dispatch(ChatActions.createChat({ text: message.text, model: formValue.model, settings: formValue.settings }));
    }
}
