import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTemplate from './template.reducer';
import { adapter } from './template.reducer';

export const selectTemplateState = createFeatureSelector<fromTemplate.TemplateState>(
    fromTemplate.templateFeatureKey,
);

export const {
    selectIds: selectTemplateIds,
    selectEntities: selectTemplateEntities,
    selectAll: selectAllTemplates,
} = adapter.getSelectors(selectTemplateState);


export const selectTemplateLoading = createSelector(selectTemplateState, state => state.loading)
export const selectTemplateById = (id: string) => createSelector(selectAllTemplates, templates => templates.find(template => template.id === id));
export const selectCurrentTemplateId = () => createSelector(selectTemplateState, state => state.currentTemplateId)
