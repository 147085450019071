import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullish } from '../utilities/filter-nullish.operator';

@Pipe({
    name: 'timeFromNow',
    pure: true,
})
export class TimeFromNowPipe implements PipeTransform {
    transform(value: string | Date | undefined): Observable<string> {
        if (value) {
            const obs = timer(0, 1000).pipe(
                map(() => DateTime.fromISO(value.toString()).toRelative({locale: 'en', round: true, unit: ['days', 'hours', 'minutes']})),
                filterNullish(),
            );
            return obs;
        }
        return of('');
    }
}
