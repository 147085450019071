import { inject, NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faComments,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFiles,
    faFolderOpen as faFolderOpenDuo,
    faFolders,
    faHome,
    faMagnifyingGlass,
    faPaperPlane,
    faPaperPlaneTop,
    faPencil,
    faRectangleHistory,
    faRightFromBracket,
    faSpinnerThird,
    faStars,
} from '@fortawesome/pro-duotone-svg-icons';
import { faArrowProgress, faChevronDown, faChevronRight, faHeart } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH, faFolder, faFolderOpen, faGear, faPaperclipVertical } from '@fortawesome/pro-regular-svg-icons';
import {
    faArrowsRotate,
    faBullhorn,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCircleCheck,
    faClipboard,
    faCommentArrowUpRight,
    faFileDownload,
    faGears,
    faPlus,
    faSquareDashedCirclePlus,
    faTimes,
    faTrash,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
    exports: [
    ],
    imports: [
    ],
})
export class SharedModule {
    private iconLibrary = inject(FaIconLibrary);

    constructor() {
        const iconLibrary = this.iconLibrary;

        iconLibrary.addIcons(
            faPlus,
            faPaperPlaneTop,
            faPaperPlane,
            faFilePdf,
            faEllipsisH,
            faRectangleHistory,
            faComments,
            faPencil,
            faGear,
            faGears,
            faFiles,
            faTimes,
            faTrash,
            faPaperclipVertical,
            faMagnifyingGlass,
            faFolders,
            faCircleCheck,
            faRightFromBracket,
            faFileDownload,
            faCommentArrowUpRight,
            faLinkedin,
            faClipboard,
            faCaretRight,
            faCaretDown,
            faFolder,
            faFolderOpen,
            faChevronRight,
            faHome,
            faSpinnerThird,
            faEye,
            faEyeSlash,
            faFolderOpenDuo,
            faCheck,
            faCaretUp,
            faStars,
            faBullhorn,
            faSquareDashedCirclePlus,
            faArrowsRotate,
            faHeart,
            faArrowProgress,
            faChevronDown,
        );
    }

}
