import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormRecord } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { catchError, of, ReplaySubject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Chat } from '../../+store/chat.model';
import { environment } from '../../../../environments/environment';
import { EmbeddingSearchResult } from '../../../embedding/+store/embedding.model';
import {
    ModalContentContainerComponent,
} from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { UploadCollectionActions } from '../../../upload/+store/folder/upload-collection.actions';
import { selectUploadCollectionAll } from '../../../upload/+store/folder/upload-collection.selectors';

@UntilDestroy()
@Component({
    selector: 'app-chat-embedding-search-modal',
    template: `
        <form [formGroup]="form">
            <app-modal size="xl">
                <app-modal-title>
                    <div class="flex grow">
                        <div>
                            <fa-icon [icon]="['fad', 'magnifying-glass']" size="lg"></fa-icon>
                        </div>
                        <div class="grow px-5">
                            <input type="text" formControlName="searchText" class="w-full outline-none bg-transparent"
                                   placeholder="Search..." />
                        </div>
                    </div>
                </app-modal-title>
                <app-modal-body>
                    <div class="overflow-hidden h-full">
                        <div class="flex">
                            <div class="flex space-x-3">
                                <div>
                                    <input class="hidden peer" id="allCollections" type="checkbox"
                                           formControlName="allCollections" />
                                    <label for="allCollections"
                                           class="rounded-full border border-slate-200 py-0.5 px-4 text-sm flex items-center peer-checked:bg-sky-600 peer-checked:text-white peer-checked:border-sky-600 cursor-pointer">
                                        All
                                    </label>
                                </div>
                                <div formGroupName="collections"
                                     *ngFor="let uploadCollection of uploadCollections$ | async">
                                    <input class="hidden peer" [id]="uploadCollection.id" type="checkbox"
                                           [formControlName]="uploadCollection.id" />
                                    <label [for]="uploadCollection.id"
                                           class="rounded-full border border-slate-200 py-0.5 px-4 text-sm flex items-center peer-checked:bg-sky-600 peer-checked:text-white peer-checked:border-sky-600 cursor-pointer">
                                        {{ uploadCollection.name }}
                                    </label>
                                </div>
                            </div>
                            <div class="ml-auto">
                                <!--                                <input type="text" placeholder="Search for file"-->
                                <!--                                       class="rounded-full outline-none shadow bg-transparent px-5 text-sm py-1 border border-neutral-100" />-->
                            </div>
                        </div>

                        <div class="overflow-auto">
                            <div class="mt-5">
                                <div class="flex flex-col">
                                    <app-embedding-result-item (selected)="selectResult($event)" [embedding]="embedding"
                                                               *ngFor="let embedding of $searchResults | async"></app-embedding-result-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-modal-body>
                <app-modal-footer>
                    <div class="flex items-center">
                        <div class="font-semibold text-slate-500 ml-auto flex items-center">
                            <div>{{ selectedSearchResults.size }} documents selected</div>
                            <fa-icon class="ml-2 mt-1" [icon]="['fas', 'caret-up']"></fa-icon>
                        </div>
                        <div class="ml-6 flex">
                            <app-button color="primary" type="button" (click)="submit()"
                                        [disabled]="selectedSearchResults.size === 0">Insert
                            </app-button>
                        </div>
                    </div>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrl: './chat-embedding-search-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatEmbeddingSearchModalComponent implements OnInit {
    $searchResults = new ReplaySubject<EmbeddingSearchResult[]>(1);
    @Input() chat!: Chat | null;
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        searchText: [''],
        allCollections: [true],
        collections: this.fb.record<boolean>({}),
    });
    http = inject(HttpClient);
    selectedSearchResults = new Set<string>();
    store = inject(Store);
    uploadCollections$ = this.store.select(selectUploadCollectionAll);
    modal = inject(ModalContentContainerComponent);

    ngOnInit() {
        this.store.dispatch(UploadCollectionActions.loadUploadCollections());
        this.form.controls.allCollections.disable();

        this.uploadCollections$.pipe(untilDestroyed(this)).subscribe(uploadCollections => {
            const collections = this.form.get('collections') as FormRecord;
            Object.keys(this.form.controls.collections.controls).forEach(key => collections.removeControl(key));

            uploadCollections.forEach(uploadCollection => {
                this.form.controls.collections.addControl(uploadCollection.id, new FormControl(false), { emitEvent: false });
            });
        });

        this.form.controls.allCollections.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
            if (value) {
                this.form.controls.collections.reset();
                this.form.controls.allCollections.disable({ emitEvent: false });
            }
        });

        this.form.controls.collections.valueChanges.pipe(untilDestroyed(this)).subscribe(group => {
            const values = Object.values(group);
            if (values.some(value => value)) {
                this.form.patchValue({ allCollections: false });
                this.form.controls.allCollections.enable({ emitEvent: false });
            } else {
                this.form.patchValue({ allCollections: true }, { emitEvent: false });
                this.form.controls.allCollections.disable({ emitEvent: false });
            }
        });

        this.form.valueChanges.pipe(
            debounceTime(1000),
            switchMap(values => {
                const collections = Object.entries(values.collections!)
                    .filter(([id, value]) => value)
                    .map(([id]) => id);

                return this.http.post<EmbeddingSearchResult[]>(
                    `${environment.apiUrl}/embedding/search`,
                    {
                        searchText: values.searchText,
                        collections,
                    },
                ).pipe(catchError(() => of([])));
            }),
            untilDestroyed(this),
        ).subscribe(response => this.$searchResults.next(response));
    }

    selectResult([id, checked]: [string, boolean]) {
        if (checked) {
            this.selectedSearchResults.add(id);
        } else {
            this.selectedSearchResults.delete(id);
        }
    }

    submit() {
        this.http.post(`${environment.apiUrl}/chat/document-search-result`, {
                chatId: this.chat?.id,
                embeddings: [...this.selectedSearchResults],
            })
            .subscribe(() => this.modal.onClose());
    }
}
