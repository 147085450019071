import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { WorkflowActions } from './workflow.actions';
import { Workflow } from './workflow.model';

export const workflowsFeatureKey = 'workflows';

export interface WorkflowState extends EntityState<Workflow> {
    loading: boolean;
}

export const adapter: EntityAdapter<Workflow> = createEntityAdapter<Workflow>();

export const initialState: WorkflowState = adapter.getInitialState({
    loading: false,
});

export const workflowReducer = createReducer(
    initialState,
    on(
        WorkflowActions.loadWorkflows,
        WorkflowActions.loadWorkflowById,
        WorkflowActions.generateWorkflow,
        WorkflowActions.setWorkflowDraft,
        WorkflowActions.toggleWorkflowFavorite,
        WorkflowActions.publishWorkflowDraft,
        WorkflowActions.removeWorkflowDraft,
        WorkflowActions.deleteWorkflow,
            state => ({ ...state, loading: true })),
    on(
        WorkflowActions.loadWorkflowByIdSuccess,
        WorkflowActions.setWorkflowDraftSuccess,
        WorkflowActions.toggleWorkflowFavoriteSuccess,
        (state, action) => adapter.upsertOne(action.workflow, {
            ...state,
            loading: false,
        }),
    ),
    on(
        WorkflowActions.publishWorkflowDraftSuccess,
        WorkflowActions.removeWorkflowDraftSuccess,
        (state, action) => adapter.setOne(action.workflow, {
            ...state,
            loading: false,
        }),
    ),
    on(
        WorkflowActions.deleteWorkflowSuccess,
        (state, action) => adapter.removeOne(action.workflow.id, {
            ...state,
            loading: false,
        }),
    ),
    on(
        WorkflowActions.loadWorkflowsSuccess,
        (state, action) => adapter.setAll(action.workflows, {
            ...state,
            loading: false,
        }),
    ),
    on(
        WorkflowActions.loadWorkflowsFailure,
        WorkflowActions.loadWorkflowByIdFailure,
        WorkflowActions.generateWorkflowFailure,
        WorkflowActions.setWorkflowDraftFailure,
        WorkflowActions.toggleWorkflowFavoriteFailure,
        WorkflowActions.publishWorkflowDraftFailure,
        WorkflowActions.deleteWorkflowFailure,
        WorkflowActions.removeWorkflowDraftFailure,
        state => ({ ...state, loading: false })),
);

export const workflowsFeature = createFeature({
    name: workflowsFeatureKey,
    reducer: workflowReducer,
    extraSelectors: ({ selectWorkflowsState }) => ({
        ...adapter.getSelectors(selectWorkflowsState),
    }),
});
