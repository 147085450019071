import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-main-wrapper',
    template: `
        <div class="flex h-screen">
            <app-nav></app-nav>

            <main class="w-full flex overflow-auto">
                <div class="w-full">
                    <div class="flex flex-col items-center mx-auto h-full">
                        <ng-content></ng-content>
                    </div>
                </div>
            </main>
        </div>
    `,
    styleUrls: ['./main-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainWrapperComponent implements OnInit{
    ngOnInit() {
    }
}
