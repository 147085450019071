import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Socket } from 'ngx-socket-io';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowOutputService } from '../../workflow-output.service';
import { WorkflowOutputActions } from './workflow-output.actions';
import { WorkflowOutput } from './workflow-output.model';


@Injectable()
export class WorkflowOutputEffects {
    actions$ = inject(Actions);
    workflowOutputService = inject(WorkflowOutputService);
    socket = inject(Socket);
    toast = inject(HotToastService);

    newWorkflowOutputResponse$ = createEffect(() => this.socket.fromEvent<WorkflowOutput>('newWorkflowOutput').pipe(
        map(workflowOutput => ({ ...workflowOutput, new: true })),
        map(workflowOutput => WorkflowOutputActions.generateOutputSuccess({ workflowOutputs: [workflowOutput] })),
    ));

    newWorkflowOutputResponseFailure$ = createEffect(() => this.socket.fromEvent<{
        error: any
    }>('newWorkflowOutputFailure').pipe(
        tap(error => this.toast.error('Please try again.')),
        map(error => WorkflowOutputActions.generateOutputFailure({ error })),
    ));

    generateOutput$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowOutputActions.generateOutput),
        mergeMap(action => this.workflowOutputService.generateOutput(action.payload).pipe(
            map(() => ({ type: 'NO_ACTION ' })),
            catchError(error => of(WorkflowOutputActions.generateOutputFailure({ error }))),
        )),
    ));

    loadWorkflowOutputs$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowOutputActions.loadOutputsByWorkflowId),
        switchMap(action => this.workflowOutputService.loadOutputsByWorkflowId(action.workflowId).pipe(
            map(workflowOutputs => WorkflowOutputActions.loadOutputsByWorkflowIdSuccess({ workflowOutputs })),
            catchError(error => of(WorkflowOutputActions.loadOutputsByWorkflowIdFailure({ error }))),
        )),
    ));
}
