import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MemberActions } from '../../+store/member.actions';
import {
    ModalContentContainerComponent,
} from '../../../shared/modal/modal-content-container/modal-content-container.component';

@UntilDestroy()
@Component({
    selector: 'app-invite-member-modal',
    template: `
        <form [formGroup]="form" (ngSubmit)="submit()">
            <app-modal>
                <app-modal-title>
                    <h3 class="font-semibold">Invite a Team Member</h3>
                </app-modal-title>
                <app-modal-body>
                    <div class="mb-3 grid grid-cols-2 gap-3">
                        <div>
                            <label for="firstName" class="form-label">First name</label>
                            <input formControlName="firstName" type="text" class="form-input" id="firstName" />
                        </div>

                        <div>
                            <label for="lastName" class="form-label">Last name</label>
                            <input formControlName="lastName" type="text" class="form-input" id="lastName" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="form-label">E-Mail</label>
                        <input formControlName="email" type="email" class="form-input" id="email" />
                    </div>
                </app-modal-body>
                <app-modal-footer>
                    <div class="flex justify-end">
                        <app-button class="mr-2" color="transparent" (click)="modal.onDismiss()">Cancel</app-button>
                        <app-button color="primary" type="submit">Invite</app-button>
                    </div>
                </app-modal-footer>
            </app-modal>
        </form>
    `,
    styleUrls: ['./invite-member-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteMemberModalComponent {
    actions = inject(Actions);
    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
    });
    modal = inject(ModalContentContainerComponent);
    store = inject(Store);

    submit() {
        if (this.form.valid) {
            this.store.dispatch(MemberActions.inviteMember({ member: this.form.getRawValue() }));
            this.actions.pipe(ofType(MemberActions.inviteMemberSuccess), untilDestroyed(this))
                .subscribe(() => this.modal.onClose());
        }
    }
}
