import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppModalComponent } from '../app-modal.component';

@Component({
    selector: 'app-modal-title',
  template: `
      <div class="p-4 flex">
          <ng-content></ng-content>
          <button type="button" class="ml-auto" (click)="closeModal()">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
      </div>
  `,
  styleUrls: ['./app-modal-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalTitleComponent {

    constructor(private modal: AppModalComponent) {
    }

    closeModal() {
        this.modal.close();
    }
}
