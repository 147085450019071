import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WorkflowOutputActions } from '../../+store/workflow-outputs/workflow-output.actions';
import { WorkflowOutput } from '../../+store/workflow-outputs/workflow-output.model';
import { selectWorkflowOutputByWorkflowId } from '../../+store/workflow-outputs/workflow-output.selectors';
import { Workflow } from '../../+store/workflow/workflow.model';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';

@Component({
    selector: 'app-workflow-history-list',
    template: `
        <div class="px-6 mt-6 font-semibold text-sm text-slate-700">History of the last 7 days</div>
        <app-workflow-output-list [outputs]="outputs$ | async" *ngIf="outputs$"></app-workflow-output-list>
    `,
    styleUrls: ['./workflow-history-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowHistoryListComponent implements OnChanges {
    @Input() workflow?: Workflow | null;

    store = inject(Store);
    cdr = inject(ChangeDetectorRef);
    outputs$!: Observable<WorkflowOutput[]>;

    ngOnChanges(changes: SimpleChanges) {
        if (this.workflow) {
            this.store.dispatch(WorkflowOutputActions.loadOutputsByWorkflowId({ workflowId: this.workflow.id }));
            this.outputs$ = this.store.select(selectWorkflowOutputByWorkflowId(this.workflow.id)).pipe(filterNullish());
            this.cdr.markForCheck();
        }
    }
}
