import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { ModalService } from '../../../shared/modal/modal.service';
import {
    ChatMessageDocumentSearchFolderSelectionModalComponent,
} from '../../modals/chat-message-document-search-folder-selection-modal/chat-message-document-search-folder-selection-modal.component';

@Component({
    selector: 'app-chat-message-document-search-folder-selection-button',
    template: `
        <app-button color="white" (click)="onClick()">Select Folder</app-button>
    `,
    styleUrl: './chat-message-document-search-folder-selection-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageDocumentSearchFolderSelectionButtonComponent {
    modalService = inject(ModalService);

    @Output() select = new EventEmitter<string>();

    onClick() {
        const modalRef = this.modalService.open(ChatMessageDocumentSearchFolderSelectionModalComponent);
        modalRef.onClose.subscribe(path => this.select.next(path));
    }
}
