import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PaymentActions } from '../../+store/payment.actions';
import { selectInvoicesOfSubscription } from '../../+store/payment.selectors';
import { AppState } from '../../../reducers';
import {
    ModalContentContainerComponent
} from '../../../shared/modal/modal-content-container/modal-content-container.component';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';
import { StripeInvoice } from '../../interfaces/stripe-invoice.interface';
import { StripeService } from '../../stripe.service';

@Component({
  selector: 'app-list-invoices-modal',
  template: `
      <app-modal>
          <app-modal-title>
              <h3>
                  Invoices of {{productName}}
              </h3>
          </app-modal-title>
          <app-modal-body class="px-3">
              <app-stripe-invoice-item *ngFor="let invoice of invoices$ | async" [invoice]="invoice"></app-stripe-invoice-item>
          </app-modal-body>
          <app-modal-footer>
              <div class="flex justify-between">
                  <button
                      (click)="modalContainer.onDismiss()"
                      type="button" class="btn-light">
                      Close
                  </button>
              </div>
          </app-modal-footer>
      </app-modal>
  `,
  styleUrls: ['./list-invoices-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListInvoicesModalComponent implements OnInit {
    @Input() productName!: string;
    @Input() subscriptionId!: string;
    invoices$!: Observable<StripeInvoice[]>;

    constructor(
        public modalContainer: ModalContentContainerComponent,
        private store: Store<AppState>,
        private stripeService: StripeService,
    ) {
    }

    ngOnInit() {
        this.invoices$ = this.store.select(selectInvoicesOfSubscription(this.subscriptionId)).pipe(
            filterNullish(),
        )
    }
}
