import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { diffChars } from 'diff';
import { ChatMessage } from '../../+store/chat.model';

export interface SpellcheckMessageArguments {
    correctedText: string;
    correctionCount: number;
}

@Component({
    selector: 'app-chat-message-spellcheck',
    template: `
        <div class="w-full py-7 flex justify-center bg-blue-100/50">
            <div class="flex max-w-[1200px] w-full px-5">
                <div class="shrink-0">
                    <ng-container>
                        <img ngSrc="/assets/logo/avatar.png" class="aspect-square rounded" priority="true" width="45"
                             height="45"
                             alt="">
                    </ng-container>
                </div>
                <div class="flex flex-wrap gap-x-12 gap-y-4 grow ml-6">
                    <div class="w-full text-red-500 font-semibold">Mistakes
                        found: {{ message.arguments.correctionCount }}</div>
                    <div class="w-0 grow">
                        <div class="text-slate-400 text-sm font-semibold">Original</div>
                        <div class="mt-3" [innerHTML]="originalText"></div>
                    </div>
                    <div class="w-0 grow">
                        <div class="text-slate-400 text-sm font-semibold">Corrected</div>
                        <div class="mt-3" [innerHTML]="correctedText"></div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./chat-message-spellcheck.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageSpellcheckComponent {
    message!: ChatMessage<SpellcheckMessageArguments>;

    originalText?: string;
    correctedText?: string;

    @Input('message') set _message(message: ChatMessage<SpellcheckMessageArguments>) {
        this.message = message;
        const differences = diffChars(message.text, message.arguments.correctedText, {});

        this.originalText = differences
            .filter(d => d.removed || !d.added)
            .map(d => {
                if (d.removed) {
                    return `<span class="text-red-600 font-semibold underline">${d.value}</span>`;
                }

                return d.value;
            }).join('');

        this.correctedText = differences
            .filter(d => d.added || !d.removed)
            .map(d => {
                if (d.added) {
                    return `<span class="text-green-600 font-semibold">${d.value}</span>`;
                }

                return d.value;
            }).join('');
    }
}
