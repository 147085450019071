import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../reducers';
import { selectAccessToken } from './+store/auth.selectors';


@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {

    constructor(
        private store: Store<AppState>,
        private router: Router,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean> | boolean {


        return this.store.pipe(
            select(selectAccessToken),
            map(accessToken => {
                if (!accessToken) {
                    return this.router.createUrlTree(['/auth/login']);
                }
                return true;
            })
        );
    }

}
