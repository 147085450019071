import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnInit, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkflowActions } from '../../../workflow/+store/workflow/workflow.actions';
import { selectWorkflowAll } from '../../../workflow/+store/workflow/workflow.selectors';

@Component({
    selector: 'app-workflow-tiles',
    template: `
        <div class="flex mb-2 text-slate-500 flex-wrap pb-3">
            <div
                class="bg-white shadow shadow-neutral-200 rounded-full text-slate-500 font-medium text-sm py-2 px-4 cursor-pointer whitespace-nowrap mr-3 mb-3"
                *ngFor="let category of categories()" (click)="toggleCategory(category)"
                [class.active]="selectedCategories().includes(category)">
                {{ category }}
            </div>
        </div>
        <div class="w-full grid grid-cols-3 auto-rows-fr justify-center gap-5">
            <a [routerLink]="['/workflow', workflow.id]" fragment="outputs"
               class="bg-white hover:bg-blue-50 shadow-md rounded-lg shadow-neutral-200 w-full p-6 flex"
               *ngFor="let workflow of filteredWorkflows()">
                <div class="flex flex-col w-full">
                    <!--                    <div class="shrink-0">-->
                    <!--                        <img [ngSrc]="workflow.image" [width]="50" [height]="50" [alt]="workflow.label">-->
                    <!--                    </div>-->
                    <div class="font-semibold text-lg text-slate-700">{{ workflow.label }}</div>
                    <div class="text-sm text-slate-500 mt-2 mb-5">{{ workflow.description }}</div>
                    <div class="flex mt-auto items-end w-full">
                        <div class="flex flex-wrap">
                            <div class="bg-sky-100 rounded-full text-xs py-2 px-4 whitespace-nowrap mr-3 mt-3"
                                 *ngFor="let category of workflow.categories">
                                {{ category }}
                            </div>
                        </div>
                        <img class="ml-auto" [ngSrc]="workflow.image" [width]="50" [height]="50" [alt]="workflow.label">
                    </div>

                </div>
            </a>
        </div>
    `,
    styleUrls: ['./workflow-tiles.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowTilesComponent implements OnInit {
    cdr = inject(ChangeDetectorRef);
    store = inject(Store);
    selectedCategories = signal<string[]>([]);

    workflows = this.store.selectSignal(selectWorkflowAll);
    categories = computed(() => {
        const workflows = this.workflows();
        const categories = new Set<string>();

        workflows.forEach(workflow => workflow.categories.forEach(category => categories.add(category)));
        return categories;
    });
    filteredWorkflows = computed(() => {
        const workflows = this.workflows();
        const selectedCategories = this.selectedCategories();

        if (selectedCategories.length > 0) {
            return workflows.filter(workflow => workflow.categories.some(category => selectedCategories.includes(category)));
        }

        return workflows;
    });

    ngOnInit() {
        this.store.dispatch(WorkflowActions.loadWorkflows());
    }

    toggleCategory(category: string) {
        const selectedCategories = new Set([...this.selectedCategories()]);

        if (selectedCategories.has(category)) {
            selectedCategories.delete(category);
        } else {
            selectedCategories.add(category);
        }

        this.selectedCategories.set(Array.from(selectedCategories));
    }
}
