import { ChangeDetectionStrategy, Component, forwardRef, Input, signal, WritableSignal } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { isMaxValueInvalid, isMinValueInvalid, isRequiredInvalid } from '../../../utilities/input.validations';
import { NumberField } from '../../types/number-field.type';
import { TypedValidationErrors } from '../../types/typed-validation-errors.type';

@Component({
    selector: 'app-generic-number-field',
    template: `
        <ng-container *ngIf="field">
            <label class="form-label" *ngIf="field.label.length > 0">
                {{ field.label }}<span *ngIf="field.required">*</span>
            </label>
            <div class="bg-slate-100 p-2 rounded">
                <input type="number" class="form-input"
                       [placeholder]="field.placeholder ?? ''"
                       [disabled]="isDisabled"
                       [value]="value()"
                       (input)="onChange($event)"
                       (blur)="onTouch()"
                        [attr.min]="field.min"
                        [attr.max]="field.max"/>
            </div>

            <app-field-error-messages
                [errorObject]="errorObject()"
                [touched]="touched()">
            </app-field-error-messages>
        </ng-container>
    `,
    styleUrls: ['./generic-number-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericNumberFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GenericNumberFieldComponent),
            multi: true,
        },
    ],
})
export class GenericNumberFieldComponent implements ControlValueAccessor {
    @Input() field!: NumberField;

    isDisabled = false;
    value = signal('');
    touched = signal(false);

    errorObject: WritableSignal<TypedValidationErrors | null> = signal(null);

    changeFn = (p: string) => {
    };

    onChange($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        this.changeFn(target.value);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    touchFn = () => {
    };

    onTouch()  {
        this.touched.set(true);
        this.touchFn();
    }

    writeValue(value: string): void {
        this.value.set(value);
    }

    // error validation
    setErrorAndReturn(errors: TypedValidationErrors | null) {
        this.errorObject.set(errors);
        return errors;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Validate required
        if (isRequiredInvalid(control.value, this.field.required)) {
            return this.setErrorAndReturn({ required: true });
        }

        // Validate minValue
        if (isMinValueInvalid(control.value, this.field.min)) {
            return this.setErrorAndReturn({ minValue: this.field.min?.toString() });
        }

        // Validate maxValue
        if (isMaxValueInvalid(control.value, this.field.max)) {
            return this.setErrorAndReturn({ maxValue: this.field.max?.toString() });
        }

        // No errors found
        return this.setErrorAndReturn(null);
    }
}
