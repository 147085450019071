import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoverWrapperComponent } from './components/popover-wrapper/popover-wrapper.component';
import { PopoverDirective } from './directives/popover.directive';


@NgModule({
    declarations: [
        PopoverDirective,
        PopoverWrapperComponent,
    ],
    exports: [
        PopoverDirective,
    ],
    imports: [
        CommonModule,
        OverlayModule,
    ],
})
export class PopoverModule {
}
