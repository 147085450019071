import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    ModalContentContainerComponent,
} from '../../../shared/modal/modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-chat-message-document-search-folder-selection-modal',
    template: `
        <app-modal>
            <app-modal-title>
                <h3 class="font-bold">Select a Folder</h3>
            </app-modal-title>
            <app-modal-body>
                <div class="max-h-[80lvh] overflow-auto">
<!--                    <app-folder-tree path="" [allowFolderCreation]="false" [activeOnClick]="true"></app-folder-tree>-->
                </div>
            </app-modal-body>
            <app-modal-footer>
                <div class="flex justify-end">
                    <app-button color="transparent" class="mr-2" (click)="modal.onDismiss()">Cancel</app-button>
                    <app-button color="primary" (click)="select()">Select</app-button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrl: './chat-message-document-search-folder-selection-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageDocumentSearchFolderSelectionModalComponent {
    // @ViewChild(FolderTreeComponent, { static: true }) folderTree!: FolderTreeComponent;
    modal = inject(ModalContentContainerComponent);

    async select() {
        // const selected = await firstValueFrom(this.folderTree.$currentSelected.pipe(timeout(1), catchError(() => of(''))));
        //
        // if (selected) {
        //     this.modal.close.next(selected);
        // }
    }
}
