import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, IsActiveMatchOptions } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject, switchMap } from 'rxjs';
import { WorkflowOutputActions } from '../../+store/workflow-outputs/workflow-output.actions';
import {
    selectNewWorkflowOutputByWorkflowId,
    selectWorkflowOutputLoading,
} from '../../+store/workflow-outputs/workflow-output.selectors';
import { Workflow } from '../../+store/workflow/workflow.model';
import { filterNullish } from '../../../shared/utilities/filter-nullish.operator';

@Component({
    selector: 'app-generic-wrapper',
    template: `
        <div class="w-full h-full overflow-hidden grid grid-cols-2">
            <div class="overflow-hidden h-full border-r border-r-slate-200 flex flex-col">
                <div class="flex border-b px-10 py-6 items-center gap-8">
                    <div>
                        <img [src]="workflow?.image" width="30">
                    </div>
                    <div>
                        <div class="font-semibold text-slate-700">{{ workflow?.label }}</div>
                        <div class="text-sm text-slate-500">{{ workflow?.description }}</div>
                    </div>
                </div>

                <form class="flex flex-col grow overflow-hidden pt-5" [formGroup]="form" (ngSubmit)="submit()"
                      formGroupFinder>

                    <div class="overflow-auto">
                        <ng-container formArrayName="elements">
                            <ng-container *ngFor="let item of workflow!.data; let i = index">
                                <ng-container [ngSwitch]="item.type" [formGroupName]="i">
                                    <ng-container *ngSwitchCase="'generic-form'">
                                        <app-generic-form [data]="workflow!.data[i]"></app-generic-form>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="mt-auto border-t flex px-12 py-6 justify-end items-center">
                        <div class="mr-5">
                            <label class="mr-3 text-sm inline">Number of Outputs:</label>
                            <div class="inline-block">

                                <input type="number" formControlName="outputs" [max]="2" [min]="1"
                                       class="form-input text-sm max-w-[50px] border-1 border-slate-200" />
                            </div>
                        </div>
                        <app-button color="primary" type="submit" *ngIf="!outputLoading()">
                            Generate
                        </app-button>
                        <app-button color="primary" type="button" *ngIf="outputLoading()">
                            <fa-icon animation="spin" [icon]="['fad', 'spinner-third']"></fa-icon>
                        </app-button>
                    </div>
                </form>
            </div>
            <div class="bg-slate-100 flex flex-col overflow-hidden">
                <div class="px-6 py-7 border-b flex text-sm items-center">
                    <a [routerLink]="['.']" fragment="outputs" class="py-2 px-3 tab" routerLinkActive="active"
                       [routerLinkActiveOptions]="routerLinkActiveOptions">Outputs</a>
                    <a [routerLink]="['.']" fragment="history" class="py-2 px-3 tab" routerLinkActive="active"
                       [routerLinkActiveOptions]="routerLinkActiveOptions">History</a>
                </div>

                <div class="overflow-auto">
                    <ng-container *ngIf="fragment() === 'outputs'">
                        <div class="w-1/2 mx-auto flex flex-col items-center"
                             *ngIf="!outputLoading() && outputs()?.length === 0">
                            <div class="font-medium mt-5">
                                Start by filling out the form.
                            </div>
                        </div>

                        <div class="w-1/2 mx-auto flex flex-col items-center" *ngIf="outputLoading()">
                            <app-loading-owl-animation></app-loading-owl-animation>
                            <div class="font-semibold -mt-5">
                                Generating Output...
                            </div>
                        </div>

                        <app-workflow-output-list [outputs]="outputs()"></app-workflow-output-list>
                    </ng-container>

                    <app-workflow-history-list [workflow]="workflow"
                                               *ngIf="fragment() === 'history'"></app-workflow-history-list>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./generic-wrapper.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'h-full w-full',
    },
})
export class GenericWrapperComponent {
    routerLinkActiveOptions: IsActiveMatchOptions = {
        fragment: 'exact',
        paths: 'exact',
        queryParams: 'ignored',
        matrixParams: 'exact',
    };

    fb = inject(FormBuilder);
    _workflow: WritableSignal<Workflow | null> = signal(null);
    $workflow = new ReplaySubject<Workflow>(1);
    http = inject(HttpClient);
    form = this.fb.nonNullable.group({
        outputs: [1],
        elements: this.fb.nonNullable.array<FormGroup>([]),
    });

    route = inject(ActivatedRoute);
    fragment = toSignal(this.route.fragment);

    store = inject(Store);
    outputs = toSignal(this.$workflow.pipe(
        filterNullish(),
        switchMap(workflow => this.store.select(selectNewWorkflowOutputByWorkflowId(workflow.id))),
    ));
    outputLoading = this.store.selectSignal(selectWorkflowOutputLoading);

    get workflow(): Workflow | null {
        return this._workflow();
    }

    @Input() set workflow(workflow: Workflow) {
        this._workflow.set(workflow);
        this.$workflow.next(workflow);

        this.form.controls.elements.clear({ emitEvent: false });

        workflow.data.forEach(() => {
            this.form.controls.elements.push(this.fb.nonNullable.group({}));
        });
    }

    submit() {
        // return console.log(this.form.value)
        if (this.form.valid) {
            const values = this.form.getRawValue();
            this.store.dispatch(WorkflowOutputActions.generateOutput({
                payload: {
                    data: values.elements,
                    outputs: values.outputs,
                    workflowId: this.workflow!.id,
                },
            }));
        }
    }
}
