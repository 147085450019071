import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project } from '../../../project/+store/project.model';
import {faPen, faTrash} from "@fortawesome/pro-solid-svg-icons";
import {ProjectActions} from "../../../project/+store/project.actions";
import {Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs";
import {ModalService} from "../../modal/modal.service";
import {RenameProjectModalComponent} from "../../../project/components/rename-project-modal/rename-project-modal.component";

@Component({
    selector: 'app-project-list-item',
    template: `
        <div class="flex items-center px-5 py-4 group hover:bg-slate-800/50 cursor-pointer"
             [routerLink]="['/project', project.id]" routerLinkActive="bg-slate-800">
            <div class="text-slate-400">
                <fa-icon [icon]="['fad', 'rectangle-history']"></fa-icon>
            </div>
            <div class="ml-4 flex flex-col">
                <h4 class="text-slate-300 text-xs">{{ project.name }}</h4>
                <div class="text-slate-400 text-xs">{{project.uploadCount}} documents</div>
            </div>
            <div class="ml-auto">
                <button
                    [tp]="popover"
                    tpVariation="popper"
                    (tpVisible)="$popoverVisible.next($event)"
                    class="bg-transparent text-slate-400 ml-auto px-1 py-1 hover:bg-blue-300/10 hover:text-white rounded shadow-sm"
                    [class.group-[:not(:hover)]:hidden]="!($popoverVisible | async)">
                    <fa-icon [icon]="['far', 'gear']" size="lg" [fixedWidth]="true"></fa-icon>
                </button>
                <ng-template #popover>
                    <div class="d-flex flex-col">
                        <div class="p-2">
                            <button class="text-slate-200" (click)="renameProject()">
                                <fa-icon class="p-1" [icon]="faPen" [fixedWidth]="true"></fa-icon>
                                Rename
                            </button>
                        </div>
                        <div class="p-2">
                            <button class="text-slate-200" (confirmedClick)="deleteProject(project.id)">
                                <fa-icon class="p-1" [icon]="faTrash" [fixedWidth]="true"></fa-icon>
                                Delete
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    styleUrls: ['./project-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListItemComponent {
    protected readonly faTrash = faTrash;
    protected readonly faPen = faPen;
    @Input('project') project!: Project;

    $popoverVisible = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store,
        private modal: ModalService,
    ) {
    }

    deleteProject(id: string) {
        this.store.dispatch(ProjectActions.deleteProject({id}));
    }

    renameProject() {
        const modal = this.modal.open(RenameProjectModalComponent, {centered: true, size: 'auto'});
        modal.contentInstance.id = this.project.id;
        modal.contentInstance.currentName = this.project.name;
    }
}
