import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ChatActions } from '../../+store/chat.actions';
import {
    ModalContentContainerComponent,
} from '../../../shared/modal/modal-content-container/modal-content-container.component';

@Component({
  selector: 'app-rename-chat-modal',
  template: `
      <form [formGroup]="chatForm" (ngSubmit)="onSubmit()">
          <app-modal>
              <app-modal-title>
                <h3>
                    Update Chat Name:
                </h3>
              </app-modal-title>
              <app-modal-body>
                  <label for="chat-name" class="form-label">Chat Name</label>
                  <input class="form-input" type="text" id="chat-name" formControlName="chatName">
              </app-modal-body>
              <app-modal-footer>
                  <button
                      type="submit"
                      class="btn-light"
                      [disabled]="!chatForm.valid"
                  >
                      Submit
                  </button>
              </app-modal-footer>
          </app-modal>
      </form>
  `,
  styleUrls: ['./rename-chat-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameChatModalComponent {
    @Input() id!: string;

    chatForm = new FormGroup({
        chatName: new FormControl('', {nonNullable: true}),
    });

    constructor(
        private modalContainer: ModalContentContainerComponent,
        private store: Store,
    ) {
    }

    close() {
        this.modalContainer.onDismiss();
    }

    onSubmit() {
        this.store.dispatch(ChatActions.updateChat({chat: { id: this.id, name: this.chatForm.getRawValue().chatName }}));
        this.modalContainer.onDismiss();
    }
}
