import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { Update } from '../shared/utilities/update.type';
import { Chat } from './+store/chat.model';

@Injectable({
    providedIn: 'root',
})
export class ChatService {

    constructor(
        private socket: Socket,
        private http: HttpClient,
    ) {
    }

    createChat(options: any) {
        return this.http.post<Chat>(`${environment.apiUrl}/chat`, options);
    }

    sendChatMessage(chatMessage: {text: string, chatId: string}) {
        this.socket.emit('chatMessage', chatMessage);
    }

    loadById(id: string) {
        return this.http.get<Chat>(`${environment.apiUrl}/chat/${id}`);
    }

    loadByProjectId(projectId: string) {
        return this.http.get<Chat[]>(`${environment.apiUrl}/chat/project/${projectId}`);
    }

    updateChat(value: Update<Chat>) {
        return this.http.patch<Chat>(`${environment.apiUrl}/chat/${value.id}`, value);
    }

    loadAll() {
        return this.http.get<Chat[]>(`${environment.apiUrl}/chat`);
    }

    subscribe(id: string) {
        this.socket.emit('subscribe', `chat/${id}`);
    }

    unsubscribe(id: string) {
        this.socket.emit('unsubscribe', `chat/${id}`);
    }

    enhancePrompt(prompt: string) {
        return this.http.post<{text: string}>(`${environment.apiUrl}/enhance-prompt`, {
            prompt: prompt,
        });
    }
}
