import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-chat-model-switcher',
    template: `
        <div class="bg-slate-100 flex p-2 rounded-md gap-2 text-sm justify-evenly">
            <div class="rounded p-2 grow cursor-pointer" tp="Fast and Cheap" [class.active]="value === 'gpt-3.5-turbo'"
                 (click)="change('gpt-3.5-turbo')">
                GPT-3.5 Turbo
            </div>
            <div class="p-2 rounded grow text-slate-400 hover:text-slate-600 cursor-pointer" tp="Best Results"
                 [class.active]="value === 'gpt-4'"
                 (click)="change('gpt-4')">
                GPT-4
            </div>
        </div>
    `,
    styleUrls: ['./chat-model-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChatModelSwitcherComponent),
            multi: true,
        },
    ],
})
export class ChatModelSwitcherComponent implements ControlValueAccessor {
    changeFn!: (p: string) => void;
    value: string = 'gpt-3.5-turbo';

    constructor(
        private cdr: ChangeDetectorRef,
    ) {
    }

    change(model: string) {
        this.value = model;
        this.update();
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
    }

    update() {
        if (this.changeFn) {
            this.changeFn(this.value);
        }
    }

    writeValue(value: string): void {
        if (value) {
            this.value = value;
        } else {
            this.value = 'gpt-3.5-turbo';
        }

        this.cdr.markForCheck();
    }

}
