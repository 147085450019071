import { createSelector } from '@ngrx/store';
import { documentsFeature } from './document.reducer';

export const {
    selectIds: selectDocumentsIds,
    selectEntities: selectDocumentsEntities,
    selectAll: selectAllDocuments,
    selectTotal: selectTotalDocuments,
} = documentsFeature;

export const selectDocumentsByChatId = (chatId: string) => createSelector(selectAllDocuments, documents => documents.filter(doc => doc.chatId === chatId));
