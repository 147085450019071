import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, of, tap} from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowService } from '../../workflow.service';
import { WorkflowActions } from './workflow.actions';
import {WorkflowBuilderService} from '../../workflow-builder.service';
import {Router} from '@angular/router';


@Injectable()
export class WorkflowEffects {
    actions$ = inject(Actions);
    workflowService = inject(WorkflowService);
    workflowBuilderservice = inject(WorkflowBuilderService);
    router = inject(Router);

    loadWorkflows$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.loadWorkflows),
        exhaustMap(() => this.workflowService.loadAll().pipe(
            map(workflows => WorkflowActions.loadWorkflowsSuccess({ workflows })),
            catchError(error => of(WorkflowActions.loadWorkflowsFailure({ error }))),
        )),
    ));

    loadWorkflowById$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.loadWorkflowById),
        mergeMap(action => this.workflowService.loadById(action.id, action.forEdit).pipe(
            map(workflow => WorkflowActions.loadWorkflowByIdSuccess({ workflow })),
            catchError(error => of(WorkflowActions.loadWorkflowByIdFailure({ error }))),
        )),
    ));

    generateWorkflow$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.generateWorkflow),
        exhaustMap((action) => this.workflowBuilderservice.generateWorkflow(action.dto).pipe(
            tap((workflow) => this.router.navigate(['/', 'workflow', 'edit', workflow.id])),
            map(workflow => WorkflowActions.loadWorkflowByIdSuccess({ workflow })),
            catchError(error => of(WorkflowActions.generateWorkflowFailure({ error }))),
        )),
    ));

    setWorkflowDraft$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.setWorkflowDraft),
        exhaustMap((action) => this.workflowBuilderservice.setWorkflowDraft(action.id, action.dto).pipe(
            map(workflow => WorkflowActions.setWorkflowDraftSuccess({ workflow })),
            catchError(error => of(WorkflowActions.setWorkflowDraftFailure({ error }))),
        )),
    ));

    removeWorkflowDraft$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.removeWorkflowDraft),
        exhaustMap((action) => this.workflowBuilderservice.removeWorkflowDraft(action.id).pipe(
            map(workflow => WorkflowActions.removeWorkflowDraftSuccess({ workflow })),
            catchError(error => of(WorkflowActions.removeWorkflowDraftFailure({ error }))),
        )),
    ));

    publishWorkflowDraft$ = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.publishWorkflowDraft),
        exhaustMap((action) => this.workflowBuilderservice.publishWorkflowDraft(action.id).pipe(
            map(workflow => WorkflowActions.publishWorkflowDraftSuccess({ workflow })),
            catchError(error => of(WorkflowActions.publishWorkflowDraftFailure({ error }))),
        )),
    ));

    toggleWorkflowFavorite = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.toggleWorkflowFavorite),
        exhaustMap((action) => this.workflowBuilderservice.toggleWorkflowFavorite(action.id).pipe(
            map(workflow => WorkflowActions.toggleWorkflowFavoriteSuccess({ workflow })),
            catchError(error => of(WorkflowActions.toggleWorkflowFavoriteFailure({ error }))),
        )),
    ));

    deleteWorkflow = createEffect(() => this.actions$.pipe(
        ofType(WorkflowActions.deleteWorkflow),
        exhaustMap((action) => this.workflowBuilderservice.deleteWorkflow(action.id).pipe(
            map(workflow => WorkflowActions.deleteWorkflowSuccess({ workflow })),
            catchError(error => of(WorkflowActions.deleteWorkflowFailure({ error }))),
        )),
    ));
}
