import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EmbeddingSearchObject } from '../../../chat/+store/chat.model';
import { Embedding } from '../../../embedding/+store/embedding.model';
import { selectEmbeddingById } from '../../../embedding/+store/embedding.selectors';
import { AppState } from '../../../reducers';
import { Upload } from '../../../upload/+store/upload/upload.model';
import { selectUploadById } from '../../../upload/+store/upload/upload.selectors';
import { filterNullish } from '../../utilities/filter-nullish.operator';

@Component({
    selector: 'app-message-chunk',
    template: `
        <div class="mb-5">
            <div>
                <span class="font-bold text-blue-300">Score:</span>
                {{embeddingSearch.score}}
            </div>
            <div>
                <span class="font-bold text-blue-300">Document:</span>
                {{(upload$ | async)?.fileName}}
            </div>
            <div>
                <span class="font-bold text-blue-300">Location/Page:</span>
                {{(embedding$ | async)?.loc?.pageNumber}}
            </div>
            <div>
                <span class="font-bold text-blue-300">Embedding/Text:</span>
                {{(embedding$ | async)?.pageContent}}
            </div>
        </div>
    `,
    styleUrls: ['./message-chunk.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageChunkComponent implements OnInit {
    @Input() embeddingSearch!: EmbeddingSearchObject;

    upload$!: Observable<Upload>;
    embedding$!: Observable<Embedding>;

    constructor(
        private store: Store<AppState>,
    ) {
    }

    ngOnInit() {
        this.upload$ = this.store.pipe(select(selectUploadById(this.embeddingSearch.uploadId)), filterNullish());
        this.embedding$ = this.store.pipe(select(selectEmbeddingById(this.embeddingSearch.embeddingId)), filterNullish());
    }

}
