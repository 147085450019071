import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Member } from './+store/member.model';
import { InviteMemberDto } from './interfaces/invite-member.interface';

@Injectable({
    providedIn: 'root',
})
export class MemberService {

    constructor(
        private http: HttpClient,
    ) {
    }

    loadAll(): Observable<Member[]> {
        return this.http.get<Member[]>(`${environment.apiUrl}/member`);
    }

    inviteMember(payload: InviteMemberDto) {
        return this.http.post<Member>(`${environment.apiUrl}/member/invite`, payload);
    }
}
