import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { NgMathPipesModule } from 'ngx-pipes';
import { SharedModule } from '../shared/shared.module';
import { DocumentEffects } from './+store/document.effects';
import {
    CreateCollectionButtonComponent,
} from './components/create-collection-button/create-collection-button.component';
import { UploadCollectionListComponent } from './components/upload-collection-list/upload-collection-list.component';
import { CreateCollectionModalComponent } from './modals/create-collection-modal/create-collection-modal.component';
import { UpdateCollectionModalComponent } from './modals/update-collection-modal/update-collection-modal.component';
import { DocumentDetailComponent } from './pages/document-detail/document-detail.component';
import { DocumentIndexComponent } from './pages/document-index/document-index.component';

@NgModule({
    declarations: [
        DocumentIndexComponent,
        CreateCollectionModalComponent,
        CreateCollectionButtonComponent,
        UploadCollectionListComponent,
        DocumentDetailComponent,
        UpdateCollectionModalComponent,
    ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([DocumentEffects]),
        SharedModule,
        NgMathPipesModule,
        ReactiveFormsModule,
        RouterModule,
        TippyDirective,
    ],
})
export class DocumentModule {
}
