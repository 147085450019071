import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ApplicationRef,
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Injector,
    Input,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { PopoverWrapperComponent } from '../components/popover-wrapper/popover-wrapper.component';

@Directive({
    selector: '[appPopover]',
})
export class PopoverDirective implements OnInit {
    @Input() appPopover!: TemplateRef<any>;

    componentRef?: ComponentRef<PopoverWrapperComponent>;

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private overlay: Overlay,
        private applicationRef: ApplicationRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private vcr: ViewContainerRef,
        private injector: Injector,
    ) {
    }

    @HostListener('click')
    onClick() {
        if (!this.componentRef) {
            const positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetY: 20,
                    },
                ]);

            const scrollStrategy = this.overlay.scrollStrategies.reposition({autoClose: true, scrollThrottle: 50});
            const overlay = this.overlay.create({
                positionStrategy,
                scrollStrategy,
            });

            const popoverPortal = new ComponentPortal(
                PopoverWrapperComponent,
                this.vcr,
                this.injector,
                undefined,
                [this.vcr.createEmbeddedView(this.appPopover, undefined, {injector: this.injector}).rootNodes],
            );

            this.componentRef = overlay.attach(popoverPortal);

            const closeFn = () => {
                this.componentRef?.destroy();
                this.componentRef = undefined;
            };

            this.componentRef.instance.close.subscribe(closeFn);
        }
    }

    ngOnInit() {
    }

}
