import {ChangeDetectionStrategy, Component, effect, inject, input, output, signal} from '@angular/core';
import {monacoWorkflowOptions} from '../../pages/workflow-editor/monaco.options';
import {NonNullableFormBuilder} from '@angular/forms';
import {editor} from 'monaco-editor';
import {WorkflowBuilderService} from '../../workflow-builder.service';
import {animationFrames, firstValueFrom, take} from 'rxjs';
import typia, {tags} from 'typia';
import {TextField} from '../../../shared/custom-inputs/types/text-field.type';
import {TextareaField} from '../../../shared/custom-inputs/types/textarea-field.type';
import {NumberField} from '../../../shared/custom-inputs/types/number-field.type';
import {WorkflowBase} from '../../+store/workflow/workflow.model';
import {NgxEditorModel} from 'ngx-monaco-editor-v2';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {skip} from 'rxjs/operators';
import ICodeEditor = editor.ICodeEditor;

export interface IGenericForm {
    type: 'generic-form';
    fields: (TextField | TextareaField | NumberField)[] & tags.MinItems<1>;
}
export type IWorkflowEditorType = Pick<WorkflowBase, 'promptTemplate' | 'categories' | 'description' | 'label'> & {
    data: IGenericForm[] & tags.MinItems<1>;
}

interface IMonacoSchemaDef {
    uri: string,
    schema: any,
    fileMatch: string[]
}

@Component({
    selector: 'app-workflow-edit-form',
    template: `
        <form (ngSubmit)="updateWorkflow()"
              [formGroup]="workflowForm"
              formGroupFinder
              class="flex flex-col h-full" [class.invisible]="editorInitialising()">
            @if (model()) {
                <ngx-monaco-editor
                    class="flex-grow"
                    [options]="monacoWorkflowDefaultOptions"
                    (onInit)="monacoEditorInit($event)"
                    [model]="model()!">
                </ngx-monaco-editor>
            }


            <div class="mt-auto border-t px-12 ">
                @if (editorErrors()) {
                    <div class="text-red-600 mt-2">Not a valid Workflow</div>
                }
                <div class="py-6 justify-between items-center w-ful flex">
                    <app-button class="ml-auto" color="primary" type="submit" [disabled]="editorErrors()">
                        Update Workflow
                    </app-button>
                </div>
            </div>
        </form>
        @if (editorInitialising()) {
            <div class="absolute inset-0 bg-white/90 flex justify-center items-center">
                <fa-icon [icon]="faSpinner" animation="spin" size="2x"></fa-icon>
            </div>
        }
    `,
    styleUrl: './workflow-edit-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'flex flex-col relative bg-white'
    }
})
export class WorkflowEditFormComponent {
    workflow = input<WorkflowBase | undefined>();
    loading = input<boolean>(false);
    workflowUpdate = output<WorkflowBase>()

    workflowBuilderService = inject(WorkflowBuilderService);
    fb = inject(NonNullableFormBuilder);
    monacoWorkflowDefaultOptions = monacoWorkflowOptions;
    monacoWindowObject?: any;
    monacoEditorInstance?: ICodeEditor; // Monaco Editor Instance
    editorInitialising = signal(true);

    workflowForm = this.fb.group({})

    workflowChange = effect(() => {
        // todo not allowed in computed? why... angular version bug?
        // this.valueChanged.set(false);
        const workflow = this.workflow();

        // stupid workaround for new init from editor
        this.model.set(undefined);
        this.editorInitialising.set(true);
        this.monacoEditorInstance = undefined;

        // we wait for one frame rendering to get a new editor instance
        this.waitOnePaint(() => {
            const workflowEditorInput: Omit<WorkflowBase, 'image' | 'type'> = {
                data: workflow?.data ?? [],
                promptTemplate: workflow?.promptTemplate ?? '',
                description: workflow?.description ?? '',
                label: workflow?.label ?? '',
                categories: workflow?.categories ?? []
            }

            this.model.set({
                value: JSON.stringify(workflowEditorInput),
                language: 'json'
            })
        })

    }, {allowSignalWrites: true})

    model = signal<NgxEditorModel | undefined>(undefined)


    formatEditor() {
        this.monacoEditorInstance?.getAction('editor.action.formatDocument')?.run().then(() => {
            this.editorInitialising.set(false);
        });
    }

    editorErrors = signal<boolean>(true);

    async loadSchemaDef(): Promise<IMonacoSchemaDef> {
        const schema = await firstValueFrom(this.workflowBuilderService.loadWorkflowSchema());

        return {
            schema,
            fileMatch: ['*'], // wildcard every file will be matched
            uri: 'workflowSchema' // internal editor schema id
        };
    }

    // set up the monaco editor with the correct schema and is called on the editor on init callback
    async monacoEditorInit(editor: ICodeEditor) {
        if (this.monacoEditorInstance) {
            return;
        }

        this.monacoWindowObject = (window as any).monaco;
        const workflowSchema = await this.loadSchemaDef();

        this.monacoWindowObject.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            allowComments: false,
            schemaValidation: 'error',
            schemas: [workflowSchema]
        });

        this.monacoEditorInstance = editor;
        this.monacoWindowObject.editor.onDidChangeMarkers(() => {
            this.updateMonacoErrors();
        });

        this.updateMonacoErrors();
        this.waitOnePaint(() => this.formatEditor());
    }

    waitOnePaint(fn: () => void) {
        animationFrames().pipe(
            skip(1),
            take(1),
        ).subscribe({
            next: fn
        })
    }

    async updateMonacoErrors() {
        if (!this.monacoWindowObject || !this.monacoEditorInstance) {
            return;
        }

        const emptyValue = this.monacoEditorInstance.getValue() === '';
        this.editorErrors.set(this.monacoWindowObject.editor.getModelMarkers({owner: 'json'}).length > 0 || emptyValue);
    }

    updateWorkflow() {
        if (!this.monacoEditorInstance || !this.workflow() || this.editorErrors()) {
            return;
        }

        let workflowValue: unknown;

        try {
            workflowValue = JSON.parse(this.monacoEditorInstance.getValue());
        } catch (e) {
            console.error('no valid JSON for parsing');
            return;
        }

        const validateWorkflow = typia.validateEquals<IWorkflowEditorType>(workflowValue);

        if (!validateWorkflow.success) {
            for (const error of validateWorkflow.errors) {
                console.error(error);
            }
            return;
        }


        this.workflowUpdate.emit({
            ...this.workflow()!,
            ...validateWorkflow.data
        });
    }

    protected readonly faSpinner = faSpinner;
}
