import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { EmbeddingModule } from '../embedding/embedding.module';
import { PopoverModule } from '../shared/popover/popover.module';
import { SharedModule } from '../shared/shared.module';
import { WorkflowModule } from '../workflow/workflow.module';
import { ChatEffects } from './+store/chat.effects';
import { ChatInputFieldComponent } from './components/chat-input-field/chat-input-field.component';
import {
    ChatMessageDocumentSearchFolderSelectionButtonComponent,
} from './components/chat-message-document-search-folder-selection-button/chat-message-document-search-folder-selection-button.component';
import {
    ChatMessageDocumentSearchResultComponent,
} from './components/chat-message-document-search-result/chat-message-document-search-result.component';
import {
    ChatMessageDocumentSearchComponent,
} from './components/chat-message-document-search/chat-message-document-search.component';
import { ChatMessageSpellcheckComponent } from './components/chat-message-spellcheck/chat-message-spellcheck.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatModelSettingsComponent } from './components/chat-model-settings/chat-model-settings.component';
import { ChatModelSwitcherComponent } from './components/chat-model-switcher/chat-model-switcher.component';
import { NewChatModalComponent } from './components/new-chat-modal/new-chat-modal.component';
import { RenameChatModalComponent } from './components/rename-chat-modal/rename-chat-modal.component';
import {
    ChatEmbeddingSearchModalComponent,
} from './modals/chat-embedding-search-modal/chat-embedding-search-modal.component';
import {
    ChatMessageDocumentSearchFolderSelectionModalComponent,
} from './modals/chat-message-document-search-folder-selection-modal/chat-message-document-search-folder-selection-modal.component';
import { ChatDetailComponent } from './pages/chat-detail/chat-detail.component';
import { ChatIndexComponent } from './pages/chat-index/chat-index.component';

@NgModule({
    declarations: [
        RenameChatModalComponent,
        NewChatModalComponent,
        ChatIndexComponent,
        ChatInputFieldComponent,
        ChatDetailComponent,
        ChatModelSwitcherComponent,
        ChatModelSettingsComponent,
        ChatMessageComponent,
        ChatMessageDocumentSearchComponent,
        ChatMessageDocumentSearchResultComponent,
        ChatMessageSpellcheckComponent,
        ChatMessageDocumentSearchFolderSelectionButtonComponent,
        ChatMessageDocumentSearchFolderSelectionModalComponent,
        ChatEmbeddingSearchModalComponent,
    ],
    imports: [
        CommonModule,
        EffectsModule.forFeature([ChatEffects]),
        ReactiveFormsModule,
        SharedModule,
        TextFieldModule,
        TippyDirective,
        NgOptimizedImage,
        FormsModule,
        PopoverModule,
        WorkflowModule,
        EmbeddingModule,
    ],
})
export class ChatModule {
}
